import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../../../index";
import "../../styles/form.pc.css";
import "../../../globalstyles/input.pc.css";
import "../../../globalstyles/background.pc.css";
import "../../../globalstyles/button.pc.css";
import "../../../globalstyles/text.pc.css";
import Logo from "../../../assets/mainLogo.svg";
import Background from "../../../assets/backgroundLogin.jpg";
import { useNavigate } from "react-router-dom";

const ConfirmationForm = () => {
    const { store } = useContext(Context);
    const navigate = useNavigate();

    const handleLogout = () => {
        store.logout().then(() => navigate("/login"));
    };

    return (
        <div className="form_main_center">
            <div className="form_container form_container_fullscreen form_container_center">
                <div className="form_logo">
                    <img src={Logo} alt="Логотип компании" />
                </div>
                <h2>Письмо отправлено на указанную почту</h2>
                <div className="form_description">
                    <p>
                        Для подтверждения регистрации дождитесь активации
                        администратором
                    </p>
                </div>
                <div className="form_flex_row">
                    <p
                        className="p_blue p_14 pointer_custom"
                        onClick={handleLogout}
                    >
                        <span>Вход</span>
                    </p>
                </div>
            </div>
            <img
                src={Background}
                alt="Фон компании"
                className="background_image_fullscreen"
            />
        </div>
    );
};

export default observer(ConfirmationForm);
