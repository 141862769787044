import React from 'react';
import './MisceleniousStyles.css'

const TaskTeg = (params: any) => {
    return (
        <div className='task-teg-widget nonselectable' style={{
            backgroundColor: params.color,
        }}>
            <div className='task-type-text' style={{color: "rgba(0, 0, 0, 0.7)"}}>
                {params.name}
            </div>
        </div>

    );
}
export default TaskTeg;
