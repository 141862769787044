export interface IUserFullName {
    name: string
    surname: string
    middlename?: string
}

/**
 * Из объекта полного имени пользователя собирает нужную строку.
 * @param {IUserFullName} fullName Объект из запроса.
 * @param {string} format Строка с шаблоном. (n/N - Имя / Сокр. Имя) (s/S - Фамилия / Сокр. Фамилия) (m/M - Отчество / Сокр. Отчество)
 * @returns {string} Необходимое отображение имени
 */
export function fullNameFormat(fullName: IUserFullName, format: string) {
    //Подготовить шаблон
    format = format.replace("n", "<%n%>")
    format = format.replace("s", "<%s%>")
    format = format.replace("m", "<%m%>")
    format = format.replace("N", "<%N%>")
    format = format.replace("S", "<%S%>")
    format = format.replace("M", "<%M%>")

    //Сделать замену
    format = format.replace("<%n%>", fullName.name)
    format = format.replace("<%s%>", fullName.surname)
    format = format.replace("<%N%>", fullName.name[0] + ".")
    format = format.replace("<%S%>", fullName.surname[0] + ".")

    if (fullName.middlename && fullName.middlename?.replace(' ', '') != '') {
        format = format.replace("<%M%>", fullName.middlename[0] + ".")
        format = format.replace("<%m%>", fullName.middlename)
    } else {
        format = format.replace("<%M%>", "")
        format = format.replace("<%m%>", "")
    }

    return format
}

export function initials(str: string) {
    return str ? str.split(/\s+/).map((w, i) => i && w ? w.substring(0, 1).toUpperCase() + '.' : w).join(' ') : '';
}
