import $api from "..";
import {AxiosResponse} from "axios";
import {
    IGetTaskHistoryDisplay,
    IGetTasksDisplayData,
    ITasksDisplay,
    ITasksDisplayFilters,
} from "../models/TaskModels";
import {IMoveTaskBoardData, IMoveTaskData, IMoveTasksData} from "../models/IMoveTaskData";
import {IDeleteTasks} from "../models/IDeleteTasks";
import {ITaskGeneralInfo} from "../models/ITask";
import {ICreateTegTask} from "../models/ICreateTegTask";
import {ITag, ITaskFormDisplay} from "../models/ITaskFormDisplay";
import {ITaskCreate} from "../models/ITaskCreate";
import {ITaskEdit} from "../models/ITaskEdit";
import {IUpdateTegTask} from "../models/IUpdateTegTask";
import {IUpdateTypeTask} from "../models/IUpdateTypeTask";
import {IGetAllTaskPerson} from "../models/IAllTaskPerson";
import {IGetTaskRequest} from "../models/IGetTaskRequest";
import {ITypeAndPriority} from "../models/ITypeAndPriority";
import {ISubTask, ISubTaskView} from "../models/ISubTask";
import {ISetTaskParent} from "../models/ISetTaskParent";
import { IProjectHistoryDisplay } from "../models/ProjectModels";
import {ITaskChecklistGroup} from "../models/ITaskChecklistGroup";
import {ITaskChecklistGroupEdit} from "../models/ITaskChecklistGroupEdit";
import {ITaskChecklist} from "../models/ITaskChecklist";

export default class TaskService {


    static async getProjectTasksDisplayData(body: IGetTasksDisplayData): Promise<AxiosResponse<ITasksDisplay>> {
        return $api.post<ITasksDisplay>(`Project/getTasks`, body);
    }

    static async getAllTaskPerson(taskId: number): Promise<AxiosResponse<IGetAllTaskPerson>> {
        return $api.get<IGetAllTaskPerson>(`Task/getAllTaskPerson?taskId=${taskId}`);
    }

    async getTypeAndPriority(): Promise<AxiosResponse<ITypeAndPriority>> {
        return $api.get<ITypeAndPriority>(`Project/getTypeAndPriority`);
    }

    static async updateTaskType(body: IUpdateTypeTask): Promise<AxiosResponse<any>> {
        return $api.post<any>(`Task/updateTaskType`, body);
    }

    static async updateTaskPriority(body: IUpdateTegTask): Promise<AxiosResponse<any>> {
        return $api.post<any>(`Task/updateTaskPriority`, body);
    }

    static async getTask(reqBody: IGetTaskRequest): Promise<AxiosResponse<ITaskGeneralInfo>> {
        return $api.post<ITaskGeneralInfo>(`Project/getTaskInfo`, reqBody);
    }

    static async getTaskHistory(reqBody: IGetTaskHistoryDisplay): Promise<AxiosResponse<IProjectHistoryDisplay[]>> {
        return $api.post<IProjectHistoryDisplay[]>(`Project/getTaskLogs`, reqBody);
    }

    static async getTaskForm(taskId: number): Promise<AxiosResponse<ITaskFormDisplay>> {
        return $api.get<ITaskFormDisplay>(`Project/getTask?taskId=${taskId}`);
    }

    static async addTegTask(body: ICreateTegTask): Promise<AxiosResponse<number>> {
        return $api.post<number>(`teg/addTegTaskInfo`, body);
    }

    static async getTegs(body: { taskId: number | undefined; projectId: number | undefined; userId: number; }): Promise<AxiosResponse<ITag[]>> {
        return $api.post<ITag[]>(`teg/getTegs`, body);
    }

    static async getProjectTasksFilters(projectId: number): Promise<AxiosResponse<ITasksDisplayFilters>> {
        return $api.get<ITasksDisplayFilters>(`Project/taskDisplayFilters?projectId=${projectId}`);
    }

    static async moveTask(body: IMoveTaskData): Promise<void> {
        return $api.post("Project/dashBoard/moveTask", body);
    }

    static async moveTaskBoard(body: IMoveTaskBoardData): Promise<void> {
        return $api.post("/Task/moveTaskOutsideBoard", body);
    }

    static async moveTasks(body: IMoveTasksData): Promise<void> {
        return $api.post("/Project/dashBoard/bulkMoveTasks", body);
    }

    static async deleteTasks(body: IDeleteTasks): Promise<void> {
        return $api.post("Project/deleteTasks", body);
    }

    static async createTask(body: ITaskCreate): Promise<AxiosResponse<ITaskFormDisplay>> {
        return $api.post("Project/addTask", body);
    }

    static async editTask(body: ITaskEdit): Promise<void> {
        return $api.post("Project/editTask", body);
    }

    static async addTagsInTasks(body: { tags: ITag[], taskId: number }): Promise<void> {
        return $api.post("Task/addTagsInTasks", body);
    }

    async getTaskChecklist(taskId: number): Promise<AxiosResponse<ITaskChecklistGroup[] | []>> {
        return $api.get(`Task/getTaskChecklist?taskId=${taskId}`);
    }

    static async createChecklistGroup(body: ITaskChecklistGroup) {
        return $api.post("Task/addChecklistGroup", body);
    }

    static async editChecklistGroup(body: ITaskChecklistGroupEdit) {
        return $api.post("Task/editChecklistGroup", body);
    }

    static async deleteTaskChecklistGroup(groupId: number) {
        return $api.get(`Task/deleteTaskChecklistGroup?groupId=${groupId}`);
    }

    static async createChecklistElement(body: ITaskChecklist) {
        return $api.post("Task/addChecklistElement", body);
    }
    static async deleteTaskChecklistElement(elementId: number) {
        return $api.get(`Task/deleteElementTaskChecklist?elementId=${elementId}`);
    }

    static async editTaskChecklistElement(body: ITaskChecklistGroupEdit) {
        return $api.post("Task/editChecklistElement", body);
    }

    static async getSubTasksTree(taskId: number): Promise<AxiosResponse<ISubTaskView>> {
        return $api.get<ISubTaskView>(`Task/getSubTasksTree?taskId=${taskId}`,);
    }

    static async getSubTasks(taskId: number): Promise<AxiosResponse<ISubTask[]>> {
        return $api.get(`Task/getSubTasks?taskId=${taskId}`);
    }

    async setTaskParent(body: ISetTaskParent): Promise<AxiosResponse<void>> {
        return $api.post("Task/setParent", body);
    }
}
