export function dateToNiceString(date: Date | undefined, format: string) {
    if (!(date instanceof Date)) { return "-" }

    const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const monthName = months[date.getMonth()]
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    let res = format
    res = res.replace("month", monthName)
    res = res.replace("dd", day)
    res = res.replace("MM", month)
    res = res.replace("yyyy", year)
    res = res.replace("hh", hour)
    res = res.replace("mm", minute)

    return res
}