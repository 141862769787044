import { useEffect, useRef } from 'react';

const useOutsideAlerter = (onOutsideClick: any) => {
  const ref = useRef();

  useEffect(() => {
    function handleClick(event: any) {
      const dataPicker = document.querySelector('.p-datepicker');
      const dropdown = document.querySelector('.p-dropdown-panel');
      if (ref.current && !(ref.current as any).contains(event.target)) {
        if(!dataPicker?.contains(event.target) && !dropdown?.contains(event.target)){
          onOutsideClick(event);
        }
      }
    }
    document.addEventListener('mousedown', handleClick);
   
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [onOutsideClick]);

  return ref;
}

export default useOutsideAlerter;