import { AxiosResponse } from "axios";
import $api from "..";
import { IMessageGroup } from "../models/IChatMessage";

export default class ChatService {
    static async deleteMessage(
        idMessage: number
    ): Promise<AxiosResponse<void>> {
        return $api.get<void>(`Project/deleteMessage?idMessage=${idMessage}`);
    }

    static async sendMessage(body: {
        message: string;
        chatId: number;
    }): Promise<AxiosResponse<boolean>> {
        return $api.post<boolean>(`Project/sendMessage`, body);
    }

    static async saveEditMessage(body: {
        message: string;
        messageId: number;
    }): Promise<AxiosResponse<boolean>> {
        return $api.post<boolean>(`Project/saveEditMessage`, body);
    }

    static async getChatMessages(
        chatId: number
    ): Promise<AxiosResponse<IMessageGroup[]>> {
        return $api.get<IMessageGroup[]>(
            `Project/getChatMessages?chatId=${chatId}`
        );
    }
}
