import React, {CSSProperties, FC} from 'react';
import "./Button.css"

interface IButtonProps {
    text?: string | undefined;
    image?: any;
    id?: string;
    callBack: () => void;
    style?: CSSProperties;
    textColor?: string;
}

/**
 * Компонент для отображения интерактивной кнопки.
 *
 * @param {IButtonProps} props - Свойства компонента.
 * @param {string | undefined} props.text - Текст, отображаемый на кнопке. Может быть undefined, если на кнопке только изображение.
 * @param {any} props.image - Изображение, отображаемое на кнопке.  Может быть как URL картинки, так и JSX элемент.
 * @param {string} id - id кнопки
 * @param {() => void} props.callBack - Функция обратного вызова, вызываемая при клике на кнопку.
 * @param {CSSProperties} [props.style] - Дополнительные стили, которые применяются к контейнеру.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент кнопки.
 */
const Button: FC<IButtonProps> = ({text, image,id, callBack, style, textColor}) => {
    return (
        <button key={id} style={{...style}} className={`ui__component--button ${text ? "ui__component--button__text" : "ui__component--button__none--text"}`} onClick={callBack}>
            {text ?
                <a style={{color: `var(${textColor})`}}>{text}</a>
            : null}
            {image ?
                <img src={image}/>
            : null}
        </button>
    );
};

export default Button;