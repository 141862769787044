import { FC, useContext} from 'react';
import { observer } from "mobx-react-lite";
import DocumentCopy from '../../../assets/document-copy.svg';
import { AppContext } from '../../../App';

export interface ICopyButtonProps {
    textDivId: string;
}

const BtnCopyText: FC<ICopyButtonProps> = ({ textDivId }) => {
    const {showToast} = useContext(AppContext);

    function onClick() {
        const text = document.getElementById(textDivId)?.innerText;
        if (!text) { return }
        navigator.clipboard.writeText(text);
        showToast("Скопировано в буфер обмена");
    }

    return (
        <div className='button_copy'>
            <div className="button-base" onClick={onClick}>
                <img src={DocumentCopy} />
            </div>
        </div>
    )
}

export default observer(BtnCopyText);