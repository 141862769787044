import {FC, useContext, useEffect, useState} from 'react';
import "./TaskInformationForm.css"
import EditIcon from '../../../assets/edit.svg'
import EditIconGray from '../../../assets/editButtonGray.svg'
import {useParams} from 'react-router-dom';
import {Context} from "../../../index";
import TaskPriority from "../../Shared/TaskPriority";
import TaskType from "../../Shared/TaskType";
import {ITag, ITaskFormDisplay} from "../../../models/ITaskFormDisplay";
import task, {TaskContext} from '../../../pages/Task/Task';
import TaskAddEditForm from "../TaskAddEditForm/TaskAddEditForm";
import TasksTagDisplay from "../../Shared/TagSelectorAndDisplay/TasksTagDisplay";
import {IHasPermission} from "../../../models/IChekRole";
import {AppContext} from "../../../App";
import MarkdownPreview from "../../Shared/MarkdownPreview/MarkdownPreview";
import {FileIcons} from "../../Shared/InputChat/FileIcons";
import unknown_svg from "../../../assets/FileIcons/unknown.svg";
import {convertMessage, formatEditMessage} from "../../../helpers/chatFucntion"
import CopyTextButton from "../../buttons/CopyTextButton/CopyTextButton";
import HtmlPreview from '../../UiLib/HtmlPreview/HtmlPreview';


interface ITaskPage {
    projectId?: number,
    userAccess: IHasPermission[],
    taskNumber?: string
}


const TaskInformationForm: FC<ITaskPage> = ({projectId, userAccess, taskNumber}) => {
    const {showToast} = useContext(AppContext);

    const {taskInformation, loadTaskInfo, getAllTaskPerson} = useContext(TaskContext);
    const {id} = useParams();
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const {store} = useContext(Context);
    const [tags, setTags] = useState<ITag[]>([]);

    // Ограничение прав
    const [editTaskInfo, setEditTaskInfo] = useState(false);

    // Марк даун
    const [fileMap, setFileMap] = useState<{
        data: { type: string; id: string; url: string }[];
    }>({data: []});
    const [url, setUrl] = useState("");

    useEffect(() => {

        // Проверяем, есть ли доступ к редактированию задачи
        const hasEditTaskInfoPermission = userAccess.some((access) => {
            if (access.functionCode === "TaskAction") {
                return access.permissions.some(
                    (permission) =>
                        permission.permissionCode === "edit" && permission.isGranted === true
                );
            }
            return false;
        });

        // Устанавливаем состояние
        setEditTaskInfo(hasEditTaskInfoPermission);
    }, [userAccess]);

    function updateTaskPriority(index: number) {
        if (editTaskInfo) {
            (async () => {
                try {
                    let res = await store.updateTaskPriority(Number(id), index);
                    await loadTaskInfo();
                } catch (error) {
                    console.log(error)
                }
            })();
        } else {
            showToast("У вас нет прав для редактирования задачи!");
        }
    }

    function updateTaskType(index: number) {
        if (editTaskInfo) {
            (async () => {
                try {
                    let res = await store.updateTaskType(Number(id), index);
                    await loadTaskInfo();
                } catch (error) {
                    console.log(error)
                }
            })();
        } else {
            showToast("У вас нет прав для редактирования задачи!");
        }
    }

    function onEditTaskClick(e: any) {
        if (editTaskInfo) {
            (async () => {
                e.stopPropagation();
                store.getTaskForm(Number(id)).then((res) => {
                    if (!res?.id) {
                        return;
                    }
                    setShowEditForm(!showEditForm);
                });
                await loadTaskInfo()
            })();
        } else {
            showToast("У вас нет прав для редактирования задачи!");
        }
    }


    const taskPriorityDisplay = (rowData: any, index: number) => {
        const style = JSON.parse(rowData?.color);
        return (
            <button className="button__taskPriorityDisplay--chek" onClick={() => updateTaskPriority(index)}>
                <TaskPriority name={rowData.name} color={style?.color} icon={style.icon}/>
            </button>
        );
    };

    const taskTypeDisplay = (rowData: any, index: number) => {
        const style = JSON.parse(rowData?.color);
        return (
            <button className="button__taskPriorityDisplay--chek" onClick={() => updateTaskType(index)}>
                <TaskType name={rowData.name} color={style?.color} icon={style?.icon}/>
            </button>
        );
    };

    function onAddTags(_tags: ITag[]) {
        console.log("Уровень страницы задачи")
        console.log("_tags", _tags);
        console.log("tags", tags);
        // let newTags = tags?.filter((xx) => xx.id < 0);
        // setTags([...newTags, ..._tags].filter((value, index, self) => self.indexOf(value) === index));

        // let tagsToAdd = _tags.filter(xx => !tags.map(yy => yy.id).includes(xx.id));
        // let removeTags = _tags.filter(xx => tags.map(yy => yy.id).includes(xx.id))
        //
        // console.log("Теги на удаление", removeTags)
        // console.log("Теги на добавление", tagsToAdd)

        if (_tags) {
            setTags(_tags)
        }

        // return;

        (async () => {
            await store.addTagsInTasks(_tags, taskInformation?.id!);
            loadTaskInfo();
            setTags(taskInformation?.tags!);
        })();
    }

    const generateFileId = (fileType: string) => {
        const id = Date.now().toString(); //.slice(0,5);
        return `${fileType}${id}`;
    };

    function fileIcon(fileType: string | undefined) {
        let fileTypeClean = fileType?.replace(/\./g, "");
        let variableName = `${fileTypeClean}_svg`;
        let pictureSrc = (FileIcons as any)[variableName];
        if (!pictureSrc) {
            //Тут если не нашли картинку нужна женерик картинка файла
            return unknown_svg;
        } else {
            return pictureSrc;
        }
    }


    function formatEditMessageTwo(text: string) {
        let changeValue = text;
        let newFileMap: { type: string; id: string; url: string }[] =
            fileMap?.data;

        let newMass: string[] = [];

        let prevIndex = 0;
        let checkCode = false;
        let checkQuote = false;

        console.log(changeValue)

        for (let i = 0; i <= changeValue.length; i++) {
            if (changeValue.substring(i, i + 46) == "<div class=\"code__block--chat__message\"><code>" || changeValue.substring(i - 13, i) == "</code></div>") {
                console.log("КОД")
                if (checkCode == false) {
                    newMass.push("\n```")
                    prevIndex = i + 47
                    checkCode = true;
                } else {
                    newMass.push(changeValue.substring(prevIndex, i - 14).replace(/<br>/g, "\n"));
                    newMass.push("```")
                    prevIndex = i + 1;
                    checkCode = false;
                }
            }

            if (changeValue.substring(i, i + 12) == '<blockquote>' || changeValue.substring(i - 13, i) == "</blockquote>") {
                if (checkQuote == false) {
                    newMass.push("\n> ")
                    prevIndex = i + 13
                    checkQuote = true;
                } else {
                    newMass.push(changeValue.substring(prevIndex, i - 13));
                    // newMass.push("\n")
                    prevIndex = i + 1;
                    checkQuote = false;
                }
            }

            if (checkCode == false && checkQuote == false) {
                if (
                    changeValue[i] === "\n" &&
                    newMass[newMass.length] === "\n"
                ) {
                    newMass[newMass.length] = newMass[newMass.length - 1];
                } else if (changeValue[i] === "\n") {
                    newMass.push(changeValue.substring(prevIndex, i + 1));
                    prevIndex = i + 1;
                } else if (changeValue[i] === " ") {
                    newMass.push(changeValue.substring(prevIndex, i));
                    prevIndex = i + 1;
                } else if (i == changeValue.length) {
                    newMass.push(changeValue.substring(prevIndex, i));
                    prevIndex = i + 1;
                }
            }
        }

        newMass = newMass.filter((xx) => xx.length > 0);

        for (let i = 0; i < newMass.length; i++) {
            if (newMass[i].includes("</a></div></div>")) {
                let j = i - 1;
                while (!newMass[i].includes("href=") && j >= 0) {
                    newMass[i] = newMass[j] + " " + newMass[i];
                    newMass[j] = "";
                    j--;
                }
            }
        }

        newMass = newMass.map((value) => {
            if (
                value == "<a" ||
                value == "<a\n" ||
                value == "<img" ||
                value == "<img\n" ||
                value == "/>" ||
                value == "<div" ||
                value.includes('href="[object') ||
                value == "/>\n" ||
                value == "<div\n" ||
                value.includes(
                    "input--chat__block--messages__message--file__chat"
                ) ||
                value == "<audio" ||
                value == "controls" ||
                value.includes(
                    'className="input--chat__block--messages__message--audio__chat--block"'
                ) ||
                value == "<video" ||
                value.includes('style="padding-top:') ||
                value.includes('10px"') ||
                value.includes('width="500px"') ||
                value.includes('height="240px"') ||
                value.includes('controls="controls"><source') ||
                value.includes('type="video/mp4"')
            ) {
                value = "";
                return value;
            }

            if (
                value
                    .slice(value.length - 8, value.length)
                    .includes("</video>") ||
                value
                    .slice(value.length - 9, value.length)
                    .includes("</video>\n")
            ) {
                value = value.slice(5, value.length - 11);
                let fileType = "V";

                const fileId = generateFileId(fileType);

                const newFileMap: { type: string; id: string; url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url:
                        `<video style="padding-top: 10px" width="500px" height="240px" controls="controls"><source type="video/mp4" src="` +
                        value +
                        `"/></video>`,
                };

                setFileMap((prevFileMap) => ({
                    data: [...prevFileMap.data, newFileMap],
                }));

                value = "{" + fileId + "}";

                return value;
            }

            if (
                value.slice(value.length - 8, value.length).includes("</audio>")
            ) {
                value = value.slice(5, value.length - 8);
                let nameFile = value.split(">")[1];
                value = value.split('"')[0];

                let fileType = "M";

                const fileId = generateFileId(fileType);

                const newFileMap: { type: string; id: string; url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: `<audio controls className="input--chat__block--messages__message--audio__chat--block" src="${url}">${nameFile}</audio>`,
                };

                setFileMap((prevFileMap) => ({
                    data: [...prevFileMap.data, newFileMap],
                }));

                value = "{" + fileId + "}";

                return value;
            }

            if (value.slice(value.length - 6, value.length).includes('"/><a')) {
                value = "";
                return value;
            }

            if (
                value
                    .slice(value.length - 13, value.length)
                    .includes("</div></div>")
            ) {
                value = value.slice(6, value.length);
                let nameFile = value
                    .slice(1, value.length)
                    .split("<")[0]
                    .split(">")[1];

                value = value.split('"')[0];

                let fileType = "F";

                let fileTypeGet = nameFile.split(".");
                let imgGet = fileIcon(fileTypeGet[fileTypeGet.length - 1]);
                const fileId = generateFileId(fileType);

                const newFileMap: { type: string; id: string; url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: `<div className="input--chat__block--messages__message--file__chat--block"><div className="input--chat__block--messages__message--file__chat"><img src="${imgGet}"/><a href="${value}">${nameFile}</a></div></div>`,
                };

                setFileMap((prevFileMap) => ({
                    data: [...prevFileMap.data, newFileMap],
                }));

                value = "{" + fileId + "}";

                return value;
            }

            if (value.slice(0, 6).includes("href=")) {
                value = value.slice(6, value.length - 4);
                value = value.split('"')[0];
                return value;
            }

            if (value.slice(0, 4).includes("src=")) {
                value = value.slice(5, value.length - 1);

                let fileType = "G";

                const fileId = generateFileId(fileType);
                const newFileMap: { type: string; id: string; url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: `<img src="${value}" />`,
                };

                setFileMap((prevFileMap) => ({
                    data: [...prevFileMap.data, newFileMap],
                }));

                value = "{" + fileId + "}";

                return value;
            }

            return value;
        });

        newMass = newMass.filter((xx) => xx != "");
        changeValue = "";

        for (let i = 0; i < newMass.length; i++) {
            if (newMass[i].includes("</br>")) {
                let newMassSplit = newMass[i]
                    .split("</br>")
                    .filter((x) => x !== "");
                newMassSplit.forEach((yy) => {
                    changeValue += yy;
                });
                changeValue += " ";
            } else {
                changeValue += newMass[i] + " ";
            }
        }

        if (taskInformation?.shortDescription) {
            taskInformation.shortDescription = changeValue;
        }
    }

    useEffect(() => {
        if (taskInformation?.shortDescription) {
            let newDataText = formatEditMessage(taskInformation.shortDescription ? taskInformation.shortDescription : "", fileMap);
            taskInformation.shortDescription = newDataText.disassembledText;
            if (newDataText.arrayChatFiles) {
                setFileMap(newDataText.arrayChatFiles);
            }
            // formatEditMessage(taskInformation.shortDescription, fileMap)
        }
    }, [taskInformation]);

    function removeTag(tag: ITag) {
        let newTagsTask = tags?.filter(xx => xx.id !== tag.id);
        (async () => {
            await store.addTagsInTasks(newTagsTask, taskInformation?.id!);
            loadTaskInfo();
            setTags(taskInformation?.tags!);
        })();
        setTags([]);
    }

    function onEditFormClose() {
        setShowEditForm(false);
        (async () => {
            await loadTaskInfo();
            await getAllTaskPerson();
        })();
    }

    useEffect(() => {
        (async () => {
            try {
                await loadTaskInfo();
                console.log(taskInformation?.tags!)
                setTags(taskInformation?.tags!);
            } catch (error) {
            }
        })();
    }, [id]);

    useEffect(() => {
        setTags(taskInformation?.tags!);
        console.log(taskInformation?.tags!)
    }, [taskInformation]);

    useEffect(() => {
        (async () => {
            try {
                await loadTaskInfo();
                store.setCurrentProjectId(projectId ?? 1);
            } catch (error) {
            }
        })();
    }, [store.isTaskUpdate]);


    return (
        <div className="widget__task--information">
            {showEditForm ? (
                <div className="full-screen-overlay">
                    <div className="popup-form-background">
                        <TaskAddEditForm mode={"edit"} taskId={Number(id)} closeFunc={onEditFormClose}
                                         projectId={projectId}
                                         permission={editTaskInfo}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="widget__task--information__head">
                <div className="widget__task--information__name">

                    <p className="widget__task--information__p--style-abr" id={taskNumber}>
                        {taskNumber}
                    </p>
                    <CopyTextButton textDivId={taskNumber ?? ''}></CopyTextButton>
                    <p className="widget__task--information__p--style">
                        {taskInformation?.shortDescription}
                    </p>
                </div>
                <div className="widget__task--information__edit" onClick={onEditTaskClick}>
                    <div className="widget__task--information__edit--block">
                        <img src={editTaskInfo ? EditIcon : EditIconGray}/>
                    </div>
                </div>
            </div>
            <div className="widget__task--information__desk--block">
                <p className="m-0">
                    <HtmlPreview content={taskInformation?.description ?? ""}/>
                </p>
            </div>
            <div className="widget__task--information__priority--type">
                <div className="widget__task--information__priority--type--block">
                    <div className="widget__task--information__priority--type--block__head">
                        <p className="widget__task--information__p--style">Приоритет</p>
                    </div>
                    <div className="widget__task--information__priority--type--block__list">
                        {taskInformation?.priority?.map((priority, index) => (
                            <div key={index + 1}
                                 style={priority.curent ? {} : {opacity: 0.3}}
                                 className="widget__task--information__priority--type--block__list--margin">
                                {taskPriorityDisplay(priority, index + 1)}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="widget__task--information__priority--type--block">
                    <div className="widget__task--information__priority--type--block__head">
                        <p className="widget__task--information__p--style">Тип задачи</p>
                    </div>
                    <div className="widget__task--information__priority--type--block__list">
                        {taskInformation?.type?.map((type, index) => (
                            <div key={index + 1}
                                 style={type.curent ? {} : {opacity: 0.3}}
                                 className="widget__task--information__priority--type--block__list--margin">
                                {taskTypeDisplay(type, index + 1)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="widget__task--information__tegs">
                <div>
                    <p className="widget__task--information__p--style">Теги</p>
                </div>
                <div className="widget__task--information__tegs--view">
                    {taskInformation?.tags && (
                        <div className="widget__task--information__tegs--view__task--tag">
                            <TasksTagDisplay
                                projectId={projectId ?? store.currentProjectId}
                                taskId={taskInformation?.id}
                                tags={taskInformation?.tags}
                                addTagsFunc={onAddTags}
                                removeTag={removeTag}
                                permission={editTaskInfo}
                            />
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
    );
};

export default TaskInformationForm;