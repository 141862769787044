import { FC, useEffect, useRef, useState } from "react";
import ContainerWithOverlay from "../ContainerWithOverlay/ContainerWithOverlay";
import "./HtmlPreview.css";

interface IHtmlPreviewProps {
    content: string;
}

/**
 * Превью html
 *
 * @param {String} content - Содержимое.
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент превью.
 */
const HtmlPreview: FC<IHtmlPreviewProps> = ({ content }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [imgSrc, setImgSrc] = useState<string | undefined>();

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const img = (event.target as HTMLElement).closest(
                ".html-preview img"
            );
            if (img && containerRef.current?.contains(img)) {
                openFullScreen((img as HTMLImageElement).src);
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener("click", handleClick);
        }

        return () => {
            if (container) {
                container.removeEventListener("click", handleClick);
            }
        };
    }, []);

    const openFullScreen = (src: string) => {
        setImgSrc(src);
    };

    return (
        <div className="html-preview" ref={containerRef}>
            {imgSrc ? (
                <ContainerWithOverlay
                    onOverlayClick={() => setImgSrc(undefined)}
                >
                    <img src={imgSrc} className="fullscreen_image" />
                </ContainerWithOverlay>
            ) : null}
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
};

export default HtmlPreview;
