export interface IEditPersonalSettingsRequest {
    settings: IEditPersonalSettings[];
}

export interface IEditPersonalSettings {
    id: number | undefined; // есть id редачим существующую запись, нет - создаем новую
    paramName: string;
    value: any;
}

export interface IPersonalSettings {
    id: number;
    paramName: string;
    value: any;
}

export enum PersonalSettingsOptions {
    showProxyBoardsLeftMenu = "showProxyBoardsLeftMenu",
    showSubprojectsProjectPage = "showSubprojectsProjectPage",
    editTaskEmail = "editTaskEmail",
    editTaskFront = "editTaskFront",
    commentTaskEmail = "commentTaskEmail",
    commentTaskFront = "commentTaskFront",
    createDeleteTaskEmail = "createDeleteTaskEmail",
    createDeleteTaskFront = "createDeleteTaskFront",
    projectEditInfoEmail = "projectEditInfoEmail",
    projectEditInfoFront = "projectEditInfoFront",
    createDeleteSubprojectEmail = "createDeleteSubprojectEmail",
    createDeleteSubprojectInfoFront = "createDeleteSubprojectInfoFront",
    createDeleteBoardEmail = "createDeleteBoardEmail",
    createDeleteBoardFront = "createDeleteBoardFront",
    editBoardEmail = "editBoardEmail",
    editBoardFront = "editBoardFront",
}
