import {useContext, createContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import "../styles/menu.pc.css";
import "./LeftMenu.css";
import "../../globalstyles/ControlElementsStyles.css";
import {Context} from "../../index";
import Logo from "../../assets/logo.svg";
import {useNavigate} from "react-router-dom";
import NavigationPanel from "./NavigationMenu/NavigationPanel";
import {ICheckRole, IHasPermission} from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";


interface MenuFormProps {
    userAccess: IHasPermission[]
}

const MenuForm = ({userAccess}: MenuFormProps) => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const [hrefLogoCompany, setHrefLogoCompany] = useState<string | undefined>(
        ""
    );


    function goToProject() {
        navigate("/projects");
    }

    useEffect(() => {
        (async () => {
            try {
                await store.getSystemSettings();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const picture = await store.getSystemLogo();
            setHrefLogoCompany(picture?.length > 0 ? picture : Logo)
        })();
    }, [store.systemSettings]);

    return (
        <div className="menuForm__component">
            <div className="menuForm__component--head">
                <div className="company-logo-name">
                    <img src={hrefLogoCompany} onClick={goToProject}></img>
                </div>

                <div className="card flex justify-content-center">
                    <NavigationPanel userAccess={userAccess}/>
                </div>
            </div>
            <div className="bottom-group">
                <p>
                    <a className="wiki-link" href={'https://wiki.bpmlab.ru/doc/rukovodstvo-polzovatelya-dRyZh90XbW'} target="_blank">Руководство пользователя</a>
                </p>
            </div>
        </div>
    );
};

export default observer(MenuForm);
