import $api from "..";
import { AxiosResponse } from "axios";

import {ProjectForMenu} from "../models/response/LeftMenuResponse";
import { IFeedback } from "../models/IFeedback";
import {IEditElemSettings, IElemSettings} from "../models/IElemSettings";
import { IGetRole } from "../models/IGetRole";
import {IGetOneRole} from "../models/IGetOneRole";
import {IEditRole} from "../models/IEditRole";
import {IGetAllAvailablePermissions, IRoleFunctionGroups} from "../models/IGetAllAvailablePermissions";
import {ICreateRole} from "../models/ICreateRole";
import {IUser} from "../models/IUser";
import {IGetAllRoles} from "../models/IGetAllRoles";
import {IEditUser} from "../models/IEditUser";
import {ISystemDeletePerson} from "../models/ISystemDeletePerson";
import {ISystemInvitePerson} from "../models/ISystemInvitePerson";
import { ISideBar } from "../models/LeftMenuModels";
import {IGetAllUsersWithSystemRoles} from "../models/IGetAllUsersWithSystemRoles";
import {IExcludePermissionFunction} from "../models/IExcludePermissionFunction";
import {ISaveExcludePermission} from "../models/ISaveExcludePermission";
import {IRestrictAccessBoardsSave, IRestrictAccessBoardsUpdate} from "../models/IRestrictAccess";
import {ICheckRole, IHasPermission} from "../models/IChekRole";

export default class CommonService {

    static async deleteImg(body: {img: string}):Promise<AxiosResponse<any>> {
        return $api.post<any>(`System/deleteImg`, body);
    }

    static async hasPermission(body: ICheckRole): Promise<AxiosResponse<IHasPermission[]>> {
        return $api.post<IHasPermission[]>(`hasPermission`, body);
    }

    static async saveRestrict(body: IRestrictAccessBoardsUpdate): Promise<AxiosResponse<any>> {
        return $api.post<any>(`Board/restrictAccess`, body);
    }

    static async restrictAccess(projectId: number): Promise<AxiosResponse<any>> {
        return $api.get<any>(`Board/restrictAccess?projectId=${projectId}`);
    }

    static async saveExcludeAccessPermission(body: ISaveExcludePermission): Promise<AxiosResponse<any>> {
        return $api.post<any>(`System/excludePermission`, body)
    }

    static async getDataToExcludePermissions(id: number): Promise<AxiosResponse<IExcludePermissionFunction>> {
        return $api.get<IExcludePermissionFunction>(`System/excludePermission?personId=${id}`)
    }

    static async systemInviteUser(body: ISystemInvitePerson): Promise<AxiosResponse<any>> {
    return $api.post<any>(`System/systemInviteUser`, body)
}

    static async deleteSystemPerson(body: ISystemDeletePerson[]): Promise<AxiosResponse<any>> {
        return $api.post<any>(`System/deletePerson`, body)
    }

    static async editUser(body: IEditUser): Promise<AxiosResponse<any>> {
        return $api.post<any>(`System/editUser`, body)
    }

    static async getAllAvailablePermissions(): Promise<AxiosResponse<IRoleFunctionGroups[]>> {
        return $api.get<IRoleFunctionGroups[]>(`System/createRole`);
    }

    static async deleteRole(id: number): Promise<AxiosResponse<any>> {
        return $api.post<any>(`System/deleteRole?id=${id}`)
    }

    static async deleteRoles(reqBody: {id: number[]}): Promise<AxiosResponse<any>> {
        return $api.post<{id: number[]}>(`System/deleteRole`, reqBody)
    }

    static async createRole(reqBody: ICreateRole): Promise<AxiosResponse<any>> {
        return $api.post<ICreateRole>(`System/createRole`, reqBody)
    }

    static async editRole(reqBody: IEditRole): Promise<AxiosResponse<any>> {
        return $api.post<IEditRole>(`System/editRole`, reqBody);
    }

    static async getRole(id: number): Promise<AxiosResponse<IGetOneRole>> {
        return $api.get<IGetOneRole>(`System/getRole?role=${id}`)
    }

    static async getNameAllRoles(): Promise<AxiosResponse<IGetRole[]>> {
        return $api.get<IGetRole[]>(`System/getRoles`)
    }

    static async getAllRoles(): Promise<AxiosResponse<IGetRole[]>> {
        return $api.get<IGetRole[]>(`System/getRoles`)
    }

    static async getSystemCover(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`System/settings/getSystemCover`);
    }

    static async getSystemLogo(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`System/settings/getSystemLogo`);
    }

    static async getProjects(): Promise<AxiosResponse<ISideBar>> {
        return $api.get<ISideBar>(`sideBar`); 
    }

    static async addFeedback(reqBody: IFeedback): Promise<void> {
        return $api.post(`addFeedback`, reqBody); 
    }

    static async addSystemSettings(reqBody: IEditElemSettings[]): Promise<AxiosResponse<any>> {
        return $api.post(`System/addSettings`, reqBody);
    }

    static async editSystemSettings(reqBody: IEditElemSettings[]): Promise<AxiosResponse<any>> {
        return $api.post(`System/editSettings`, reqBody);
    }

    static async getSystemSettings(): Promise<AxiosResponse<IElemSettings[]>> {
        return $api.get("System/settings");
    }

    static async getPublicSettigns(): Promise<AxiosResponse<IElemSettings[]>> {
        return $api.get("/System/getPublicSettings");
    }

    static async getVersion() {
        return $api.get("/System/getVersion");
    }
}