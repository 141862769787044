import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/search.pc.css";
import SearchElem from "../../../assets/search.svg"
import '../../buttons/DefaultButtons.css'

export interface ISearchComponentProps {
    defaultValue: string;
    onSubmitFunc: (value: string) => void;
    changeSearchValue?: (value: string) => void;
}

const Search: FC<ISearchComponentProps> = ({defaultValue, onSubmitFunc, changeSearchValue}) => {
    function handleSubmit(event: any) {
        event.preventDefault();
        onSubmitFunc( event.nativeEvent.srcElement[0].value)
    }

    return (
        <div className="custom_search" >
            <form  onSubmit={handleSubmit} style={{display: "flex"}}>
                <input onChange={changeSearchValue ? (e: any) => changeSearchValue(e.target.value) : () => {}} type="search" value={defaultValue}/>
                <button  type="submit" className='button-base'>
                    <img src={SearchElem} alt=""/>
                </button>
            </form>
        </div>
    )
}

export default observer(Search);