import xsl_svg from "../../../assets/FileIcons/xsl.svg";
import xls_svg from "../../../assets/FileIcons/xsl.svg";
import xlsx_svg from "../../../assets/FileIcons/xsl.svg";
import avi_svg from "../../../assets/FileIcons/avi.svg";
import bmp_svg from "../../../assets/FileIcons/bmp.svg";
import crd_svg from "../../../assets/FileIcons/crd.svg";
import csv_svg from "../../../assets/FileIcons/csv.svg";
import dll_svg from "../../../assets/FileIcons/dll.svg";
import doc_svg from "../../../assets/FileIcons/doc.svg";
import docx_svg from "../../../assets/FileIcons/docx.svg";
import dwg_svg from "../../../assets/FileIcons/dwg.svg";
import eps_svg from "../../../assets/FileIcons/eps.svg";
import exe_svg from "../../../assets/FileIcons/exe.svg";
import flv_svg from "../../../assets/FileIcons/flv.svg";
import gif_svg from "../../../assets/FileIcons/gif.svg";
import html_svg from "../../../assets/FileIcons/html.svg";
import iso_svg from "../../../assets/FileIcons/iso.svg";
import java_svg from "../../../assets/FileIcons/java.svg";
import jpg_svg from "../../../assets/FileIcons/jpg.svg";
import mdb_svg from "../../../assets/FileIcons/mdb.svg";
import mid_svg from "../../../assets/FileIcons/mid.svg";
import mov_svg from "../../../assets/FileIcons/mov.svg";
import mp3_svg from "../../../assets/FileIcons/mp3.svg";
import mp4_svg from "../../../assets/FileIcons/mp4.svg";
import mpeg_svg from "../../../assets/FileIcons/mpeg.svg";
import pdf_svg from "../../../assets/FileIcons/pdf.svg";
import png_svg from "../../../assets/FileIcons/png.svg";
import ppt_svg from "../../../assets/FileIcons/ppt.svg";
import pptx_svg from "../../../assets/FileIcons/ppt.svg";
import ps_svg from "../../../assets/FileIcons/ps.svg";
import psd_svg from "../../../assets/FileIcons/psd.svg";
import pub_svg from "../../../assets/FileIcons/pub.svg";
import rar_svg from "../../../assets/FileIcons/rar.svg";
import raw_svg from "../../../assets/FileIcons/raw.svg";
import rss_svg from "../../../assets/FileIcons/rss.svg";
import svg_svg from "../../../assets/FileIcons/svg.svg";
import tiff_svg from "../../../assets/FileIcons/tiff.svg";
import txt_svg from "../../../assets/FileIcons/txt.svg";
import json_svg from "../../../assets/FileIcons/txt.svg";
import wav_svg from "../../../assets/FileIcons/wav.svg";
import wma_svg from "../../../assets/FileIcons/wma.svg";
import xml_svg from "../../../assets/FileIcons/xml.svg";
import zip_svg from "../../../assets/FileIcons/zip.svg";

export const FileIcons = {
    xsl_svg,
    xls_svg,
    xlsx_svg,
    avi_svg,
    bmp_svg,
    crd_svg,
    csv_svg,
    dll_svg,
    doc_svg,
    docx_svg,
    dwg_svg,
    eps_svg,
    exe_svg,
    flv_svg,
    gif_svg,
    html_svg,
    iso_svg,
    java_svg,
    jpg_svg,
    mdb_svg,
    mid_svg,
    mov_svg,
    mp3_svg,
    mp4_svg,
    mpeg_svg,
    pdf_svg,
    png_svg,
    ppt_svg,
    pptx_svg,
    ps_svg,
    psd_svg,
    pub_svg,
    rar_svg,
    raw_svg,
    rss_svg,
    svg_svg,
    tiff_svg,
    txt_svg,
    json_svg,
    wav_svg,
    wma_svg,
    xml_svg,
    zip_svg,
};