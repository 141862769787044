import {FC, useContext, useEffect, useState} from 'react';
import TaskFiles from "../TaskFiles/TaskFiles";
import {useParams} from "react-router-dom";
import TaskPerson from "../TaskPerson/TaskPerson";
import {IHasPermission} from "../../../models/IChekRole";
import SubTasks from "../TaskRightInfo/SubTasks/SubTasks";
import {ISubTask} from "../../../models/ISubTask";
import {TaskContext} from "../../../pages/Task/Task";
import { Context } from '../../..';

interface ITaskRightInfoParams {
    setSubTasks?: (tasks: ISubTask[]) => void;
    userAccess: IHasPermission[]
}

const TaskRightInfo: FC<ITaskRightInfoParams> = ({setSubTasks, userAccess}) => {
    const {subTasks, allowedProjects} = useContext(TaskContext);
    const { id } = useParams();
    const { store } = useContext(Context);


    // Ограничение прав
    const [viewTaskInfo, setViewTaskInfo] = useState(false);

    useEffect(() => {
        try {
            // Ищем доступ с функцией "TaskAction"
            const taskActionAccess = userAccess.find((access) => access.functionCode === "TaskAction");

            if (taskActionAccess) {
                // Проверяем, есть ли разрешение на просмотр
                const hasViewPermission = taskActionAccess.permissions.some(
                    (permission) => permission.permissionCode === "view" && permission.isGranted
                );

                // Устанавливаем состояние
                setViewTaskInfo(hasViewPermission);
            }
        } catch (err) {
            console.error("Error processing userAccess:", err);
        }
    }, [userAccess]);

    return (
        <div className="task--right__info--menu">
            <div>
                <TaskFiles userAccess={userAccess}/>
            </div>
            <div>
                {!store.getBlockSubtasks() && viewTaskInfo ? (
                    <SubTasks subtaskList={subTasks} allowedProjects={allowedProjects} setSubTasks={setSubTasks} taskId={Number(id)} userAccess={userAccess}/>
                ) : null}
            </div>
            <div>
                <TaskPerson permission={viewTaskInfo}/>
            </div>
        </div>
    );
};

export default TaskRightInfo;