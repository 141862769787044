import React, {useContext, useEffect, useState} from "react";
import {Context} from '../../../index';
// import "../../styles/form.pc.css"
// import "../../../globalstyles/input.pc.css"
// import "../../../globalstyles/background.pc.css"
// import "../../../globalstyles/button.pc.css"
// import "../../../globalstyles/text.pc.css"
import {observer} from "mobx-react-lite";
import CustomLink from "../../Shared/CustomLink/CustomLink";

import Logo from "../../../assets/mainLogo.svg";
import Bacground from "../../../assets/backgroundLogin.jpg";
import {Password} from "primereact/password";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import PrivacyPolicyLink from "../../Shared/PrivacyPolicyLink";
import {AppContext} from "../../../App";

const LoginForm = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    const [nameCompany, setNameCompany] = useState<string | undefined>("");
    const [descriptionCompany, setDescriptionCompany] = useState<string | undefined>("");
    const [hrefLogoCompany, setHrefLogoCompany] = useState<string | undefined>("");
    const [hrefCoverCompany, setHrefCoverCompany] = useState<string | undefined>("");
    const {setIsLoading} = useContext(AppContext);
    const {store} = useContext(Context);

    const handleLogin = async () => {
        let emailErr = validateEmail();
        const passwordErr = validatePassword();

        if (emailErr || passwordErr) return;

        try {
            const errorRes: any = await store.login(email, password);
            if (errorRes) {
                if (errorRes.message ) {
                    if (errorRes.message === 'Invalid password!') setPasswordError("Неверный пароль");
                    else if (errorRes.message === `The user with the mail ${email} does not exist!`)
                        setEmailError("Пользователь с таким email не найден");
                }
            }
        } catch (err) {
        }
    };

    const handleOauth = async (access_token: string) => {
        if (!access_token) return;

        try {
            const errorRes: any = await store.loginOAuth(access_token);
            if (errorRes) {
                if (errorRes.errors && errorRes.errors.length) {
                    const errCode = errorRes.errors[0].status;
                    if (errCode === 400) setPasswordError("Неверный пароль");
                    else if (errCode === 404)
                        setEmailError("Пользователь с таким email не найден");
                    else if (errCode === 401)
                        setEmailError('Ожидает активацию')
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const validateEmail = (): boolean => {
        let emailErr = undefined;

        if (!email) {
            emailErr = "Введите email";
        } else if (!isValidEmail(email)) {
            emailErr = "Некорректный email";
        }

        setEmailError(emailErr);
        return emailErr !== undefined;
    };

    const validatePassword = (): boolean => {
        let passwordErr = undefined;

        if (!password || password.length === 0) {
            passwordErr = "Введите пароль";
        }

        setPasswordError(passwordErr);

        return passwordErr !== undefined;
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const loadLogo = async () => {
        const picture = await store.getSystemLogo();
        setHrefLogoCompany(picture ? picture : Logo)
    };

    const loadCover = async () => {
        const picture = await store.getSystemCover();
        setHrefCoverCompany(picture ? picture : Bacground)
    };

    const loadYandexOAuth = () => {

        (window as any).YaAuthSuggest.init(
            {
                client_id: store.getOAuthId(),
                response_type: 'token',
                redirect_uri: `${store.getExtUrl()}/login/token`
            },
            store.getExtUrl(),
            {
                view: "button",
                parentId: "oauth_container",
                buttonSize: 'l',
                buttonView: 'additional',
                buttonTheme: 'light',
                buttonBorderRadius: "6",
                buttonIcon: 'ya',
            }
        )
            .then((x: any) => x.handler())
            .then((x: any) => handleOauth(x.access_token))
            .catch((error: any) => console.log('Обработка ошибки', error))

    }

    useEffect(() => {
        (async () => {
            try {
                const data = await store.getPublicSettings();
                loadLogo();
                loadCover();
                if (data) {
                    let description = data.find(xx => xx.paramName == "description");
                    if (description) {
                        setDescriptionCompany(description.value);
                    } else {
                        setDescriptionCompany("Слоган компании пользователя");
                    }

                    let name = data.find(xx => xx.paramName == "name");
                    if (name) {
                        setNameCompany(name.value);
                    } else {
                        setNameCompany("Development CS");
                    }
                } else {
                    setDescriptionCompany("Слоган компании пользователя");
                    setNameCompany("Development CS");
                }

                if (store.linkNewPerson != "" && store.userWithStatus.email != "") {
                    setEmail(store.userWithStatus.email)
                }

                (() => {
                    const script = document.createElement("script");
                    script.src = "https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js";
                    script.async = true;
                    script.onload = () => loadYandexOAuth();
                    const oAuth = document.querySelector('.login_in_with_Google.pointer_custom')
                    oAuth?.appendChild(script);

                })()
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);


    return (
        <div
            className="form_main"
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleLogin();
                }
            }}
        >

            <div className="form_container">
                <div className="form_inner">
                    <div className="form_logo">
                        <img src={hrefLogoCompany} alt="Логотип компании"/>
                    </div>
                    <div>
                        <h2 className="form_header form_text">Вход</h2>
                    </div>
                    <div id="oauth_container" className="login_in_with_Google pointer_custom">

                    </div>
                    <div className="login_in_with_Email form_flex_row pointer_custom">
                        <div></div>
                        <a href="">или через Email</a>
                        <div></div>
                    </div>
                    <div
                        className={`input_form_login ${emailError ? "input_with_error" : ""
                        }`}
                    >
                        <label className="input_label" htmlFor="login_email">
                            Email*
                        </label>
                        <InputText
                            id="login_email"
                            value={email}
                            onBlur={validateEmail}
                            placeholder="email"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                        />
                        {emailError ? (
                            <p className="error_message">{emailError}</p>
                        ) : null}
                    </div>
                    <div
                        className={`input_form_login ${passwordError ? "input_with_error" : ""
                        }`}
                    >
                        <label className="input_label" htmlFor="login_pass">
                            Пароль*
                        </label>
                        <Password
                            id="login_pass"
                            value={password}
                            onBlur={validatePassword}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setPassword(e.target.value)}
                            feedback={false}
                            toggleMask
                            placeholder="********"
                        />
                        {passwordError ? (
                            <p className="error_message">{passwordError}</p>
                        ) : null}
                    </div>
                    <div className="login_btn">
                        <Button
                            className="login_btn"
                            label="Войти"
                            onClick={() => {
                                handleLogin();
                            }}
                        />
                    </div>
                    <div className="form_flex_row form__margin-top">
                        <p className="p_gray p_14_login">Еще нет аккаунта?</p>
                        <p className="p_blue p_14 pointer_custom">
                            <CustomLink to="/register">Зарегистрируйтесь</CustomLink>
                        </p>
                    </div>

                    <div className="pointer_custom">
                        <p className="p_blue p_14 ">
                            <CustomLink to="/forgotPassword">
                                Забыли пароль
                            </CustomLink>
                            ?
                        </p>
                    </div>

                    <PrivacyPolicyLink/>
                </div>
            </div>

            <div className="form_background">
                <div>
                    <h1 className="p_white h1_60 ">{nameCompany}</h1>
                    <h3 className="p_white h3_36">
                        {descriptionCompany}
                    </h3>
                </div>
                <img src={hrefCoverCompany} alt="Фон компании"/>
            </div>
        </div>
    );
};

export default observer(LoginForm);
