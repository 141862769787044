import React, {FC, ReactNode, isValidElement, cloneElement, Children, CSSProperties, useState,} from 'react';
import "./Table.css"
import button from "../Button/Button";

interface ITableProps {
    children: ReactNode;
    style?: CSSProperties;
    classBlock?: string | string[];
}

interface ITableRowProps {
    children: ReactNode;
    style?: CSSProperties;
    keyBlock?: React.Key | null | undefined;
    classBlock?: string | string[];
}

interface ITableDataProps {
    children?: ReactNode;
    style?: CSSProperties;
    classBlock?: string | string[];
}

interface ITableDataSortProps {
    children?: ReactNode;
    style?: CSSProperties;
    callBack: (id: number) => void;
    state?: 0 | 1 | 2;
    images: [any, any];
    classBlock?: string | string[];
}

interface ITableHeadProps {
    children: ReactNode;
    style?: CSSProperties;
    classBlock?: string | string[];
}

/**
 * Компонент для создания таблицы.
 *
 * @param {ITableProps} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы таблицы (например, TableHead, TableBody).
 * @param {CSSProperties} [props.style] - Дополнительные стили для элемента table.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент таблицы.
 *
 * @example
 * ```jsx
 * <Table style={{ width: '100%', borderCollapse: 'collapse' }}>
 *  <TableHead>
 *      <TableTr>
 *           <TableTh>Имя</TableTh>
 *          <TableTh>Возраст</TableTh>
 *      </TableTr>
 * </TableHead>
 *  <TableBody>
 *      <TableTr>
 *          <TableTd>Иван</TableTd>
 *           <TableTd>25</TableTd>
 *      </TableTr>
 * </TableBody>
 * </Table>
 * ```
 */
const Table: FC<ITableProps> = ({ children, style, classBlock }) => {
    return (
        <table  style={{...style}}  className={`${classBlock} ui__component--table`}>
            {children}
        </table>
    );
};

/**
 * Компонент для заголовка таблицы.
 *
 * @param {ITableHeadProps} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы заголовка (например, TableTr).
 *  @param {CSSProperties} [props.style] - Дополнительные стили для элемента thead.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент заголовка таблицы.
 */
const TableHead: FC<ITableHeadProps> = ({children, style, classBlock}) => {
    return (
        <thead style={{...style}} className={`${classBlock} ui__component--table__head`}>
            {children}
        </thead>);
}

/**
 * Компонент для тела таблицы.
 *
 * @param {ITableProps} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы тела таблицы (например, TableTr).
 *  @param {CSSProperties} [props.style] - Дополнительные стили для элемента tbody.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент тела таблицы.
 */
const TableBody: FC<ITableProps> = ({ children, style, classBlock }) => {
    return (
        <tbody style={{...style}}  className={`${classBlock}`}>
            {children}
        </tbody>
    );
};

/**
 * Компонент для строки таблицы.
 *
 * @param {ITableRowProps} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы строки таблицы (например, TableTh, TableTd).
 *  @param {CSSProperties} [props.style] - Дополнительные стили для элемента tr.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент строки таблицы.
 */
const TableTr: FC<ITableRowProps> = ({ children, style, keyBlock, classBlock }) => {
    return (
        <tr key={keyBlock} style={{...style}} className={`${classBlock}`}>
            {children}
        </tr>
    );
};

/**
 * Компонент для ячейки заголовка таблицы.
 *
 * @param {ITableDataProps} props - Свойства компонента.
 * @param {ReactNode} [props.children] - Дочерние элементы ячейки заголовка (текст, другие элементы).
 *  @param {CSSProperties} [props.style] - Дополнительные стили для элемента th.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент ячейки заголовка таблицы.
 */
const TableTh: FC<ITableDataProps> = ({ children, style, classBlock }) => {
    return (
        <th style={{...style}} className={`${classBlock} ui__component--table__th`}>
            {children}
        </th>
    );
};

/**
 * Компонент для ячейки заголовка таблицы с сортировкой.
 *
 * @param {ITableDataProps} props - Свойства компонента.
 * @param {ReactNode} [props.children] - Дочерние элементы ячейки заголовка (текст, другие элементы).
 * @param {CSSProperties} [props.style] - Дополнительные стили для элемента th.
 * @param {[ReactNode, ReactNode]} props.images - Массив с двумя изображениями для состояний кнопки.
 * @param {() => void} props.callBack - Функция обратного вызова, вызываемая при клике на кнопку.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент ячейки заголовка таблицы.
 */
const TableThSort: FC<ITableDataSortProps> = ({ children, style, images, state, callBack, classBlock}) => {
    const [clickCount, setClickCount] = useState<0 | 1 | 2>(state ?? 2);

    const handleClick = () => {
        if (clickCount === 0) {
            setClickCount(1);
            callBack(1);
        } else if (clickCount === 1) {
            setClickCount(2);
            callBack(2);
        } else {
            setClickCount(0);
            callBack(0);
        }

    };

    return (
        <th style={{...style}} className={`${classBlock} ui__component--table__sort--th`} onClick={handleClick}>
            <button className="ui__component--table__sort--th__block">
                <a>{children}</a>
                {clickCount !== 2 && images[clickCount] ?
                    <img src={images[clickCount]}/>
                : <div style={{width: "20px"}}></div>}
            </button>
        </th>
    );
};

/**
 * Компонент для ячейки данных таблицы.
 *
 * @param {ITableDataProps} props - Свойства компонента.
 * @param {ReactNode} [props.children] - Дочерние элементы ячейки данных (текст, другие элементы).
 *  @param {CSSProperties} [props.style] - Дополнительные стили для элемента td.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент ячейки данных таблицы.
 */
const TableTd: FC<ITableDataProps> = ({ children, style, classBlock }) => {
    return (
        <td style={{...style}} className={`${classBlock} ui__component--table__td`}>
            {children}
        </td>
    );
};


export  { Table, TableHead, TableBody, TableTr, TableTh, TableTd, TableThSort };