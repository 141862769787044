import React, {FC, useContext, useState} from "react";
import "./PasswordChange.css";
import "../../../globalstyles/input.pc.css"
import CrossImg from "../../../assets/cross_grey.svg";
import {Password} from "primereact/password";
import GrayButton from "../../buttons/ButtonBase/ButtonBase";
import ButtonBase from "../../buttons/ButtonBase/ButtonBase";
import {Context} from "../../../index";
import {AppContext} from "../../../App";

interface IPasswordChangeProps {
    onClose: () => void;
}

const MIN_PASSWORD_LENGTH = 8;

const PasswordChange: FC<IPasswordChangeProps> = ({onClose}) => {
    const { store } = useContext(Context);
    const { showToast } = useContext(AppContext);

    //Текущий
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string | undefined>(undefined);

    //Новый
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState<string | undefined>(undefined);

    //Новый подтверждение
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [newPasswordConfirmError, setNewPasswordConfirmError] = useState<string | undefined>(undefined);

    const validatePassword = (value: string, setter: any): boolean => {
        //Тут всякие валидации по паролям
        let passwordErr = undefined;

        if (!value || value.length === 0) {
            passwordErr = "Введите пароль";
        }

        if (value.length < MIN_PASSWORD_LENGTH) {
            passwordErr = `Минимальная длина пароля: ${MIN_PASSWORD_LENGTH} символов`;
        }

        setter(passwordErr);

        return passwordErr !== undefined;
    };

    async function onSave(){
        const errorMsg = 'Ошибка при смене пароля, пароль не изменен'
        try {
            validatePassword(newPassword, setNewPasswordError)
            // validatePassword(password, setPasswordError)
            validatePassword(newPasswordConfirm, setNewPasswordConfirmError)

            if (newPasswordConfirmError || newPasswordError || passwordError) {
                //Тут надписи будут под полями паролей появляться
                return
                // showToast(errorMsg)
            }

            let res = await store.changePassword({
                oldPassword: password.trim().length ? password.trim() : null,
                newPassword: newPassword,
                newPasswordConfirm: newPasswordConfirm,
            })
            // console.log(res)
            if (res.status === 200) {
                setPassword('')
                setNewPassword('')
                setNewPasswordConfirm('')
                //Show success popup
                showToast('Пароль успешно изменен')
            } else if (res.status === 403){
                setPasswordError("Не верный пароль")
            } else {
                //Show error popup
                showToast(`Ошибка при обновлении пароля: ${res?.data?.message}`)
            }
        } catch(err){
            showToast(errorMsg)
        }
    }

    return (
        <div className="personal-settings-container-container">
            <div className="personal-settings-container">
                <div
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                    <h1 className="header-text">Смена пароля</h1>
                    <button className="button-base personal-settings-close-button" onClick={onClose}>
                        <img src={CrossImg}></img>
                    </button>
                </div>
                <div style={{paddingTop: "24px"}} id="user-personal-settings-password-change-block">
                    <div className="password-change-field-block">
                        <label className="input_label" htmlFor="current-password">Текущий пароль</label>
                        <Password id="current-password" value={password}
                                  // onBlur={() => validatePassword(password, setPasswordError)}
                                  className="input_form_login"
                                  feedback={false} toggleMask placeholder="* * * * *"
                                  onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                  ) => setPassword(e.target.value)}
                        />
                        <p className="error_message">{passwordError ?? "\u00A0"}</p>
                    </div>
                    <div style={{marginTop: "32px"}}>
                        <div className="password-change-field-block">
                            <label className="input_label" htmlFor="new-password">Новый пароль</label>
                            <Password id="new-password" value={newPassword}
                                      onBlur={() => validatePassword(newPassword, setNewPasswordError)}
                                      className="input_form_login"
                                      feedback={false} toggleMask placeholder="* * * * *"
                                      onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                      ) => setNewPassword(e.target.value)}
                            />
                            <p className="error_message">{newPasswordError ?? "\u00A0"}</p>
                        </div>
                        <div className="password-change-field-block">
                            <label className="input_label" htmlFor="new-password-confirm">Подтверждение нового
                                пароля</label>
                            <Password id="new-password-confirm" value={newPasswordConfirm}
                                      onBlur={() => validatePassword(newPasswordConfirm, setNewPasswordConfirmError)}
                                      className="input_form_login"
                                      feedback={false} toggleMask placeholder="* * * * *"
                                      onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                      ) => setNewPasswordConfirm(e.target.value)}
                            />
                            <p className="error_message">{newPasswordConfirmError ?? "\u00A0"}</p>
                        </div>
                        <div className="password-change-field-block">
                            <div className="password-change-form-controls">
                                <div style={{marginLeft: "auto"}}>
                                    <ButtonBase textContent="Отмена" onClickFunc={onClose}
                                                optionalClasses="gray-button blue-font"/>
                                </div>
                                <div style={{paddingLeft: "12px"}}>
                                    <ButtonBase textContent="Сохранить" onClickFunc={onSave}
                                                optionalClasses="blue-button white-font"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PasswordChange;
