import React, {FC, useContext, useEffect, useState} from 'react';
import "./Basket.css"
import Button from "../../component/UiLib/Button/Button";
import UnarchiveButtonPassive from "../../assets/unarchiveButtonPassive.svg"
import DeleteButtonPassive from "../../assets/deleteElementGreyTrue.svg"
import ArrowUp from "../../assets/arrow_up.svg"
import ArrowDown from "../../assets/arrow_down.svg"
import {CurrentTab, TabNavigator} from "../../component/UiLib/TabNavigator/TabNavigator";
import WidgetContainer from "../../component/UiLib/WidgetContainer/WidgetContainer";
import {Table, TableBody, TableHead, TableTd, TableTh, TableThSort, TableTr} from "../../component/UiLib/Table/Table";
import {Context} from "../../index";
import {IViewBasketEntities, IViewBasketResp} from "../../models/IViewBasketResp";
import {IViewBasket} from "../../models/IViewBasket";
import {entitySystemBasket} from "../../models/ConstsModels/entitySystemBasket";
import {fullNameFormat} from "../../helpers/Inicials";
import {dateToNiceString} from "../../helpers/dateToNiceString";
import {AppContext} from "../../App";
import Loader from "../../component/Shared/Loader/Loader";
import {scrollYCheck} from "../../helpers/domUtils";
import {sortAny, SortType} from "../../helpers/sortUtils";
import ConfirmModal from "../../component/ModalsLib/ConfirmModal/ConfirmModal";
import FunctionSystem from '../../models/functionCode/functionsSystem';
import {IHasPermission} from "../../models/IChekRole";

const dataTab: {name: string, code: string}[] = [
    {name: 'Проект', code:  entitySystemBasket.project},
    {name: 'Доска', code:  entitySystemBasket.board},
    {name: 'Колонка', code:  entitySystemBasket.column},
    {name: 'Задача', code:  entitySystemBasket.task},
    {name: 'Файл', code:  entitySystemBasket.file}
]

const Basket: FC = () => {
    // Переменные их состояния
    const {store} = useContext(Context);
    const {chekRole} = useContext(AppContext)
    const { showToast, setBreadCrumb, setNavPanelHighlight } = useContext(AppContext);
    const [allDataEnd, setAllDataEnd] = useState<boolean>(false);
    const [entityType, setEntityType] = useState<entitySystemBasket>(entitySystemBasket.project);
    const [basketData, setBasketData] = useState<IViewBasketEntities[]>([]);
    const [currentBasketData, setCurrentBasketData] = useState<IViewBasketEntities[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [itemTo, setItemTo] = useState<number | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    //Доступы
    const [hasViewPermission, setHasViewPermission] = useState<boolean>(false);
    const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);
    const [hasDeletePermission, setHasDeletePermission] = useState<boolean>(false);
    const GLOBAL_TAKE = 20;

    // Жизненный цикл
    useEffect(() => {
        const abortController = new AbortController();

        const functionCode = new FunctionSystem()
        let data = chekRole(undefined, undefined, functionCode.getAppFunction).then((checkThisRole: IHasPermission[]) => {
            const basketAction = checkThisRole.find(xx => xx.functionCode === "BasketAction");
            setHasViewPermission(basketAction?.permissions.some(xx => xx.permissionCode === "view" && xx.isGranted == true) || false);
            setHasEditPermission(basketAction?.permissions.some(xx => xx.permissionCode === "edit" && xx.isGranted == true) || false);
            setHasDeletePermission(basketAction?.permissions.some(xx => xx.permissionCode === "delete" && xx.isGranted == true) || false);
        });

        setBreadCrumb([{ label: "Корзина", url: "basket" }]);
        setNavPanelHighlight("basket");
        fetchData(basketData.length).then(() => {
        }).catch((error) => {
            showToast("Ошибка загрузщки данных!")
        });

        return() => {
            abortController.abort();
        }
    }, [entityType]);

    // Вспомогательные функции
    const fetchData = async (skip: number) => {
        // debugger
        try {
            let data: IViewBasket = {
                skip: skip ?? 0,
                take: GLOBAL_TAKE,
                filters: {
                    byEntityType: String(entityType)
                },
                sort: {
                    deletedAt: "asc"
                }
            }

            let getData: undefined | IViewBasketResp = undefined

            if (!allDataEnd) {
                getData = await store.viewBasket(data);
            }

            if (getData != undefined) {
                if (skip == 0) {
                    setBasketData([...getData.entity!]);
                    setCurrentBasketData([...getData.entity!]);
                } else {
                    if (getData?.count == 0) {
                        setAllDataEnd(true)
                    }
                    setBasketData(prevBasketData => [...prevBasketData, ...getData?.entity!]);
                    setCurrentBasketData(prevBasketData => [...prevBasketData, ...getData?.entity!]);
                }
            } else {
                setBasketData([]);
                setCurrentBasketData([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const restoreData = async (ids: number[]) => {
        try {
            if (ids?.length <= 0) {
                showToast("Не выбран объект для восстановления!")
                return;
            }

            let getData = await store.restoreBasket(ids);

            if (getData != 200) {
                showToast("Невозможно восстановить этот объект, так как его родитель удален!")
                return;
            }
            await fetchData(0);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const getCurrentRow = (event: React.UIEvent<HTMLDivElement>) => {
        if (scrollYCheck(event) && !allDataEnd) {
            fetchData(basketData.length).then(() => {
            }).catch((error) => {
                showToast("Ошибка загрузщки данных!")
            })
        }
    }

    const deleteBasketFiles = async (ids: number[]) => {
        try {
            if (ids?.length <= 0) {
                showToast("Не выбраны объекты для удаления!")
                return;
            }

            let getData = await store.deleteBasketFiles(ids);

            if (getData == undefined || getData != 200) {
                showToast("Произошла ошибка при удалении данных!")
                return;
            }
            await fetchData(0);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const restoreObject = (id: number) => {
        setShowLoader(true);
        setAllDataEnd(false);
        restoreData([id]).then(() => {
            setShowLoader(false);
        }).catch((error) => {
            showToast("Ошибка загрузщки данных!")
            setShowLoader(false);
        });
    }

    const deleteObject = (id: number) => {
        setShowLoader(true);
        deleteBasketFiles([id]).then(() => {
            setShowLoader(false);
        }).catch((error) => {
            showToast("Ошибка загрузщки данных!")
            setShowLoader(false);
        });
    }

    const sortBasketData = (type: SortType, data: IViewBasketEntities[], sortDirection: "asc" | "desc", sortField: keyof IViewBasketEntities): IViewBasketEntities[] | undefined => {
        let sortValues: (string | number | undefined)[] = []

        if (sortField == "deletedBy" || sortField == "createdBy") {
            sortValues = data.map((item) => item[sortField]["name"]);
        } else {
            sortValues = data.map((item) => item[sortField]);
        }

        const sortedValues = sortAny(type, sortValues, sortDirection);

        if (!sortedValues) {
            return undefined;
        }

        const sortedData = data.slice().sort((a, b) => {
            const aValue = a[sortField];
            const bValue = b[sortField];
            return sortedValues.indexOf(aValue) - sortedValues.indexOf(bValue);
        });

        return sortedData;
    };

    const handleSort = (type: SortType, sortField: keyof IViewBasketEntities, id: number) => {
        let newDirection: "asc" | "desc" | null = null;
        if (id === 0) {
            newDirection = "asc";
        } else if (id === 1) {
            newDirection = "desc";
        } else if (id === 2) {
            newDirection = null;
        }

        if (newDirection === null) {
            setBasketData(currentBasketData);
            return;
        }

        const sortedData = sortBasketData(type, basketData, newDirection, sortField);


        if (sortedData) {
            setBasketData(sortedData);
        }
    };

    const changeTab = (entity: entitySystemBasket) => {
        setShowLoader(true);
        setAllDataEnd(false)
        setCurrentBasketData([]);
        setBasketData([]);
        setEntityType(entity);
        setShowLoader(false);
    }

    const openModal = (itemId: number) => {
        setItemTo(itemId);
        setShowModal(true);
    }

    const closeModal = (check: boolean) => {
        if (check && itemTo) {
            restoreObject(itemTo as number);
            setItemTo(null);
        }
        setShowModal(false);
    }

    const getTable = () => {
        let returnElement: React.ReactElement[] = []

        dataTab.forEach(xx => {
            returnElement.push(
                <CurrentTab key={xx.name} name={xx.name} callBack={() => changeTab(entitySystemBasket[xx.code as entitySystemBasket])}>
                    <WidgetContainer>
                        <Table style={{tableLayout: "fixed", borderSpacing: "12px 12px"}}>
                            <TableHead>
                                <TableTr>
                                    <TableThSort classBlock={xx.code != entitySystemBasket.project ? "page__basket--cell" : "page__basket--cell__main"} images={[ArrowUp, ArrowDown]} callBack={(id) =>  handleSort(SortType.text, "entityName", id)}>{xx.name.toUpperCase()}</TableThSort>
                                    {xx.code != entitySystemBasket.project ?
                                        <TableThSort classBlock={"page__basket--cell__main"} images={[ArrowUp, ArrowDown]} callBack={(id) =>  handleSort(SortType.text, "projectName", id)}>ПРОЕКТ</TableThSort>
                                    : null }
                                    <TableThSort classBlock={"page__basket--cell"} images={[ArrowUp, ArrowDown]} callBack={(id) =>  handleSort(SortType.text, "createdBy", id)}>РУКОВОДИТЕЛЬ</TableThSort>
                                    <TableThSort classBlock={"page__basket--cell"} images={[ArrowUp, ArrowDown]} callBack={(id) =>  handleSort(SortType.text, "deletedBy", id)}>УДАЛИЛ</TableThSort>
                                    <TableThSort classBlock={"page__basket--cell"} images={[ArrowUp, ArrowDown]} callBack={(id) =>  handleSort(SortType.text, "deletedAt", id)}>ДАТА УДАЛЕНИЯ</TableThSort>

                                    {xx.code == entitySystemBasket.file ?
                                        <TableTh classBlock={"page__basket--cell__reverse"}></TableTh>
                                    : null}
                                </TableTr>
                            </TableHead>
                        </Table>
                        <div onScroll={(event) => getCurrentRow(event)} className="page__basket--scroll">
                            <Table style={{tableLayout: "fixed", borderSpacing: "12px 12px"}}>
                                <TableBody>
                                    {basketData?.map((item, i) => (
                                        <TableTr key={item.id}>
                                            <TableTd classBlock={ item.projectName ? "page__basket--cell" : "page__basket--cell__main"}>
                                                {item.entityName}
                                            </TableTd>
                                            {item.projectName ?
                                                <TableTd classBlock={"page__basket--cell__main"}>{item.projectName}</TableTd>
                                            : null }
                                            <TableTd classBlock={"page__basket--cell"}>
                                                {fullNameFormat(
                                                    {
                                                        name: item.createdBy.name,
                                                        surname: item.createdBy.surname,
                                                        middlename: item.createdBy.middleName ?? "",
                                                    },
                                                    "s N M"
                                                )}
                                            </TableTd>
                                            <TableTd classBlock={"page__basket--cell"}>
                                                {fullNameFormat(
                                                    {
                                                        name: item.deletedBy.name,
                                                        surname: item.deletedBy.surname,
                                                        middlename: item.deletedBy.middleName ?? "",
                                                    },
                                                    "s N M"
                                                )}
                                            </TableTd>
                                            <TableTd classBlock={"page__basket--cell"}>
                                                {dateToNiceString(new Date(item.deletedAt), "yyyy-MM-dd")}
                                            </TableTd>
                                            <TableTd classBlock={"page__basket--cell__reverse"}>
                                                <Button
                                                    id={String(i) + "-recover"} text={undefined}
                                                    image={UnarchiveButtonPassive} callBack={() => hasEditPermission ? openModal(item.id) : showToast("У вас нет прав для восстановления объекта!")}/>
                                            </TableTd>
                                            {xx.code == entitySystemBasket.file ?
                                                <TableTd classBlock={"page__basket--cell__reverse"}>
                                                    <Button
                                                        id={String(i) + "-delete-s3"} text={undefined}
                                                        image={DeleteButtonPassive} callBack={() => hasDeletePermission ? deleteBasketFiles([item.id]) : showToast("У вас нет прав для удаления файла!")}/>
                                                </TableTd>
                                            : null}
                                        </TableTr>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </WidgetContainer>
                </CurrentTab>
            )
        })

        return returnElement;
    }


    if (hasViewPermission) {
        return (
            <div className="page__basket">
                {showLoader ? <Loader/> : null}
                {showModal ?
                    <ConfirmModal callBack={(check) => closeModal(check)} confirmText={"Да"} cancelText={"Отмена"} headText={"Восстановить сущность"} bodyText={"Вы уверены что хотите восстановить сущность?"}/>
                : null}
                <TabNavigator>
                    {getTable()}
                </TabNavigator>
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
};

export default Basket;