import React, {FC, useContext, useEffect, useState} from 'react';
import {
    IExcludePermissionFunction,
    IExcludePermissionFunctionRoles,
    IExcludePermissionFunctionFunctionGroups,
    IExcludePermissionFunctionSystemFunctions,
    IExcludePermissionFunctionAccessPermissions
} from "../../../models/IExcludePermissionFunction";
import {Context} from "../../../index";
import ArrowSub from "../../../assets/arrowDown.svg";
import ArrowRight from "../../../assets/ArrowRight.svg";
import "./ProjectEditRoleBoard.css"
import {ISaveExcludePermission} from "../../../models/ISaveExcludePermission";
import {all} from "axios";
import {IRestrictAccess, IRestrictAccessBoardsSave, IRestrictAccessBoardsUpdate} from "../../../models/IRestrictAccess";
import {useParams} from "react-router-dom";
import {IGetOneRole, IRoleAvailablePermissions} from "../../../models/IGetOneRole";
import {access} from 'fs';
import {AppContext} from "../../../App";
// import RestrictAccess from "../RestrictAccess/RestrictAccess";
import {IGetAllUsersWithSystemRolesChecked} from "../../../models/IGetAllUsersWithSystemRoles"
import UserIcon from "../../../assets/user.svg"
import {initials} from "../../../helpers/Inicials";

interface ItemPersonRole {
    name: string;
    code: string;
    personRoleId: number;
}


interface IProjectEditRoleBoard {
    personId: ItemPersonRole | null | undefined
    closeFunc: () => void;
    roleEdit?: IExcludePermissionFunction | undefined
    projectId: number | null | undefined;
}

enum IPermissionCode {
    "create" = "Создание",
    "view" = "Просмотр",
    "delete" = "Удаление",
    "edit" = "Редактирование",
    "full" = "Все"
}

enum IEditPermissionCode {
    "create" = "create",
    "view" = "view",
    "delete" = "delete",
    "edit" = "edit",
    "full" = "full"
}

const ProjectEditRoleBoard: FC<IProjectEditRoleBoard> = ({personId, closeFunc, roleEdit, projectId}) => {
    const {store} = useContext(Context);
    const [userRole, setUserRole] = useState<IExcludePermissionFunction | undefined>();
    const [page, setPage] = useState<boolean>(true);
    const [viewWarning, setViewWarning] = useState<boolean>(false);
    const roleIdG = roleEdit?.roles.findIndex(role => role.personRoleId === personId!.personRoleId)!;
    const [boardsData, setBoardsData] = useState<IRestrictAccess>();
    const [boardsSaveId, setBoardsSaveId] = useState<{ board: number, column: number } | undefined>();
    const {id} = useParams();
    const [allUsers, setAllUsers] = useState<IGetAllUsersWithSystemRolesChecked[] | undefined>([]);
    const {showToast} = useContext(AppContext);

    // const [checkSystemRole, setCheckSystemRole] = useState<boolean>(false);

    function onCheckEvent(id: number) {
        let boardIndex = boardsData?.boards.findIndex(board => board.id === id)!;
        let chekIn = boardsData?.boards[boardIndex].personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId);

        if (chekIn) {
            let data = boardsData?.boards.map(xx => {
                if (xx.id == id) {
                    xx.personRoleIds = xx.personRoleIds?.filter(yy => yy != userRole!.roles[roleIdG].personRoleId)
                    xx.columns = xx.columns.map(zz => {
                        zz.personRoleIds = zz.personRoleIds?.filter(yy => yy != userRole!.roles[roleIdG].personRoleId)
                        return zz;
                    })
                }
                return xx;
            })
            let newData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));
            newData.boards = data!;
            setBoardsData(newData);
        } else {
            let newData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));
            if (newData.boards.find(xx => xx.id == id)?.personRoleIds == undefined) {


                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds!.push(userRole!.roles[roleIdG].personRoleId);
                newData.boards[newData.boards.findIndex(board => board.id === id)].columns =
                    newData.boards[newData.boards.findIndex(board => board.id === id)].columns.map(col => {
                        if (!col.personRoleIds) {
                            col.personRoleIds = [];
                        }

                        col.personRoleIds = [...col.personRoleIds, userRole!.roles[roleIdG].personRoleId];
                        return col;
                    });
            } else {
                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds!.push(userRole!.roles[roleIdG].personRoleId);
                newData.boards[newData.boards.findIndex(board => board.id === id)].columns =
                    newData.boards[newData.boards.findIndex(board => board.id === id)].columns.map(col => {
                        if (!col.personRoleIds) {
                            col.personRoleIds = [];
                        }

                        col.personRoleIds = [...col.personRoleIds, userRole!.roles[roleIdG].personRoleId];
                        return col;
                    });
            }
            setBoardsData(newData)
        }
    }

    function handleViewBlocks(arrowOne: string, arrowTwo: string, block: string) {
        const arrowOneFind = document.getElementById(arrowOne);
        const arrowTwoFind = document.getElementById(arrowTwo);
        const blockFind = document.getElementById(block);

        if (arrowOneFind && arrowTwoFind && blockFind) {
            if (arrowOneFind.style.display === 'none') {
                arrowOneFind.style.display = 'block';
            } else {
                arrowOneFind.style.display = 'none';
            }
            if (arrowTwoFind.style.display === 'none') {
                arrowTwoFind.style.display = 'block';
            } else {
                arrowTwoFind.style.display = 'none';
            }
            if (blockFind.style.display === 'none') {
                blockFind.style.display = 'block';
            } else {
                blockFind.style.display = 'none';
            }
        }
    }

    function saveExclude() {
        let allChoosePerm = userRole!.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);

        let allChoosePermSystem = userRole!.roles[roleIdG].functionGroups.flatMap(xx =>
            xx.systemFunctions.flatMap(yy =>
                yy.accessPermissions.map(zz => zz.id)
            )
        );

        const data: ISaveExcludePermission = {
            projectId: Number(id),
            personRoleId: userRole!.roles[roleIdG].personRoleId,
            accessPermissionIds: allChoosePerm
        };

        let nowUserId = userRole!.roles[roleIdG].personRoleId;

        let restrict: IRestrictAccessBoardsUpdate = {
            personRoleId: nowUserId,
            boards: [],
            boardIdsToDeleteAccess: [],
            columnIdsToDeleteAccess: [],
            projectId: Number(id),
        };

        if (boardsData?.boards) {
            boardsData.boards.forEach(board => {
                // true - удаляем, false - добавляем
                let stepBoard = board.personRoleIds?.includes(nowUserId);

                // true - удаляем, false - добавляем
                let allColumnsId = board.columns.flatMap(column => {
                    if (column.personRoleIds?.includes(nowUserId)) {
                        return column.id;
                    }
                    return [];
                })

                let allColumnsToSave = board.columns.flatMap(column => {
                    if (!column.personRoleIds?.includes(nowUserId)) {
                        return column.id;
                    }
                    return [];
                })

                // Если пользователь добавлен в personRoleId борды - удалить его, если нет, значит добавить на борду
                // true - удаляем, false - добавляем
                if (stepBoard) {
                    let allColumnBoard = board.columns.map(xx => xx.id)
                    restrict?.boardIdsToDeleteAccess!.push(board.id);

                    allColumnBoard.forEach(zz =>
                        restrict?.columnIdsToDeleteAccess!.push({columnId: zz, boardId: board.id})
                    );
                } else {
                    //Если пользователь ограничен по всем колонкам
                    if (board.columns.length == allColumnsId.length) {
                        allColumnsId.forEach(zz =>
                            restrict?.columnIdsToDeleteAccess!.push({columnId: zz, boardId: board.id})
                        );
                    } else {
                        let saveAccess = board.columns.map(xx => xx.id).filter(xx => !allColumnsId.includes(xx));

                        allColumnsId.forEach(zz =>
                            restrict?.columnIdsToDeleteAccess!.push({columnId: zz, boardId: board.id})
                        );

                        restrict?.boards!.push({id: board.id, columnIds: saveAccess});

                        if (allColumnsId.length != 0) {
                            restrict?.boards!.push({id: board.id, allColumns: false});
                        } else {
                            restrict?.boards!.push({id: board.id, allColumns: true});
                        }
                    }
                }

            })
        }

        console.log("restrict", restrict);

        (async () => {
            try {
                if (restrict) {
                    const boardSave = await store.saveRestrict(restrict);
                }
                if (data) {
                    const res = await store.saveExcludeAccessPermission(data);
                }
                closeFunc();
            } catch (err) {
                console.log(err)
            }
        })();
    }

    function changeGroup(groupId: number, checked: boolean) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(JSON.stringify(userRole));

        if (getData && getData.roles[roleIdG]) {
            let currentGroup = getData.roles[roleIdG].functionGroups.find((xx) => xx.id == groupId);
            let allSystemFunction = currentGroup!.systemFunctions.flatMap(xx => xx.accessPermissions);
            let allExclude = getData.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);

            if (checked == false) {
                allSystemFunction.forEach((xx) => {
                    if (!allExclude.includes(xx!.id)) {
                        getData!.roles[roleIdG].excludedPermissions.push({
                            id: 0,
                            accessPermission: xx!.id,
                            personRoleId: Number(personId!.code!)
                        });
                    }
                })
            } else {
                let newAccess = allSystemFunction.map(xx => xx!.id);
                getData.roles[roleIdG].excludedPermissions =
                    getData!.roles[roleIdG].excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
            }
        }
        setUserRole(getData);
        checkAccessToBoard(getData);
    }

    function changeSystemFunctions(systemFunction: number, checked: boolean) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(JSON.stringify(userRole));

        if (getData && getData.roles[roleIdG]) {
            let group = getData.roles[roleIdG].functionGroups.map((xx) => xx);
            let system = group.flatMap(xx => {
                const foundSystemFunction = xx.systemFunctions.find((xx) => xx.id === systemFunction);
                return foundSystemFunction ? foundSystemFunction : undefined;
            });
            let allAccessItem = system
                .flatMap(xx => xx?.accessPermissions)
                .filter(accessPermission => accessPermission !== undefined);
            let allExclude = getData.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);
            if (checked == false) {
                allAccessItem.forEach((xx) => {
                    if (!allExclude.includes(xx!.id)) {
                        getData!.roles[roleIdG].excludedPermissions.push({
                            id: 0,
                            accessPermission: xx!.id,
                            personRoleId: Number(personId!.code!)
                        });
                    }
                })
            } else {
                let newAccess = allAccessItem.map(xx => xx!.id);
                getData.roles[roleIdG].excludedPermissions =
                    getData!.roles[roleIdG].excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
            }
        }
        setUserRole(getData);
        checkAccessToBoard(getData);

    }

    function checkAccessToBoard(getData: IExcludePermissionFunction | undefined) {
        let allSystemFunction = getData?.roles[roleIdG].functionGroups.flatMap(xx => xx.systemFunctions);
        let findBoardAccess = allSystemFunction?.find(xx =>
            xx.functionCode == "BoardAction")
        let findViewBoard = findBoardAccess?.accessPermissions.find(yy => yy.code == "view");
        let allExcludePerm = getData?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);

        let newBoardData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));

        if (findViewBoard != undefined && allExcludePerm?.includes(findViewBoard.id)) {
            // нужно записать доски
            newBoardData.boards = newBoardData?.boards.map(xx => {
                if (xx.personRoleIds?.includes(getData!.roles[roleIdG].personRoleId)) {
                    return xx
                } else {
                    if (xx.personRoleIds == undefined) {
                        xx.personRoleIds = []
                    }
                    xx.personRoleIds.push(getData!.roles[roleIdG].personRoleId);
                    xx.columns = xx.columns.map(yy => {
                        if (yy.personRoleIds?.includes(getData!.roles[roleIdG].personRoleId)) {
                            return yy;
                        } else {
                            if (yy.personRoleIds == undefined) {
                                yy.personRoleIds = []
                            }
                            yy.personRoleIds.push(getData!.roles[roleIdG].personRoleId);
                        }
                        return yy;
                    });
                }
                return xx
            })
        } else {
            newBoardData.boards = newBoardData?.boards.map(xx => {
                xx.personRoleIds = xx.personRoleIds?.filter(zz => zz != getData!.roles[roleIdG].personRoleId)
                xx.columns = xx.columns.map(zy => {
                    zy.personRoleIds = zy.personRoleIds?.filter(zz => zz != getData!.roles[roleIdG].personRoleId)
                    return zy;
                })
                return xx;
            })
        }

        setBoardsData(newBoardData);
    }

    function changeTab() {
        let allSystemFunction = userRole?.roles[roleIdG].functionGroups.flatMap(xx => xx.systemFunctions);
        let findBoardAccess = allSystemFunction?.find(xx =>
            xx.functionCode == "BoardAction")
        let findViewBoard = findBoardAccess?.accessPermissions.find(yy => yy.code == "view");
        let allExcludePerm = userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);

        if (findViewBoard == undefined) {
            showToast("Пользователю не выдан доступ на просмотр доски!")
            return;
        }

        if (allExcludePerm?.includes(findViewBoard.id)) {
            showToast("Пользователю не выдан доступ на просмотр доски!")
            return;
        }

        setPage(!page);
    }

    function chengeAccessPermissions(accessPermission: number, accessPermissionCode: string) {

        let getData: IExcludePermissionFunction | undefined = JSON.parse(
            JSON.stringify(userRole)
        );
        if (getData && getData.roles[roleIdG]) {
            let allAccessItem = getData.roles[roleIdG].excludedPermissions.map((xx) => xx.accessPermission);
            let findItem = allAccessItem.find((xx) => xx === accessPermission);

            // Добавляем доступ
            if (findItem) {
                getData.roles[roleIdG].excludedPermissions =
                    getData.roles[roleIdG].excludedPermissions.filter(
                        (xx) => xx.accessPermission !== accessPermission
                    );
                // Забираем доступ
            } else {
                getData.roles[roleIdG].excludedPermissions.push({
                    id: 0,
                    accessPermission: accessPermission,
                    personRoleId: Number(personId!.code!)
                });
            }


        }
        setUserRole(getData);
        checkAccessToBoard(getData);
    };

    function getUsers() {
        let dataRoleEdit = roleEdit;
        (async () => {
            try {
                const users = await store.getAllUsersWithSystemRoles(Number(id));
                // const dataRoles = await store.getAllRoles();

                if (users) {
                    let data: IGetAllUsersWithSystemRolesChecked[] = [];
                    users.map((xx) => {
                        data.push({
                            chek: false,
                            user: xx
                        })
                    });

                    let currentSystemRoleUser = data.map(yy => yy.user).find(xx => dataRoleEdit!.roles.find(zz => {
                        if (zz.personRoleId == Number(xx.personRolesId)) {
                            return zz
                        }
                    }))

                    if (!dataRoleEdit || !currentSystemRoleUser) {
                        return console.log("Не удалось получить роли!");
                    }

                    let nowRoleEdit = dataRoleEdit?.roles[roleIdG];
                    let systemRoleId = currentSystemRoleUser.personRolesId;
                    let systemRoleFind = dataRoleEdit.roles.find(role => role.personRoleId == systemRoleId);

                    if (!systemRoleFind) {
                        return console.log("Такой роли не существует!");
                    }

                    let systemGroup = systemRoleFind!.functionGroups;
                    let systemFunc = systemGroup.flatMap(xx => xx.systemFunctions).filter(xx => xx.accessPermissions.length);

                    let newRoleGroups = nowRoleEdit.functionGroups.map(projGroup => {
                        if (systemGroup.map(xx => xx.id).includes(projGroup.id)) {
                            projGroup.systemRole = true;
                            projGroup.systemFunctions = projGroup.systemFunctions.filter(xx => xx.accessPermissions.length)
                            projGroup.systemFunctions = projGroup.systemFunctions.map(projFunc => {
                                if (systemFunc.map(xx => xx.id).includes(projFunc.id)) {
                                    projFunc.systemRole = true;

                                    let sysFindFunc = systemFunc.find(xx => xx.functionCode == projFunc.functionCode)

                                    projFunc.accessPermissions = projFunc.accessPermissions.map(permission => {
                                        if (sysFindFunc && sysFindFunc.accessPermissions.map(xx => xx.code).includes(permission.code)) {
                                            permission.systemRole = true;
                                        } else {
                                            permission.systemRole = false;
                                        }

                                        return permission;
                                    });

                                    sysFindFunc && sysFindFunc.accessPermissions?.forEach(perm => {
                                        if (!projFunc.accessPermissions.some(p => p.code === perm.code)) {
                                            projFunc.accessPermissions.push({
                                                id: perm.id,
                                                systemRole: true,
                                                code: perm.code as "view" | "create" | "delete" | "edit" | "full"
                                            });
                                        }
                                    });

                                    return projFunc;
                                }

                                projFunc.systemRole = false;
                                return projFunc;
                            })


                            return projGroup;
                        }

                        return projGroup;
                    })

                    systemGroup?.forEach(group => {
                        if (!newRoleGroups.some(p => p.id === group.id)) {
                            newRoleGroups.push({
                                id: group.id,
                                name: group.name,
                                systemRole: true,
                                systemFunctions: group.systemFunctions?.map(func => {
                                    if (func) {
                                        func.systemRole = true;
                                        func.accessPermissions = func.accessPermissions?.map(access => {
                                            if (access) {
                                                access.systemRole = true;
                                                return access;
                                            }
                                            return access;
                                        }) || [];
                                        return func;
                                    }
                                    return func;
                                }) || []
                            });
                        }
                    });

                    dataRoleEdit.roles[roleIdG].functionGroups = newRoleGroups;

                    if (dataRoleEdit && dataRoleEdit.roles && dataRoleEdit.roles[roleIdG] && dataRoleEdit.roles[roleIdG].functionGroups) {
                        const functionGroups = dataRoleEdit.roles[roleIdG].functionGroups;
                        const sortedFunctionGroups: IExcludePermissionFunctionFunctionGroups[] = [...functionGroups].sort(sortFunctionGroups);

                        dataRoleEdit.roles[roleIdG].functionGroups = sortedFunctionGroups;

                        setUserRole(dataRoleEdit)
                    }
                    setAllUsers(data);
                }
            } catch (err) {
                console.log(err)
            }
        })();
    }

    function addColumnAccess(columnId: number, addChek: boolean) {
        let findCurrentBoard = boardsData?.boards.find(xx => xx.columns.some(xx => xx.id == columnId));

        let newBoardStatus: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));

        let currentPersonRoleId = roleEdit!.roles[roleIdG]!.personRoleId;

        if (findCurrentBoard?.personRoleIds?.includes(currentPersonRoleId)) {
            showToast("Пользователь не добавлен на канбан доску!")
            return;
        }

        newBoardStatus.boards = newBoardStatus?.boards.map(xx => {
            if (xx.id == findCurrentBoard?.id) {
                let findColumn = xx.columns.findIndex(yy => yy && yy.id === columnId);
                if (findColumn != -1 && addChek == true && xx.columns[findColumn!].personRoleIds?.length && xx.columns[findColumn!].personRoleIds?.includes(currentPersonRoleId)) {
                    let newPersonRole = xx.columns[findColumn!].personRoleIds?.filter(roleId => roleId !== currentPersonRoleId)
                    // let newPersonRole = xx.columns[findColumn!].personRoleIds.filter(roleId => roleId !== currentPersonRoleId);
                    xx.columns[findColumn!].personRoleIds = newPersonRole != undefined ? newPersonRole : []
                } else {
                    xx.columns[findColumn].personRoleIds?.push(currentPersonRoleId);
                }

                return xx;
            }

            return xx;
        })

        setBoardsData(newBoardStatus);
    }

    function setNewStatusBoard() {
        let newBoardStatus: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));

        newBoardStatus.boards = newBoardStatus.boards.map(board => {
            if (board.id == boardsSaveId?.board) {
                board.personRoleIds?.push(roleEdit!.roles[roleIdG]!.personRoleId);
                board.columns = board.columns.map(column => {
                    if (column.id == boardsSaveId.column) {
                        column.selected = true;
                        column.personRoleIds?.push(roleEdit!.roles[roleIdG]!.personRoleId)
                        return column;
                    } else {
                        return column;
                    }
                })
                return board;
            } else {
                return board;
            }
        })

        setBoardsData(newBoardStatus);

        setViewWarning(false);
    }

    useEffect(() => {

    }, [personId]);

    useEffect(() => {
        const data: IExcludePermissionFunction | undefined = roleEdit;

        if (data && data.roles && data.roles[roleIdG] && data.roles[roleIdG].functionGroups) {
            const functionGroups = data.roles[roleIdG].functionGroups;
            const sortedFunctionGroups: IExcludePermissionFunctionFunctionGroups[] = [...functionGroups].sort(sortFunctionGroups);

            data.roles[roleIdG].functionGroups = sortedFunctionGroups;

            setUserRole(data)
        }
    }, [roleEdit]);

    function sortFunctionGroups(a: IExcludePermissionFunctionFunctionGroups, b: IExcludePermissionFunctionFunctionGroups): number {
        const order = ["Проект", "Доска", "Задача", "Система"];
        const indexA = order.indexOf(a.name);
        const indexB = order.indexOf(b.name);
        return indexA - indexB;
    }

    function checkSystemRole(): boolean {
        const findSystemId = roleEdit?.roles.find(xx => xx.systemRole == true);

        if (findSystemId) {
            let systemFunc = findSystemId.functionGroups.flatMap(xx => xx.systemFunctions);
            let findFunctionCode = systemFunc.find(xx => xx.functionCode == "BoardAction");
            let findPermission = findFunctionCode?.accessPermissions.find(xx => xx.code == "view");
            let chekPermission = findSystemId.excludedPermissions.find(xx => xx.id == findPermission?.id)

            if (findPermission && chekPermission == undefined) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    };

    useEffect(() => {
        (async () => {
            try {
                getUsers();

                if (projectId) {
                    const boardData = await store.restrictAccess(projectId)
                    setBoardsData(boardData);

                } else {
                    console.log("Не найден id проекта!")
                }

            } catch (err) {
                console.log(err)
            }
        })();
    }, [roleEdit]);

    return (
        <div className="project__edit--role__board--component">
            <div className="project__edit--role__board--component__in">
                <div className="project__edit--role__board--component__role">
                    {initials(`${personId?.name}`)} - {userRole?.roles[roleIdG].roleName}
                </div>
                <div className="project__edit--role__board--component__tab">
                    <div
                        className={`project__edit--role__board--component__head ${page ? "project__edit--role__board--component__head--choise" : ""}`}
                        onClick={changeTab}>
                        Права участника
                    </div>
                    <div
                        className={`project__edit--role__board--component__head ${page ? "" : "project__edit--role__board--component__head--choise"}`}
                        onClick={changeTab}>
                        Доступ к канбан доскам
                    </div>
                </div>
                {page ?
                    <div>
                        <div className="project__edit--role__board--component__edit">
                            <div>
                                <div className="project__edit--role__board--component__role">

                                </div>
                                <div className="project__edit--role__board--component__block">
                                    {userRole?.roles[roleIdG].functionGroups.map((group) => (
                                        <div>
                                            <div className="project__edit--role__board--access__permissions">
                                                <div className="project__edit--role__board--arrow"
                                                     onClick={() => (handleViewBlocks("ShowGroupSub" + String(group.id),
                                                         "ShowGroupRight" + String(group.id), "ShowGroupBlock" + String(group.id)))}>
                                                    <img src={ArrowSub} id={"ShowGroupSub" + String(group.id)}
                                                         style={{display: 'none'}}/>
                                                    <img src={ArrowRight} id={"ShowGroupRight" + String(group.id)}/>
                                                </div>
                                                <div>
                                                    {group.name}
                                                </div>
                                                <div
                                                    className="project__edit--role__board--access__permissions--input__margin">
                                                    <div
                                                        className={`project__edit--role__board--access__permissions--input ${group.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                        <input type="checkbox"
                                                               checked={group.systemFunctions.some(systemFunction =>
                                                                   systemFunction.accessPermissions.some(accessPermission =>
                                                                       !userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   ))}
                                                               onClick={() => (group.systemRole != true ? changeGroup(group.id, group.systemFunctions.some(systemFunction =>
                                                                   systemFunction.accessPermissions.some(accessPermission =>
                                                                       userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   ))) : showToast("Эта функция выдана в рамках системной роли!"))
                                                               }/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={"ShowGroupBlock" + String(group.id)}
                                                 className="project__edit--role__board--access__permissions--block"
                                                 style={{display: 'none'}}>
                                                {group.systemFunctions.map((systemFunction) => (
                                                    <div>
                                                        <div
                                                            className="project__edit--role__board--access__permissions">
                                                            <div className="project__edit--role__board--arrow"
                                                                 onClick={() => (handleViewBlocks("ShowAccessPermissionsSub" + String(systemFunction.id),
                                                                     "ShowAccessPermissionsRight" + String(systemFunction.id), "ShowAccessPermissionsBlock" + String(systemFunction.id)))}>
                                                                <img src={ArrowSub}
                                                                     id={"ShowAccessPermissionsSub" + String(systemFunction.id)}
                                                                     style={{display: 'none'}}/>
                                                                <img src={ArrowRight}
                                                                     id={"ShowAccessPermissionsRight" + String(systemFunction.id)}/>
                                                            </div>
                                                            <div className="project__edit--role__board--access__permissions--name__function">
                                                                {systemFunction.nameFunction}
                                                            </div>
                                                            <div
                                                                className="project__edit--role__board--access__permissions--input__margin">
                                                                <div
                                                                    className={`project__edit--role__board--access__permissions--input ${systemFunction.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                                    <input type="checkbox"
                                                                           checked={systemFunction.accessPermissions.some(accessPermission =>
                                                                               !userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                           )}
                                                                           onClick={() => (systemFunction.systemRole != true ? changeSystemFunctions(systemFunction.id, systemFunction.accessPermissions.some(accessPermission =>
                                                                               userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                           )) : showToast("Эта функция выдана в рамках системной роли!"))}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id={"ShowAccessPermissionsBlock" + String(systemFunction.id)}
                                                            className="project__edit--role__board--access__permissions--block__item"
                                                            style={{display: 'none'}}>
                                                            {systemFunction.accessPermissions.map((accessPermission) => (
                                                                IPermissionCode[accessPermission.code as IEditPermissionCode] &&
                                                                IEditPermissionCode[accessPermission.code as IEditPermissionCode] !==
                                                                IEditPermissionCode.full ?
                                                                    <div
                                                                        className="project__edit--role__board--access__permissions--flex">
                                                                        <div
                                                                            className="project__edit--role__board--access__permissions--item">
                                                                            {IPermissionCode[accessPermission.code as IEditPermissionCode]}
                                                                        </div>
                                                                        <div
                                                                            className="project__edit--role__board--access__permissions--input__margin">
                                                                            <div
                                                                                className={`project__edit--role__board--access__permissions--input ${accessPermission.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                                                <input type="checkbox"
                                                                                       checked={!userRole?.roles[roleIdG].excludedPermissions.map(xx =>
                                                                                           xx.accessPermission).includes(accessPermission.id)}
                                                                                       onClick={() => (accessPermission.systemRole != true ? chengeAccessPermissions(accessPermission.id, accessPermission.code) : showToast("Эта функция выдана в рамках системной роли!"))}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="project__edit--role__board--component__edit">
                        <div className="restrict__access--list__board--head">
                            Канбан доски
                        </div>
                        <div className="project__edit--role__board--component__block">
                            {boardsData?.boards.map((board) => (
                                <div>
                                    <div className="restrict__access--list__board">
                                        <div className="restrict__access--list__board--desc">
                                            {board.name}
                                        </div>
                                        <div className="restrict__access--list__board--chek">
                                            <div
                                                className={`restrict__access--list__board--chek__size ${checkSystemRole() == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                <input type="checkbox"
                                                       checked={checkSystemRole() == true ? true : board.personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId) ? false : true}
                                                       onClick={() => (checkSystemRole() != true ? onCheckEvent(board.id) : showToast("Доступ ко всем доскам проекта выдан в рамках системной роли!"))}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="restrict__access--list__columns">
                                        {board.columns.sort((a,b)=>a.state-b.state).map((column) => (
                                            <div
                                                className={board.personRoleIds?.includes(roleEdit!.roles[roleIdG].personRoleId) ? "restrict__access--list__column" : "restrict__access--list__column restrict__access--list__column--gray"}>
                                                <div className="restrict__access--list__column--name">
                                                    {column.name}
                                                </div>
                                                <div className="restrict__access--list__column--chek">
                                                    <div
                                                        className={`restrict__access--list__board--chek__size ${checkSystemRole() == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                        <input type="checkbox"
                                                               checked={checkSystemRole() == true ? true : column.personRoleIds?.includes(roleEdit!.roles[roleIdG]!.personRoleId) ? false : true}
                                                               onClick={() => (checkSystemRole() != true ? addColumnAccess(column.id, !!column.personRoleIds?.includes(roleEdit!.roles[roleIdG]!.personRoleId)) :
                                                                   showToast("Доступ ко всем колонкам проекта выдан в рамках системной роли!"))}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <div className="project__edit--role__board__buttons">
                    <div className="project__edit--role__board__buttons--exit">
                        <button onClick={closeFunc}>
                            Отмена
                        </button>
                    </div>
                    <div className="project__edit--role__board__buttons--add">
                        <button onClick={saveExclude}>
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectEditRoleBoard;