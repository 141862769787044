import React, {FC} from 'react';
import './RightPopup.css'
import CloseIcon from '../../../assets/ButtonIcons/big-closeX.svg'
import {ISubTask} from "../../../models/ISubTask";

interface IRightPopupProps {
    onClose: () => void;
    name: string;
    children: React.ReactNode;
    nonblocking?: boolean;
    width?: number;
}

const RightPopup: FC<IRightPopupProps> = ({children, name,nonblocking,width,onClose}) => {
    return <div className={`right-popup-wrapper row-align ${nonblocking ? 'nonblocking' : ''}`}>
        <div className='right-popup-panel' style={{width: width ? `${width}px` : '550px'}}>
            <div className='right-popup-header row-align'>
                <div style={{overflow: 'hidden', paddingTop: '3px'}} className='h1_28'>{name}</div>
                <div style={{marginLeft: 'auto'}} className='right-popup-close' onClick={onClose}><img src={CloseIcon} alt="Close"/></div>
            </div>
            <div className='right-popup-children-container'>
                {children}
            </div>
        </div>
    </div>
}
export default RightPopup;
