import React, { useContext, useState } from "react";
import "./UserMonitorToolbar.css";
import "../../../globalstyles/text.pc.css";
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import BlueStarIcon from "../../../assets/ButtonIcons/favourite-icon-blue.svg";
import BlueStarIconActive from "../../../assets/ButtonIcons/favourite-icon-blue-active.svg";

import DisplayOptionRowsIcon from "../../../assets/ButtonIcons/display-type-rows-icon-blue.svg";
import DisplayOptionCardsIcon from "../../../assets/ButtonIcons/display-type-cards-icon-blue.svg";
import BtnCancel from "../../../component/buttons/CancelButton/CancelButton";

import Search from "../../Shared/Search/Search";
import { InputSwitch } from "primereact/inputswitch";

import MonitorFastFilter from "../FastFilters/MonitorFastFilter";
import { UserMonitorContext } from "../../../pages/UserMonitor/UserMonitor";

interface IUserMonitorToolbarProps {
    showOnlyFavourite: boolean;
    showCompleted: boolean;

    setShowOnlyFavourite: (value: boolean) => void;
    setShowCompleted: (value: boolean) => void;

    //Поиск
    taskNameSearch: string;
    setTaskNameSearch: (value: string) => void;
    onSearchSubmit: (value: string) => void;
}

const UserMonitorToolbar: React.FC<IUserMonitorToolbarProps> = ({
    showOnlyFavourite,
    showCompleted,
    setShowOnlyFavourite,
    setShowCompleted,
    taskNameSearch,
    setTaskNameSearch,
    onSearchSubmit
}) => {

    const {
        filters,
        setFilters,
        checkedFilters,
        setCheckedFilters,
        datesFilter,
        setDatesFilter,
        resetFilter,
    } = useContext(UserMonitorContext);

    const [filterCounter, setFilterCounter] = useState<number>(0);

    function onShowCompletedChange() {
        setShowCompleted(!showCompleted);
    }

    return (
        <div className="user-monitor-toolbar">
            <div className="user-monitor-toolbar-left-part">
                <MonitorFastFilter />
                <div className="user-monitor-toolbar-switch-panel">
                    <a className="a_header_name_16">Показать завершенные</a>
                    <InputSwitch
                        className={
                            showCompleted ? "show-completed-input-switch" : ""
                        }
                        checked={showCompleted}
                        onChange={onShowCompletedChange}
                    />
                </div>
            </div>
            <div className="user-monitor-toolbar-right-part">
                <Search
                    defaultValue={taskNameSearch}
                    onSubmitFunc={onSearchSubmit}
                    changeSearchValue={setTaskNameSearch}
                />
                <div
                    className="user-monitor-toolbar-button"
                    onClick={(e) => {
                        setShowOnlyFavourite(!showOnlyFavourite);
                    }}
                >
                    {showOnlyFavourite ? (
                        <img src={BlueStarIconActive} alt="Только избранные" />
                    ) : (
                        <img src={BlueStarIcon} alt="Только избранные" />
                    )}
                </div>
                <div className="user-monitor-toolbar-button">
                    <BtnFilter
                        counter={filterCounter}
                        setCounter={setFilterCounter}
                        filterData={filters}
                        setFilterData={setFilters}
                        applayFilters={async () => {}}
                        checkedFilters={checkedFilters}
                        setCheckedFilters={setCheckedFilters}
                        dates={datesFilter}
                        setDates={setDatesFilter}
                        selectedUsers={[]}
                        setSelectedUsers={() => {}}
                        showApplyButton={false}
                    />
                </div>
                {(filterCounter !== 0 || taskNameSearch) && (
                    <div className="user-monitor-toolbar-button">
                        <BtnCancel onClickFunc={resetFilter} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserMonitorToolbar;
