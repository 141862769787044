import { FC, useEffect, useState } from "react";

interface LineConnectorProps {
    offset: [number, number];
    parentHeight: number;
    sourceId: string;
    targetId: string;
    updateFlag: boolean; //переменная нужна чтобы триггерить обновление координат линий, другого способа не нашел
}

const LineConnector: FC<LineConnectorProps> = ({
    offset,
    parentHeight,
    sourceId,
    targetId,
    updateFlag,
}) => {
    const [lineCoordinates, setLineCoordinates] = useState({
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
    });

    useEffect(() => {
        const updateLineCoordinates = () => {
            const sourceElement = document.getElementById(sourceId);
            const targetElement = document.getElementById(targetId);

            if (sourceElement && targetElement) {
                const sourceRect = sourceElement.getBoundingClientRect();
                const targetRect = targetElement.getBoundingClientRect();

                setLineCoordinates({
                    x1: sourceRect.left + sourceRect.width / 2 + offset[0],
                    y1: sourceRect.top + sourceRect.height / 2 + offset[1],
                    x2: targetRect.left + targetRect.width / 2 + offset[0],
                    y2: targetRect.top + targetRect.height / 2 + offset[1],
                });
            }
        };

        const sourceElement = document.getElementById(sourceId);
        const targetElement = document.getElementById(targetId);

        const resizeObserver = new ResizeObserver(updateLineCoordinates);

        if (sourceElement) resizeObserver.observe(sourceElement);
        if (targetElement) resizeObserver.observe(targetElement);

        updateLineCoordinates();

        // Подписываемся на событие window.resize для обновления координат
        window.addEventListener("resize", updateLineCoordinates);

        return () => {
            resizeObserver.disconnect();
            window.removeEventListener("resize", updateLineCoordinates);
        };
    }, [sourceId, targetId, updateFlag, offset]);

    return (
        <div
            id="line-container"
            style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                pointerEvents: "none",
            }}
        >
            <svg className="line-svg" width="300px" height={parentHeight}>
                <line
                    id="connector-line"
                    stroke="#E1E7EA"
                    x1={lineCoordinates.x1}
                    y1={lineCoordinates.y1}
                    x2={lineCoordinates.x1}
                    y2={lineCoordinates.y2}
                />
                <line
                    id="connector-line"
                    stroke="#E1E7EA"
                    x1={lineCoordinates.x1}
                    y1={lineCoordinates.y2}
                    x2={lineCoordinates.x2}
                    y2={lineCoordinates.y2}
                />
            </svg>
        </div>
    );
};

export default LineConnector;
