export enum entitySystemBasket {
    /** Проект */
    project = 'project',
    /** Борда */
    board = 'board',
    /** Колонка */
    column = 'column',
    /** Задача */
    task = 'task',
    /** Файл */
    file = 'file'
}