import React, {useContext, useEffect, useState} from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/team.pc.css";
import "../../styles/table.pc.css";
import "./TagSelectionPopup.css"
import Search from "../Search/Search"
import '../../../globalstyles/dataTableStyles.css'
import { ITag } from '../../../models/ITaskFormDisplay';
import ColorPicker from "../ColorPicker";
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import ExitCross from "../../../assets/cancelGreey.svg";
import AddTeg from "../../../assets/addTeg.svg"
import {Context} from "../../../index";
import { AppContext } from '../../../App';

interface ITagSelectorProps {
    tags: ITag[];
    projectId: number;
    taskId: number | undefined;
    addTagsFunc: (tags: ITag[]) => void;
    closeFunc?: () => void;
}

const TAG_COLOR_OPTIONS = [
    "rgba(20,156,206,0.2)",
    "#C7DAF0",
    "#C2CBF8",
    "#E4D4F8",
    "#FACEF3",
    "#FADBE1",
    "#FCF8D6",
    "#D7F6D4",
    "#EAEAEA",
    "#AFAFAF",
]

const TAG_NAME_LIMIT = 15;

const TagSelector: React.FC<ITagSelectorProps> = ({tags, projectId,taskId, addTagsFunc, closeFunc}) => {
    const [allTags, setAllTags] = useState<ITag[]>();
    const [selectedTags, setSelectedTags] = useState<number[]>(tags.map(xx => xx.id));
    const [showTags, setShowTags] = useState<ITag[]>();
    const [showCreatePopup, setShowCreatePopup] = React.useState(false);
    const [searchText, setSearchText] = useState<string>("");
    const [tagName, setTagName] = React.useState<string | null>(null);
    const [color, setColor] = useState<string | null>(null)
    const { store } = useContext(Context);
    const { showToast } = useContext(AppContext);

    async function getAllTags(){
        const tagsRes = await store.getAllTags(1, projectId);
        const genAllTag: ITag[] = tagsRes ? tagsRes.map((xx) => ({
            id: xx.id,
            name: xx.name,
            color: xx.color,
        })) : [];

        setAllTags(genAllTag);
        setShowTags(genAllTag);
    }

    useEffect(() => {
        console.log("---> tags", tags)
    }, [tags]);

    async function addNewTag() {
        try {
            const tagsRes = await store.addTeg(taskId, projectId, tagName!, color ?? undefined);
            if (tagsRes) {
                // setSelectedTags([...selectedTags, tagsRes!]);
                // tags.push({
                //     id: tagsRes,
                //     name: tagName,
                //     color: color
                // } as ITag);
            }
        } catch (err: any) {
            showToast(err?.message);
        }
    }

    //Тут логика верная идем в addTagsFunc
    function selectTag(id: number) {
        console.log("id", id)

        console.log("Все выбранные теги", selectedTags)
        console.log("Все теги", allTags)

        let selected = selectedTags.find(xx=>xx == id);

        console.log("Выбранный тег" ,selected)

        if (!selected) {
            console.log("------- добавляем тег в задачу --------")

            let selectTagsConvert: number[] = JSON.parse(JSON.stringify(selectedTags));
            let newTags: number[] = [...selectTagsConvert, id];
            setSelectedTags(newTags);

            console.log("Все выбранные теги конец", newTags)

            let tagsToAddTask: ITag[] = allTags ? allTags.filter(tag => newTags.includes(tag.id)) : []
            console.log("Отправляем на добавление", tagsToAddTask);

            addTagsFunc(tagsToAddTask);
            // addTagsFunc(allTags ? allTags.filter(tag => newTags.includes(tag.id)) : []);
        } else {
            console.log("------- Удаляем тег из задачи --------")

            let selectTagsConvert: number[] = JSON.parse(JSON.stringify(selectedTags));
            let newTags: number[] = selectTagsConvert.filter(xx => xx != id);
            setSelectedTags(newTags);

            console.log("Все выбранные теги конец", newTags)

            let tagsToAddTask: ITag[] = allTags ? allTags.filter(tag => newTags.includes(tag.id)) : []
            console.log("Отправляем на добавление", tagsToAddTask);

            addTagsFunc(tagsToAddTask);
        }



        // if(!selected) {
        //     // selectedTags = selectedTags.filter(xx => xx );
        //     let newTagTwo = allTags?.find(xx => xx.id === e);
        //     if (newTagTwo) {
        //         tags.push(newTagTwo);
        //     }
        //
        //     console.log("selectedTags", selectedTags);
        //     let newTags:number[] = [...selectedTags, e];
        //     console.log("newTags", newTags);
        //     setSelectedTags(newTags);
        //     addTagsFunc(allTags ? allTags.filter(tag => newTags.includes(tag.id)) : []);
        // } else {
        //     console.log("selectedTags", selectedTags);
        //     let newTags:number[] = [...selectedTags.filter((xx) => xx != e)];
        //     console.log("newTags", newTags);
        //     tags = tags.filter(xx=>xx.id == e);
        //     setSelectedTags(newTags);
        //     addTagsFunc(allTags ? allTags.filter(tag => newTags.includes(tag.id)) : []);
        // }
    }


    function onSelectedChanged(){
        console.log(selectedTags)
    }

    function onAddTags() {
        console.log("selected tags", tags.filter(xx=> selectedTags.some(yy=> yy == xx.id)))
    }

    function handleColorSelection(color: string){
        setColor(color);
    }

    const handleChangeSearchText = (searchText: string) => {
        setSearchText(searchText);
        const searchTextLowercase = searchText.toLowerCase();
        setAllTags(showTags ? showTags.filter(t => t.name.toLowerCase().includes(searchTextLowercase)) : []);
    }

    function onCreateNewTagAccept(){
        (async () => {
            try {
                await addNewTag();
                await getAllTags();
            } catch (error) {
                console.log("Не удалосб получить теги: ", error)
            }
        })();
    }

    function onTagNameChange(e: any){
        setTagName(e.target.value)
    }


    useEffect(() => {
        (async () => {
            try {
                await getAllTags()
            } catch (error) {
                console.log("Не удалосб получить теги: ", error)
            }
        })();
    }, [selectedTags]);

    return (
        <div style={{overflowY: "auto"}}>
            {showCreatePopup ?
                <div className="add__teg--elem__header">
                    <button type="submit" className='add__teg--elem__header--button-base' onClick={() => setShowCreatePopup(false)}>
                        <img src={ExitCross}/>
                    </button>
                    <div className='widgets_header'>
                        <div className='task__teg-popup__add--teg'>
                            <input type="text" id="new-teg-name-input" name="new-teg-name-input" placeholder="Введите название для тега" maxLength={15}
                                   // value={tagName?.length! > TAG_NAME_LIMIT ? tagName?.slice(0, TAG_NAME_LIMIT) : tagName!}
                                   onChange={onTagNameChange}/>
                        </div>
                    </div>
                    {tagName && color ?
                        <div className="add__teg--elem__header--add__teg">
                            <div className='add__teg--elem__header--button__add' onClick={onCreateNewTagAccept}>
                                <img src={AddTeg}/>
                            </div>
                        </div>
                        : <div></div>
                    }
                </div>
                :
                <div className="add__teg--elem__header">
                    <BtnAdd type="add__teg--elem" onClickFunc={() => setShowCreatePopup(true)} permission={true}/>
                    <div className='widgets_header'>
                        <Search onSubmitFunc={() => {
                        }} defaultValue={searchText} changeSearchValue={handleChangeSearchText}/>
                    </div>
                </div>
            }
            {showCreatePopup ?
                <div style={{padding: "5px 10px"}}>
                    <div>
                        Цвет тега
                    </div>
                    <ColorPicker onColorSelected={handleColorSelection} options={TAG_COLOR_OPTIONS}/>
                </div>
            :
                <div>
                </div>
            }
            <div className='all__tegs--block' style={{overflowY: "auto"}}>
                {allTags?.map(item => (
                    <div id={String(item.id)} key={item.id} className='tag-selection-item'>
                        <div className='selected__tags--popup'>

                                <input type="checkbox" checked={tags.find(xx => xx.id == item.id) ? true : false} id={String(item.id)} onClick={() => selectTag(item.id)}/>

                                {/*<input type="checkbox" id={String(item.id)} onClick={() => selectTag(item.id)}/>*/}

                        </div>
                        <div className='selected__tags--popup__name' id={String(item.id)} style={{backgroundColor: item?.color!}}>
                            {item.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default observer(TagSelector);