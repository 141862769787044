import {FC} from "react";
import Arrow from "../../../assets/arrowDown.svg";
import ArrowWhite from "../../../assets/arrowDownWhite.svg";
import "../DefaultButtons.css";
import "./ArrowButton.css";

interface IArrowButtonProps {
    direction: "right" | "left" | "bottom" | "top"
    onClick: (e: any) => void,
    whiteArrow?: boolean
}

const ArrowButton: FC<IArrowButtonProps> = ({direction, onClick, whiteArrow}) => {

    const directionToDeg = new Map([["bottom", 0], ["top", 180], ["left", 90], ["right", 270]])

    return (
        <button
            onClick={(e) => onClick(e)}
            className="button-base arrow-button"
        >
            <img
                src={whiteArrow ? ArrowWhite : Arrow}
                alt="arrow-button"
                style={{transform: `rotate(${directionToDeg.get(direction)}deg)`}}
            />
        </button>
    );
};

export default ArrowButton;