import { FC, useEffect, useState } from "react";
import "./MisceleniousStyles.css";

interface IColorPickerProps {
    onColorSelected: (color: string) => void;
    options: string[];
    defaultColor?: string;
}

const ColorPicker: FC<IColorPickerProps> = ({ options, onColorSelected, defaultColor }) => {
    const [selectedColor, setSelectedColor] = useState<string | undefined>(defaultColor);

    function handleColorPick(color: string) {
        setSelectedColor(color)
        onColorSelected(color);
    }

    if (options.length > 0) {
        return (
            <div className="color-picker-pallet-zone">
                {options?.map((c) => (
                    <div
                        key={c}
                        id={c}
                        style={{ backgroundColor: c }}
                        className={`color-picker-color-variant ${selectedColor === c ? "selected" : ""}`}
                        onClick={() => handleColorPick(c)}
                    ></div>
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default ColorPicker;
