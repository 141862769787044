import {FC, useContext, useEffect, useState} from "react";
import {Context} from "../../index";
import "../../globalstyles/page.pc.css";
import './ProjectsMain.css'
import {observer} from "mobx-react-lite";
import "../../pages/Project/Project.css";
import {ICardProject} from "../../models/IProject";

import ProjectLogo from '../../assets/default__background.jpg';
import ProjectFrameNone from '../../assets/projects_none.svg';
import {useNavigate} from "react-router-dom";

// import { Avatar } from 'primereact/avatar';
import {AppContext} from "../../App";
import {ICheckRole, IHasPermission} from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import ProjectsToolbar from "./ProjectsToolbar";
import ProjectCard from "./ProjectCard";
import { PersonalSettingsOptions } from "../../models/PersonalSettings";

// import { Badge } from 'primereact/badge';

interface ProjectsMainProps {
    loadArchivedProjects?: boolean;
}

const ProjectsMain: FC<ProjectsMainProps> = ({loadArchivedProjects}) => {
    const {store} = useContext(Context);
    const [hrefCoverCompany, setHrefCoverCompany] = useState<string | undefined>("");
    const [projects, setProjects] = useState<ICardProject[]>([]);
    const navigate = useNavigate();

    // Ограничение прав
    const [viewProjects, setViewProjects] = useState(false);
    const [addProject, setAddProject] = useState(false);

    const [userSystemAccess, setUserSystemAccess] = useState<IHasPermission[]>([]);

    const loadCover = async () => {
        try {
            const picture = await store.getSystemCover();
            setHrefCoverCompany(picture?.length > 0 ? picture : ProjectLogo)

            const functionClass = new FunctionSystem()
            const newCheckRole: ICheckRole = {
                projectId: null,
                boardId: null,
                functionCodes: [...functionClass.getSystemFunction, ...functionClass.getProjectFunction]
            };

            const systemAccess = await store.hasPermission(newCheckRole)

            setUserSystemAccess(systemAccess);
            systemAccess.forEach((xx) => {
                if (xx.functionCode == "SystemProjectAction") { //Описание
                    setViewProjects(xx.permissions.some(perm => perm.permissionCode == "view" && perm.isGranted));
                    if (!xx.permissions.some(perm => perm.permissionCode == "view" && perm.isGranted)) {
                        navigate('/noAccessPages')
                    }
                }
                if (xx.functionCode == "ProjectAction") { //Описание
                    setAddProject(xx.permissions.some(perm => perm.permissionCode == "create" && perm.isGranted));
                }

            })
        } catch (err) {
            setHrefCoverCompany(ProjectLogo)
            console.log(err)
        }
    };


    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error)
            }
        })();

    }, [loadArchivedProjects]);

    const loadData = async () => {
        if (loadArchivedProjects){
            await store.getArchiveProjects();
        } else {
            const includeSubprojects = store.personalSettings.find(
                (s) => s.paramName === PersonalSettingsOptions.showSubprojectsProjectPage
            )?.value as boolean ?? true;
            await store.getAllProject(includeSubprojects);
        }
        store.projects && setProjects(store.projects)
        await loadCover();
    }

    return (
        <div>
            <ProjectsToolbar loadData={loadData} addProject={addProject}></ProjectsToolbar>

            <div className="projects_main_container">
                {projects?.length ? projects.map((i: ICardProject) =>
                        (<ProjectCard
                            project={i}
                            hrefCoverCompany={hrefCoverCompany}
                            viewProjects={viewProjects}/>)
                    ) :
                    <div className="projects_none_container">
                        <img src={ProjectFrameNone} alt=""/>
                        <h1>У вас пока нет ни одного проекта</h1>
                    </div>}
            </div>


        </div>
    );
};

export default observer(ProjectsMain);