import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import GrayDownload from "../../../assets/download.svg"
import BlueDownload from "../../../assets/blueDownload.svg"

import {AppContext} from "../../../App";
import { getFileUrlFromId } from '../../../helpers/getFileUrlFromId';
export interface IDownloadButtonProps {
    itemId: number
    onClickFunc: (itemId: number, type: string, name: string) => void;
    url: string,
    type: string,
    name: string,
    permission: boolean
}

const BtnDownload: FC<IDownloadButtonProps> = ({ onClickFunc, itemId, url, type, name, permission}) => {
    const { showToast } = useContext(AppContext);

    return (
        <div className="button-base">
            {permission ?
                <a href={getFileUrlFromId(itemId)} download>
                    <img src={BlueDownload}/>
                </a>
            :
                <a onClick={()=> showToast("У вас нет прав для скачивания файла!")}>
                    <img src={GrayDownload}/>
                </a>
            }
        </div>
    )
}

export default observer(BtnDownload);