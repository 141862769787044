import {FC, useContext, useEffect, useState} from "react";
import PriorityButton from "../../../assets/priorityButton.svg";
import ArrowDown from "../../../assets/arrowDown.svg";
import TypeButton from "../../../assets/typeButton.svg";
import {IFilterData} from "../../../models/TaskModels";
import {UserMonitorContext} from "../../../pages/UserMonitor/UserMonitor";

const MonitorFastFilter: FC = () => {
    const [showPriorities, setShowPriorities] = useState<boolean>(false);
    const [showTypes, setShowTypes] = useState<boolean>(false);

    const { fastFilters, changeFastFilters, selectedFastFilters } = useContext(UserMonitorContext);

    const handlePriorityBtnClick = () => {
        setShowPriorities(!showPriorities);
        setShowTypes(false);
    };

    const handleTypeBtnClick = () => {
        setShowTypes(!showTypes);
        setShowPriorities(false);
    };

    const handleSelectFilter = (filter: IFilterData, filterType: 'priority' | 'type') => {
        if (selectedFastFilters[filterType]?.id === filter.id) {
            changeFastFilters({
                priority: filterType === 'priority' ? undefined : selectedFastFilters.priority,
                type: filterType === 'type' ? undefined : selectedFastFilters.type,
                tag: undefined
           });
        } else if (filter.id === 0) {
            changeFastFilters({
                priority: filterType === 'priority' ? undefined : selectedFastFilters.priority,
                type: filterType === 'type' ? undefined : selectedFastFilters.type,
                tag: undefined
            });
        } else {
            changeFastFilters({
                priority: filterType === 'priority' ? filter : selectedFastFilters.priority,
                type: filterType === 'type' ? filter : selectedFastFilters.type,
                tag: undefined
            });
        }
    };

    const handleSelectType = (type: IFilterData) => handleSelectFilter(type, 'type');
    const handleSelectPriority = (priority: IFilterData) => handleSelectFilter(priority, 'priority');

    return (
        <div className="board-fast-filters-container">
            <div className="board-filter-container">
                <button className="board-filter-container__priority" onClick={handlePriorityBtnClick}>
                    <img src={PriorityButton}/>
                    {selectedFastFilters.priority
                        ? selectedFastFilters.priority.name
                        : "Приоритет"}
                    <img src={ArrowDown}/>
                </button>
                {showPriorities ? (
                    <div>
                        <div
                            className="fast-filters-popup-overlay"
                            onClick={() => setShowPriorities(false)}
                        ></div>
                        <div
                            className="board-filter-dropdown"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {fastFilters.priority.map((priority) => (
                                <div
                                    key={priority.id}
                                    onClick={() =>
                                        handleSelectPriority(priority)
                                    }
                                    className={`${
                                        selectedFastFilters.priority?.id ===
                                        priority.id
                                            ? "selected board-filter-dropdown__item"
                                            : "board-filter-dropdown__item"
                                    }`}
                                >
                                    {priority.name}
                                </div>
                            ))}
                            <div onClick={() =>
                                handleSelectPriority({id: 0, name: "Все", style: ""})
                            }
                                 className={`${
                                     selectedFastFilters.priority?.id === 0
                                         ? "board-filter-dropdown__item--del"
                                         : "board-filter-dropdown__item--del"
                                 }`}
                            >
                                сбросить
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default MonitorFastFilter;
