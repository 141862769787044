import { FC } from 'react';
import { observer } from "mobx-react-lite";
import "../../buttons/DefaultButtons.css"

export interface IButtonBaseProps {
    onClickFunc: () => void;
    textContent: string;
    optionalClasses?: string;
}

const ButtonBase: FC<IButtonBaseProps> = ({ onClickFunc, textContent, optionalClasses}) => {
    ////TODO Сделать норм структуру стилей для всех кнопок.
    ////TODO сделать чтобы сюда можно было передать иконку в кнопку.
    return (
        <button className={"text-button-base " + optionalClasses} onClick={() => onClickFunc()}>
            {textContent}
        </button>
    )
}

export default observer(ButtonBase);