import React, {FC, useContext, useEffect, useState} from "react";
import "./TaskChecklistElements.css";
import DragDropGray from "../../../assets/dragDrop.svg"
import EditColumnGray from "../../../assets/editButtonGray.svg"
import ExitCross from "../../../assets/cancelGreey.svg"
import {AppContext} from "../../../App";
import {Context} from "../../../index";
import {IHasPermission} from "../../../models/IChekRole";
import {ITaskChecklist} from "../../../models/ITaskChecklist";
import Checkbox from '../../Shared/Checkbox/Checkbox';
import DeleteElementGray from "../../../assets/deleteElementGreyTrue.svg";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import InputChat from "../../Shared/InputChat/InputChat";
import InfoPanel from "../../LeftMenu/InfoPanel/InfoPanel";

interface handleEditChecklistElement {
    checklistElemntId: number,
    newText?: string,
    newPosition?: number,
    newChecked?: boolean
}


interface ITaskChecklistElementsProps {
    checklistElement: ITaskChecklist,
    onChecklistElementDragEnd: () => void,
    draggable: boolean,
    deletable: boolean,
    userAccess: IHasPermission[],
    editElement: (handleEditChecklistElement: handleEditChecklistElement) => Promise<void>,
    loadChecklist: () => Promise<void>
}

const TaskChecklistElements: FC<ITaskChecklistElementsProps> = ({
                                                                    checklistElement,
                                                                    onChecklistElementDragEnd,
                                                                    draggable,
                                                                    deletable,
                                                                    userAccess,
                                                                    editElement,
                                                                    loadChecklist
                                                                }) => {
    const [renameMode, setRenameMode] = useState<boolean>(false);
    // const { handleDeleteColumn, handleEditColumn } = useContext(BoardContext);
    const {showToast} = useContext(AppContext);
    const {store} = useContext(Context);
    const [openMessages, setOpenMessages] = useState<boolean>(false);
    const [messagesCount, setMessagesCount] = useState<number>(0);

    //Ограничение прав
    const [editChecklistElement, setEditChecklistElement] = useState(false);
    const [snowViewDeleteEditButtons, setSnowViewDeleteEditButtons] = useState(false);
    const [newText, setNewText] = useState<string>();

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditChecklistElement(true);
                    }
                })
            }
        })
    }, [userAccess]);

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setTimeout(() => {
            const checklistElement = (e.target as HTMLElement).closest('.task_checklist_element');
            if (checklistElement) {
                checklistElement.classList.add('drag-hidden');
            }
        }, 0);
        e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
                checklistGroupId: checklistElement.id,
            })
        );
    };

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            const checklistElement = (e.target as HTMLElement).closest('.task_checklist_element');
            if (checklistElement) {
                checklistElement.classList.remove('drag-hidden');
            }
        }, 100);
        onChecklistElementDragEnd();
    };

    const handleNameChange = (e: any) => {
        if (e.key === "Enter") {
            saveTextChange()
        } else if(e.key === "Escape"){
            e.target.value = checklistElement.text
            setNewText(checklistElement.text)
            setRenameMode(false);
        }
    };

    function saveTextChange(){
        if(newText?.length && newText !==checklistElement.text) {
            const checklistGroupEdit: handleEditChecklistElement = {
                checklistElemntId: checklistElement.id,
                newText: newText

            }
            editElement(checklistGroupEdit);
        }
        setRenameMode(false);
    }

    const inputNewText= (e:any)=>{
        setNewText(e.target.value)
    }

    const handleSelectionChecklistElement = (id: number, e: any) => {
        const checklistGroupEdit: handleEditChecklistElement = {
            checklistElemntId: id,
            newChecked: e.target.checked

        }
        editElement(checklistGroupEdit);
    }

    const handleDeleteChecklistElement = async (elementId: number) => {
        console.log('elementId', elementId)
        await store.deleteTaskChecklistElement(Number(elementId))
        await loadChecklist()
    };

    const viewDeleteEditButtons = () => {
        setSnowViewDeleteEditButtons(!snowViewDeleteEditButtons);
    }

    const [showGroupBtn, setShowGroupBtn] = useState<number | undefined>(undefined);


    function showGroupsList(e: number) {
        setShowGroupBtn(e)

    }

    function hideGroupsList() {
        setShowGroupBtn(undefined)

    }

    return (
        <div className="task_checklist_elem_container">
            <div id={`element-${checklistElement.id}`} className={`task_checklist_elem ${renameMode ? "renameMode" : "" }`}
                onMouseOut={hideGroupsList}
                onMouseOver={()=>{showGroupsList(checklistElement.id)}} >

                <div className="drag-button" draggable={draggable && editChecklistElement} onDragStart={onDragStart}
                    onDragEnd={onDragEnd}>
                    {
                        editChecklistElement ?
                            draggable ?
                                <div className="dragDrop__edit">
                                    <img src={DragDropGray} style={{display: 'none'}}/>
                                </div>
                                :
                                <div className="dragDrop__edit"></div>
                            :
                            draggable ?
                                <div onClick={() => (showToast("У вас нет прав для перемещения колонки!"))}
                                    className="dragDrop__edit">
                                    <img src={DragDropGray} style={{display: 'none'}}/>
                                </div>
                                :
                                <div className="dragDrop__edit"></div>
                    }
                </div>
                <InfoPanel count={messagesCount} idKey={"" + checklistElement.id}/>
                <ArrowButton
                    onClick={(e) => {
                        e.preventDefault();
                        setOpenMessages(!openMessages);
                    }}
                    direction={openMessages ? "bottom" : "right"}
                />
                <Checkbox id={checklistElement.id}
                        handleSelection={handleSelectionChecklistElement}
                        checked={checklistElement.checked}
                        color={'gray'}
                />

                <div className="task_checklist_elem__block">
                    {renameMode ? (
                        <div className="task_checklist_elem__block--name">
                            <input
                                type="text"
                                defaultValue={checklistElement.text ?? ''}
                                onKeyDown={handleNameChange}
                                onInput={inputNewText}
                                onBlur={()=>saveTextChange()}
                                autoFocus
                            ></input>
                        </div>
                    ) : (
                        <div className={`task_checklist_elem__block--name ${checklistElement.checked ? 'checked' : ''}`}
                            onClick={() => (editChecklistElement ? setRenameMode(true) : showToast("У вас нет прав для редактирования колонки!"))}
                        >
                            {checklistElement.text}
                        </div>
                    )}
                    <div className="task_checklist_elem__block__button" style={!showGroupBtn ? {display: "none"} : {}}>
                        <button
                            onClick={() => (editChecklistElement ? setRenameMode(!renameMode) : showToast("У вас нет прав для редактирования колонки!"))}>
                            {renameMode ?
                                <img src={ExitCross}/>
                                :
                                    <img src={EditColumnGray}/>
                            }
                        </button>
                        {deletable ? (
                            <button
                                onClick={() => (editChecklistElement ? handleDeleteChecklistElement(checklistElement.id) : showToast("У вас нет прав для удаления колонки!"))}>
                                <img src={DeleteElementGray}/>
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            <div>
                {/* Скрываем через display, чтобы запросы не слались при каждом раскрытии чата */}
                <div className="checklist-chat" style={!openMessages ? {display: "none"} : {}}> 
                    <InputChat userAccess={userAccess} chatId={checklistElement.chatId} setMessagesCount={setMessagesCount}/>
                </div>
            </div>
        </div>
    );
};

export default TaskChecklistElements;
