import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/team.pc.css";
import "../../styles/table.pc.css";
import "../Team/Team.css"
import Search from "../../Shared/Search/Search"
import BtnAdd from '../../buttons/btnAdd.component/BtnAdd';
import '../../../globalstyles/dataTableStyles.css'
import InputSaveOnEdit from "../InputSaveOnWidjet/InputSaveOnWidjet"
import { Context } from "../../../index";
import { useParams } from "react-router-dom";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import BtnCancel from "../../buttons/CancelButton/CancelButton";
import {
    IAddTeamDisplay,
    IDeleteTeamDisplay,
    IIPersonAddCommand,
    ITeamDisplay,
    ITeamMemberDisplay
} from "../../../models/TeamModels";
import BtnDelete from '../../buttons/btnDelete.component/BtnDelete';
import Checkbox from '../../Shared/Checkbox/Checkbox';
import { ProjectContext } from '../../../pages/Project/Project';
import FilterButton from '../../buttons/btnFilter.component/BtnFilter'
import ConfirmationWindow from '../../Shared/ConfirmationWindow/ConfirmationWindow';
import { initials } from '../../../helpers/Inicials';
import ProjectAddPersonRole from "../ProjectAddPersonRole/ProjectAddPersonRole";
import {IExcludePermissionFunction} from "../../../models/IExcludePermissionFunction";
import BtnEdit from "../../buttons/btnEdit.component/BtnEdit";
import {Button} from "primereact/button";
import EditIconBlue from "../../../assets/edit.svg";
import EditIconGray from "../../../assets/editButtonGray.svg";

import ProjectEditRoleBoard from "../ProjectEditRoleBoard/ProjectEditRoleBoard";
import {AppContext} from "../../../App";
import {IHasPermission} from "../../../models/IChekRole";
import UserPopupWrapper from '../../Shared/UserPopup/UserPopupWrapper';

const PAGE_SIZE = 5

interface Item {
    name: string;
    code: string;
    status?: string;
}

interface ItemPersonRole {
    name: string;
    code: string;
    personRoleId: number;
}

interface ItemTeam {
    name: string;
    code: string;
    status?: string;
}

const fakeFilters = {
    position: [
        {
            id: 1,
            name: "Архитектор"
        },
        {
            id: 2,
            name: "Разработчик"
        },
        {
            id: 3,
            name: "Аналитик"
        },
        {
            id: 4,
            name: "Ведущий аналитик"
        },
        {
            id: 5,
            name: "РП"
        }
    ]
}

export interface ITeamFilter {
    projectId: number,
    role: number[] | undefined,
    name: string | undefined
}

interface ITeamProps {
    members: any[];
    scrollEnded: boolean;
    loadPage: (skip: number, take: number, filters: ITeamFilter) => void;
    updateFilters: (filters: ITeamFilter) => void;
    loadTeamMembers: (skip: number, take: number, filters: ITeamFilter) => void;
    userAccess: IHasPermission[];
}

const Team: React.FC<ITeamProps> = ({ members, scrollEnded, loadPage, updateFilters, loadTeamMembers, userAccess }) => {
    const { persons, roles, filterDataTeam, checkedFiltersTeams,
        setCheckedFiltersTeams, applayFiltersTeams,
        resetFilter, setFilterDataTeam,
        selectedUsersTeams, setSelectedUsersTeams,
        counterTeam, setCounterTeam,
        pageTeam, setPageTeam, setScrollEndedTeam } = useContext(ProjectContext)
    const { store } = useContext(Context);
    const { id } = useParams()
    const { showToast } = useContext(AppContext);
    const [value, setValue] = useState();
    const [openInput, setOpenInput] = useState(false);
    const [selectedUser, setSelectedUser] = useState<ItemPersonRole | null>(null);
    const [selectedRole, setSelectedRole] = useState<Item | null>(null);
    const [deletedMemberIds, setDeletedMemberIds] = useState<{personId: number, roleId: number, personRoleId: number}[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [roleEdit, setRoleEdit] = useState<IExcludePermissionFunction | undefined>();
    const [errMess, setErrMess] = useState('');
    const curRef = useRef(null);
    const [openAddWindow, setOpenAddWindow] = useState(false);
    const [modeCreateP, setModeCreateP] = useState(false);
    const [prevAddPerson, setPrevAddPerson] = useState(false);

    const nullFilter: ITeamFilter = {
        projectId: Number(id),
        role: undefined,
        name: undefined,
    }

    // Ограничение прав
    const [viewTeam, setViewTeam] = useState(false);
    const [editPerson, setEditPerson] = useState(false);
    const [delPerson, setDelPerson] = useState(false);
    const [addPerson, setAddPerson] = useState(false);
    const [newPers, setNewPers] = useState<ItemTeam[]>()

    useEffect(() => {
        let dataPers: ItemTeam[] = persons;
        let dataMembers: ITeamMemberDisplay[] = members;

        const foundMember = dataMembers.find(member => {
            return dataPers.some(team => Number(team.code) === member.id);
        });

        dataPers = dataPers.filter(xx => xx.status == "Активен");

        // if (foundMember) {
        //     let data = dataPers.filter(xx => Number(xx.code) != foundMember.id);
        setNewPers(dataPers)
        // }

    }, [persons, members]);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                setOpenInput(false);
                onCancelClick();
            }
        };

        userAccess.forEach((xx) => {
            if (xx.functionCode == "ProjectTeamAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddPerson(true);
                    }
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelPerson(true);
                    }
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewTeam(true);
                    }
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditPerson(true);
                    }
                })
            }
        })

        // if (store.user.email == "admin@admin.adm") {
        //     setViewTeam(true);
        //     setEditPerson(true);
        //     setDelPerson(true);
        //     setAddPerson(true);
        // }

        document.addEventListener('keydown', keyDownHandler);
        // Clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [userAccess]);

    useEffect(() => {
        store.setDeletePersons(true);
        setDeletedMemberIds([]);
    }, [id]);

    const onScroll = (e: any) => {
        checkIfScrolledToBottom(e)
    };

    useEffect(() => {
        (async () => {
            await loadPage(pageTeam * PAGE_SIZE, PAGE_SIZE, checkedFiltersTeams);
        })();
    }, []);

    function checkIfScrolledToBottom(e: any) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if ((1 + scrollTop + offsetHeight >= scrollHeight - 50) && (!scrollEnded)) {
            setPageTeam((prev: number) => prev + 1);
            (async () => {
                await loadPage(pageTeam * PAGE_SIZE, PAGE_SIZE, checkedFiltersTeams);
            })();
        }
    }

    const personRowDisplay = (rowData: ITeamMemberDisplay) => {
        return (
            <UserPopupWrapper userId={rowData.id}>
                <PersonLineDisplay name={initials(rowData.responsibleName)} photoId={rowData.responsiblePhotoId} />
            </UserPopupWrapper>
        )
    };

    function onSearchSubmit(value: string) {
        (curRef.current as any).scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        let newFilters = { ...checkedFiltersTeams };
        newFilters.name = value;
        setCheckedFiltersTeams(newFilters);
        (async () => {
            await updateFilters(newFilters);
        })();

    }

    function onAddClick() {
        // setOpenInput(!openInput);
        if (addPerson) {
            setOpenAddWindow(true);
        } else {
            showToast("У вас нет прав для добавления пользователя в проект!")
        }
    }

    function onCloseModal() {
        // setOpenInput(!openInput);
        setRoleEdit(undefined);
        setOpenAddWindow(false);
        setModeCreateP(false);
        clearChecks();
    }

    function onCancelClick() {
        (curRef.current as any).scrollTo({
            top: 0,
            left: 0,
        });
        resetFilter('team');
        setSearchValue('');
        setPageTeam(1);
    }

    //https://ph.bpmlab.ru/T6667 для этого
    function clearChecks(){
        const checkboxes = document.querySelectorAll('.team_checkbox');
        checkboxes.forEach(checkbox => {
            let chb = (checkbox as any).querySelector('input[type="checkbox"]')
            if(chb){
                chb.checked = false;
            }
        });
    }

    const saveUserOnTeam = (e: any) => {
        e.preventDefault();
        if (selectedUser && selectedRole) {
            let team: IIPersonAddCommand = {
                personId: Number(selectedUser.code),
                roleId: Number(selectedRole.code)
            };
            let body: IAddTeamDisplay = {
                projectId: Number(id),
                team: [team]
            };

            (async () => {
                const users = await store.addTeamMembers(body);
                const data = await store.getDataToExcludePermissions(Number(selectedUser.code))
                if (data) {
                    setRoleEdit(data);
                    console.log("DATA", data)
                    setOpenInput(false);
                    loadTeamMembers(0, PAGE_SIZE, {
                        projectId: Number(id),
                        name: undefined,
                        role: undefined
                    })

                    setOpenAddWindow(false);
                    openEditWindow();
                }
            })();
            setScrollEndedTeam(false);
            (curRef.current as any).scrollTo({
                top: 0,
                left: 0,
            });
            setPageTeam(1);
            setPrevAddPerson(true);
        }
        clearChecks()
    }

    useEffect(() => {
        if (store.deletePersons == true) {
            store.setDeletePersons(false);
            return;
        } else {
            let check = members?.find(xx => xx.roleId === Number(selectedRole?.code));
            let personId = members.find((member) => member.id == Number(selectedUser?.code));
            if (personId) {
                let user: ItemPersonRole = {
                    name: personId.responsibleName,
                    code: String(personId.personId),
                    personRoleId: personId.personRoleId
                }
                setSelectedUser(user);
            }
            if (check && prevAddPerson) {
                let ids: { personId: number, roleId: number, personRoleId: number } = {
                    personId: check.id,
                    roleId: check.roleId,
                    personRoleId: check.personRoleId
                }
                setDeletedMemberIds([ids]);
                openEditWindow();
                setOpenEditModal(true);
                setDeletedMemberIds([]);
                console.log("addd был")
                setPrevAddPerson(false);
            }
        }
    }, [members]);

    const handleTeamSelection = (memberId: number, event: any, nameForFilter?: string , roleId?: number) => {
        let ids = deletedMemberIds;

        if (event.target.checked && roleId) {
            let member = members.find((member) => member.roleId === roleId && member.id === memberId);
            if (member) {
                ids.push({personId: member.id , roleId: member.roleId, personRoleId: member.personRoleId});
            }
        } else {
            ids = ids.filter(xx=>xx.personId != memberId)
        }
        setDeletedMemberIds([...ids]);
    };

    function openEditWindow() {
        if (editPerson) {
            (async () => {
                let personId = deletedMemberIds.find(xx => xx.personId);
                if (personId) {
                    const data = await store.getDataToExcludePermissions(personId.personId)
                    let name = members.find((member) => member.id == Number(personId?.personId)).responsibleName;


                    if (data) {
                        setRoleEdit(data);
                        setOpenEditModal(true);
                        let user: ItemPersonRole = {name: name, code: String(personId.personId), personRoleId: personId.personRoleId}
                        setSelectedUser(user);
                    }
                }
            })();
        } else {
            showToast("У вас нет прав для редактирования пользователя!")
        }
    }

    function closeEditWindow() {
        setOpenEditModal(false);
    }

    function onDelete() {
        //Тут нужен маппинг с ролью для удаления
        let body: IDeleteTeamDisplay = {
            projectId: Number(id),
            team: deletedMemberIds.map(item=> ({
                personId: item.personId,
                roleId: item.roleId
            }))
        }

        deletedMemberIds?.length && (async () => {
            let res = await store.deleteTeamMembers(body);
            if (res !== null) {

                setErrMess(res);
                alert(res);

            } else {
                loadTeamMembers(0, PAGE_SIZE, {
                    projectId: Number(id),
                    name: undefined,
                    role: undefined
                })

                setDeletedMemberIds([]);
                setScrollEndedTeam(false);
                (curRef.current as any).scrollTo({
                    top: 0,
                    left: 0,
                });
                setPageTeam(1);
            }

        })();
        setDeletedMemberIds([])
        clearChecks()
    }

    const onConfirm = () => {
        store.setDeletePersons(true);
        onDelete();
        setOpenConfirmWindow(false);
    }
    const onCancel = () => {
        setOpenConfirmWindow(false);
    }

    const openConfWindow = () => {
        if (delPerson) {
            setOpenConfirmWindow(true);
        } else {
            showToast("У вас нет прав для удаления пользователя(ей)!")
        }
    }

    return (
        <div id='main-team-widget' className='widget_wrapper'>
            {openEditModal ?
                <div className="widget_wrapper--modal__edit">
                    <div className="widget_wrapper--modal__edit--size">
                        <ProjectEditRoleBoard personId={selectedUser} closeFunc={closeEditWindow} roleEdit={roleEdit} projectId={Number(id)}/>
                    </div>
                </div>
            : null
            }
            {openAddWindow ?
                <div>
                    <ProjectAddPersonRole
                        value={value} setValue={setValue}
                        open={openInput} personId={selectedUser}
                        save={saveUserOnTeam} typeInput='list'
                        selectedUser={selectedUser} setSelectedUser={setSelectedUser}
                        selectedRole={selectedRole} setSelectedRole={setSelectedRole}
                        persons={newPers} roles={roles}
                        closeFunc={onCloseModal} roleEdit={roleEdit}
                        projectId={Number(id)} modeCreateP={modeCreateP}
                        members={members}
                    />
                </div>
            : null}
            {openConfirmWindow && (deletedMemberIds?.length === 1 ? <ConfirmationWindow headerText='Удалить сотрудника из команды?'
                bodyText='Вы уверены, что хотите удалить сотрудника?'
                confirmText='Да' cancelText='Нет' onConfirm={onConfirm} onCancel={onCancel} /> : <ConfirmationWindow headerText='Удалить сотрудников из команды?'
                    bodyText='Вы уверены, что хотите удалить сотрудников?'
                    confirmText='Да' cancelText='Нет' onConfirm={onConfirm} onCancel={onCancel} />)}
            <div className='widgets_header'>
                <div>
                    <h2 className='h2_20-black'>Команда</h2>
                </div>
                <div className='widjets_header_rigth'>
                    <Search onSubmitFunc={onSearchSubmit} defaultValue={searchValue}
                            changeSearchValue={setSearchValue}/>
                    <FilterButton filterData={filterDataTeam}
                                  setFilterData={setFilterDataTeam}
                                  applayFilters={applayFiltersTeams}
                                  checkedFilters={checkedFiltersTeams}
                                  setCheckedFilters={setCheckedFiltersTeams}
                                  selectedUsers={selectedUsersTeams} setSelectedUsers={setSelectedUsersTeams}
                                  counter={counterTeam} setCounter={setCounterTeam}
                                  showApplyButton={true}
                    />
                    {(counterTeam !== 0 || searchValue) && <BtnCancel onClickFunc={onCancelClick}/>}
                    {deletedMemberIds?.length !== 0 && <BtnDelete onClickFunc={openConfWindow} permission={delPerson}/>}
                    {deletedMemberIds?.length == 1 &&
                        <button className='widjets_header_rigth--edit' onClick={openEditWindow}>
                            <img src={editPerson ? EditIconBlue : EditIconGray}/>
                        </button>
                    }
                    <BtnAdd type={''} onClickFunc={onAddClick} permission={addPerson}/>
                </div>
            </div>
            <div ref={curRef} className="custom_table">
                <table>
                    <tbody>
                        {viewTeam && members && members.length ? members.map(member => (
                            <tr>
                                <td className='team_checkbox'>
                                    <Checkbox id={member.id} roleId={member.roleId} handleSelection={handleTeamSelection} />
                                    {personRowDisplay(member)}
                                </td>
                                <td>{member.position}</td>
                            </tr>
                        )) : ''}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default observer(Team);