import React, {FC, useContext, useEffect, useState} from "react";
import "./TaskChecklistGroup.css";
import DragDropGray from "../../../assets/dragDrop.svg"
import EditColumnGray from "../../../assets/editButtonGray.svg"
import ExitCross from "../../../assets/cancelGreey.svg"
import DeleteElementGray from "../../../assets/deleteElementGreyTrue.svg"
import arrowDown from "../../../assets/arrowDown.svg"
import AddGray from "../../../assets/addGray.svg"


import {AppContext} from "../../../App";
import {Context} from "../../../index";
import {IHasPermission} from "../../../models/IChekRole";
import {ITaskChecklistGroup} from "../../../models/ITaskChecklistGroup";
import TaskChecklistElements from "../TaskChecklistElements/TaskChecklistElements";
import {ITaskChecklistEdit} from "../../../models/ITaskChecklistEdit";
import {ITaskChecklist} from "../../../models/ITaskChecklist";

interface handleEditChecklistGroup {
    checklistGroupId: number,
    newName?: string,
    newPosition?: number
}

interface handleEditChecklistElement {
    checklistElemntId: number,
    newText?: string,
    newPosition?: number,
    newChecked?: boolean
}

interface ITaskChecklistGroupProps {
    checklistGroup: ITaskChecklistGroup,
    onChecklistGroupDragEnd: () => void,
    draggable: boolean,
    deletable: boolean,
    userAccess: IHasPermission[],
    editGroup: (handleEditChecklistGroup: handleEditChecklistGroup) => Promise<void>,
    loadChecklist: () => Promise<void>,
    createElement?: (groupId?: number, nameElement?: string) => Promise<void>
}

const TaskChecklistGroup: FC<ITaskChecklistGroupProps> = ({
                                                              checklistGroup,
                                                              onChecklistGroupDragEnd,
                                                              draggable,
                                                              deletable,
                                                              userAccess,
                                                              editGroup,
                                                              loadChecklist,
                                                              createElement
                                                          }) => {
    const [renameMode, setRenameMode] = useState<boolean>(false);
    // const { handleDeleteColumn, handleEditColumn } = useContext(BoardContext);
    const {showToast} = useContext(AppContext);
    const {store} = useContext(Context);

    //Ограничение прав
    const [editChecklistGroup, setEditChecklistGroup] = useState(false);
    const [showElementsArr, setShowElementsArr] = useState<number[]>([]);
    const [showGroupBtn, setShowGroupBtn] = useState<number>();
    const [showAddElementInput, setShowElementInput] = useState<boolean>(false);

    const [nameElement, setNameElement] = useState<string>();


    const [previewChecklistGroupIndex, setPreviewChecklistGroupIndex] = useState<
        number | undefined
    >();

    const [dragEventTarget, setDragEventTarget] = useState<any>();
    const createPreviewElement = (content: string) => {
        let newElement = document.createElement("div");
        newElement.className = "elements-preview";
        newElement.innerHTML = content;
        newElement.style.backgroundColor = "#149cce6b";
        newElement.style.padding = "25px";
        newElement.style.borderRadius = "6px";
        newElement.style.pointerEvents = "none";
        return newElement;
    };


    useEffect(() => {
        userAccess.forEach((xx) => {
        //     if (xx.functionCode == "BoardAction") {
        //         xx.permissions.forEach((yy) => {
        //             if (yy.permissionCode == "edit" && yy.isGranted == true) {
        //                 setEditColumns(true);
        //             }
        //         })
        //     }
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
        //
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditChecklistGroup(true);
                    }
                })
            }
        })

        // if (store.user.email == "admin@admin.adm") {
        //     setEditChecklistGroup(true);
        // }
    }, [userAccess]);

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            const columnElement = (e.target as HTMLElement).closest('.task_checklist_group');
            if (columnElement) {
                columnElement.classList.add('drag-hidden');
            }
        }, 0);
        e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
                checklistGroupId: checklistGroup.id,
            })
        );
    };

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            const columnElement = (e.target as HTMLElement).closest('.task_checklist_group');
            if (columnElement) {
                columnElement.classList.remove('drag-hidden');
            }
        }, 100);
        onChecklistGroupDragEnd();
    };

    const handleNameChange = (e: any) => {
        if (e.key === "Enter") {
            const checklistGroupEdit: handleEditChecklistGroup = {
                checklistGroupId: checklistGroup.id,
                newName: e.target.value

            }
            editGroup(checklistGroupEdit);
            setRenameMode(false);
        }
    };

    async function createElementOnGroup(e: any) {
        if (e.key === "Enter") {
            if (createElement && nameElement?.length) {
                await createElement(checklistGroup.id, nameElement)

                e.target.value = ''
                setNameElement('')
                setShowElementInput(!showAddElementInput)

            }
        }
    }

    const handleDragEnd = () => {
        removePreview();
    };

    const removePreview = () => {
        const elements = document.querySelectorAll(".column-preview");
        elements.forEach((element) => element.remove());
        setPreviewChecklistGroupIndex(undefined);
    };

    const handleDeleteChecklistGroup = async (groupId: number) => {
        await store.deleteTaskChecklistGroup(Number(groupId))
        await loadChecklist()
    };

    const editElement = (async (handleEditChecklistElement: handleEditChecklistElement) => {
        let taskChecklistElementsForEdit: ITaskChecklist[] = checklistGroup.checklist ?? []
        if (taskChecklistElementsForEdit?.length) {
            let checklistElement = taskChecklistElementsForEdit.find(xx => xx.id === handleEditChecklistElement.checklistElemntId);
            if (checklistElement) {
                const Element: ITaskChecklistEdit = {
                    id: handleEditChecklistElement.checklistElemntId,
                    text: handleEditChecklistElement.newText,
                    checked: handleEditChecklistElement.newChecked,
                    taskId: checklistGroup.taskId

                }
                await store.editTaskChecklistElement(Element);
                await loadChecklist();
            }
        }
    })

    function showElementsList(e: number) {
        let elemsArr = JSON.parse(JSON.stringify(showElementsArr)) as number[];
        if (elemsArr.indexOf(e) === -1) {
            elemsArr.push(e)
        } else if (elemsArr.indexOf(e) !== -1) {
            elemsArr = elemsArr.filter(xx => xx != e)
        }
        setShowElementsArr(elemsArr)

    }

    function showGroupsList(e: number) {
        setShowGroupBtn(e)

    }

    function hideGroupsList() {
        setShowGroupBtn(undefined)

    }

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragEventTarget(e.target);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const droppedPosition = e.clientY;
        if (!checklistGroup.checklist?.length) {
            return
        }
        let insertIndex = checklistGroup.checklist?.length;

        if (insertIndex) {
            return
        }
        for (let checklistElement of checklistGroup.checklist) {
            const checklistElementElement = document.getElementById(
                `element-${checklistElement.id}`
            );
            if (checklistElementElement) {
                const rect = checklistElementElement.getBoundingClientRect();
                const midY = rect.top + rect.height / 2;
                if (droppedPosition < midY) {
                    insertIndex = checklistGroup.checklist.indexOf(checklistElement);
                    break;
                }
            }
        }

        if (previewChecklistGroupIndex === insertIndex) return;

        removePreview();

        if (insertIndex < checklistGroup.checklist.length) {
            const groupElement = document.getElementById(
                `element-${checklistGroup.checklist[insertIndex].id}`
            );
            if (groupElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                groupElement.insertAdjacentElement("beforebegin", newElement);
                setPreviewChecklistGroupIndex(insertIndex);
            }
        } else {
            const groupElement = document.getElementById(
                `element-${checklistGroup.checklist[checklistGroup.checklist.length - 1].id}`
            );
            if (groupElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                groupElement.insertAdjacentElement("afterend", newElement);
                setPreviewChecklistGroupIndex(insertIndex);
            }
        }
    };

    const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {

        e.preventDefault();
        try {
            console.log('e.dataTransfer', e.dataTransfer.getData("application/json"))
            const {checklistElementId} = JSON.parse(
                e.dataTransfer.getData("application/json")
            );

            const droppedPosition = e.clientY;

            if (checklistGroup.checklist) {
                return;
            }
            const movedChecklistGroup = checklistGroup.checklist!.find((c) => c.id === checklistElementId);
            if (!movedChecklistGroup) return;

            let insertIndex = checklistGroup.checklist!.length;

            for (let checklistElement of checklistGroup.checklist!) {
                const groupElement = document.getElementById(
                    `group-${checklistElement.id}`
                );
                if (groupElement) {
                    const rect = groupElement.getBoundingClientRect();
                    const midY = rect.top + rect.height / 2;
                    if (droppedPosition < midY) {
                        insertIndex = checklistGroup.checklist!.indexOf(checklistElement);
                        break;
                    }
                }
            }

            if (insertIndex !== checklistGroup.checklist!.indexOf(movedChecklistGroup)) insertIndex--;
            if (insertIndex < checklistGroup.checklist!.indexOf(movedChecklistGroup)) insertIndex++;

            if (insertIndex === checklistGroup.checklist!.indexOf(movedChecklistGroup)) {
                return;
            }
            const checklistElementEdit: handleEditChecklistElement = {
                checklistElemntId: checklistElementId,
                newPosition: insertIndex

            }


            editElement(checklistElementEdit)
            // handleMoveColumn(checklistGroupId, insertIndex);
            removePreview();
        } catch (err) {
            console.log(err)
            removePreview();
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (dragEventTarget !== e.target) {
            removePreview();
        }
    };

    const addName = (e: any) => {
        if (e.target.value.length > 0) {
            setNameElement(e.target.value);
        }
    };

    function addElementInputShow() {
        setShowElementInput(!showAddElementInput)
    }

    return (
        <div id={`group-${draggable ? checklistGroup.id : ""}`}>
            <div style={!draggable ? {display: "none"} : {}} className='task_checklist_group'>
                <div className="drag-button" draggable={draggable && editChecklistGroup} onDragStart={onDragStart}
                     onDragEnd={onDragEnd}>
                    {
                        editChecklistGroup ?
                            draggable ?
                                <div className="dragDrop__edit">
                                    <img src={DragDropGray}/>
                                </div>
                                :
                                <div className="dragDrop__edit"></div>
                            :
                            draggable ?
                                <div onClick={() => (showToast("У вас нет прав для перемещения группы!"))}
                                     className="dragDrop__edit">
                                    <img src={DragDropGray}/>
                                </div>
                                :
                                <div className="dragDrop__edit"></div>
                    }
                </div>
                <div className="task_checklist_group__block" onMouseOver={() => showGroupsList(checklistGroup.id)}
                     onMouseOut={hideGroupsList}>

                    <div>
                        <img src={AddGray} onClick={editChecklistGroup ? addElementInputShow :
                            ()=>showToast("Вы не можете добавлять элементы чек листа пока у вас нет прав на редактирование задачи")}/>

                    </div>
                    <div onClick={() => showElementsList(checklistGroup.id)}>

                        <img src={arrowDown} style={showElementsArr.indexOf(checklistGroup.id) !== -1 ? {
                            transform: "rotate(180deg)",
                            cursor: "pointer"
                        } : {cursor: "pointer"}}/>
                    </div>
                    {renameMode ? (
                        <div className="task_checklist_group__block--name">
                            <input
                                type="text"
                                defaultValue={checklistGroup.name ?? ''}
                                onKeyDown={handleNameChange}
                            ></input>
                        </div>
                    ) : (
                        <div className="task_checklist_group__block--name">
                            {checklistGroup.name}
                        </div>
                    )}
                    {}
                    <div className="task_checklist_group__block__button" style={!showGroupBtn ? {display: "none"} : {}}>
                        <button
                            onClick={() => (editChecklistGroup ? setRenameMode(!renameMode) : showToast("У вас нет прав для редактирования группы!"))}>
                            {renameMode ?
                                <img src={ExitCross}/>
                                :
                                <img src={EditColumnGray}/>
                            }
                        </button>
                        {deletable ? (
                            <button
                                onClick={() => (editChecklistGroup ? handleDeleteChecklistGroup(checklistGroup.id) : showToast("У вас нет прав для удаления группы!"))}>
                                <img src={DeleteElementGray}/>
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            {showAddElementInput ?
                <div className={'task_checklist_elem--input'}>
                    <div
                        className={"task_checklist_elem__block--name" }>
                        <input type={"text"} onInput={addName} onKeyDown={createElementOnGroup}
                               placeholder={"Введите название пункта"}/>
                    </div>

                </div> : null
            }
            <div className="task_checklist_element" onDragEnter={handleDragEnter}
                 onDragOver={(e) => handleDragOver(e)}
                 onDrop={(e) => handleOnDrop(e)}
                 onDragLeave={handleDragLeave}
                 id={`groupElement-${draggable ? checklistGroup.id : ""}`}
                 style={showElementsArr.indexOf(checklistGroup.id) !== -1 || checklistGroup.position == 0 ? {} : {display: "none"}}>
                {checklistGroup.checklist?.sort((a, b) => a.position - b.position).map((c, index) => (
                    <TaskChecklistElements key={c.id}
                                           checklistElement={c}
                                           onChecklistElementDragEnd={handleDragEnd}
                                           draggable={false}
                                           deletable={true}
                                           userAccess={userAccess}
                                           editElement={editElement}
                                           loadChecklist={loadChecklist}
                    />
                ))}
            </div>
        </div>
    );
};

export default TaskChecklistGroup;
