import {FC, useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import {AppContext} from "../../App";
import BoardFrame from "../../assets/board_frame.svg";
import ProjectFrame from "../../assets/project_frame.svg";
import {getFileUrlFromId} from "../../helpers/getFileUrlFromId";
import SubprojectIcon from "../../assets/subproject.svg";
import Avatar from "../Shared/Avatar/Avatar";
import ProjecFrameGray from "../../assets/project_frame_gray.svg";
import {ProgressBar} from "primereact/progressbar";
import {ICardProject} from "../../models/IProject";
import {useNavigate} from "react-router-dom";
import {IUserAvatar} from "../../models/IUser";


interface ProjectCardProps {
    project: ICardProject,
    hrefCoverCompany: string | undefined,
    viewProjects?: boolean
}

const ProjectCard: FC<ProjectCardProps> = ({project, hrefCoverCompany, viewProjects}: ProjectCardProps) => {
    const {showToast, archivedPage} = useContext(AppContext);

    const navigate = useNavigate();


    const handlClick = (projectId: number) => {
        if (archivedPage)
            navigate(`/archive/project/${projectId}`)
        else 
            navigate(`/project/${projectId}`)
    }

    function formatDate(dateStr: any): string {
        try {
            let date = new Date(dateStr);
            const padWithZero = (value: number) => value.toString().padStart(2, '0');
            const day = padWithZero(date.getDate());
            const month = padWithZero(date.getMonth() + 1); // Months are zero-indexed
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        } catch (err: any) {
            return "-"
        }
    }

    const getUniqueUsers = (arr: IUserAvatar[]) => {
        return arr.filter((el, ind) => ind === arr.indexOf(el));
    };

    return (
        <div
            onClick={() => (project.isUserInProject || viewProjects ? handlClick(project.id) : showToast("У вас нет прав на переход в проект!"))}
            key={project.id} className="card_projects_container">

            <div className="card_projects_img_wrap">
                <div className="card_projects_boards_info">
                    <div className="flex ">
                        <div className="p_blue p_14 text_margin">{project.boardsNum}</div>
                        <img src={BoardFrame} alt=""/>
                    </div>
                    <div className="flex">
                        <div className="p_14 text_margin">{project.projectsNum}</div>
                        <img src={ProjectFrame} alt=""/>
                    </div>
                </div>
                <div className="card_projects_dates_period">
                    {project.dateTo ? formatDate(project.dateFrom) + "-" + formatDate(project.dateTo) : formatDate(project.dateFrom)}
                </div>
                <div className="card_projects_img">
                    <img src={project.img ? getFileUrlFromId(project.img) : hrefCoverCompany} alt=""/>
                </div>
            </div>

            <div className="card_projects_info">
                <div className="text_Card_name_container">
                    {project.isSubProject ?
                        <img
                            className="nav-panel_suproject-image"
                            src={SubprojectIcon}
                            alt="subproject-icon"
                        /> : null}
                    <h1 className="text_Card_name">{project.shortDescription}</h1>
                </div>

                <div className="card_projects_code">
                    <div className="text_Card_code">{project.code}</div>
                </div>
                <div className="text_Card_description_container">
                    <div className="text_Card_description">{project.description}</div>
                </div>
                <div className="card_projects_footer">
                    <div className="card_projects_users">
                        {project.users?.length !== 0 && getUniqueUsers(project.users).map((user, index) => (
                            index < 4 &&
                            (<div className="avatar_group_item">
                                <Avatar url={getFileUrlFromId(user.photoId)} size="s"
                                        userName={user.name}/>
                            </div>)
                        ))}
                        {project.users?.length > 4 &&
                            (
                                <div className="count_users">
                                    <div className="p_gray p_19 ">
                                        +{project.users?.length - 4}
                                    </div>
                                </div>
                            )}


                    </div>
                    <div className="card_projects_boards_info_footer">
                        <div className="flex ">
                            <div className="p_blue p_14 text_margin">{project.boardsNum}</div>
                            <img src={BoardFrame} alt=""/>
                        </div>
                        <div className="flex">
                            <div className="p_14 text_margin p_gray">{project.projectsNum}</div>
                            <img src={ProjecFrameGray} alt=""/>
                        </div>
                    </div>
                    <div className="card_projects_progress">
                        <div className='card_projects_progress_item'>
                            <p className='genInfo_body_item_p11 p_gray'>ПРОГРЕСС</p>
                            <p className='genInfo_body_item_p11 p_blue'>{project.percentageCompletion}%</p>
                        </div>

                        <ProgressBar value={project.percentageCompletion ?? 0}></ProgressBar>

                    </div>
                </div>
            </div>

        </div>

    )
}

export default observer(ProjectCard);
