import React, {FC, useContext, useEffect} from 'react';
import LoginForm from "../../component/Authorization/Login/LoginForm";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {Route, useLocation, useNavigate} from "react-router-dom";
import RegistrationForm from "../../component/Authorization/Registration/RegistrationForm";
import Confirmation from "../../component/Authorization/Confirmation/Confirmation";

const Authorization: FC = (index) => {
    const {store} = useContext(Context);
    const navigate = useNavigate();


    
    if (!store.isAuth) {
        return (
            <div>
                <RegistrationForm/>
            </div>
        )
    } else if (!store.isActivate) {
        return (
            <div>
                <Confirmation/>
            </div>
        );
    } else {
        navigate("/projects");
        return null;
    }
}

export default observer(Authorization);