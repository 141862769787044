import CustomLink from "./CustomLink/CustomLink";

function PrivacyPolicyLink() {
    return (
        <div className="form_flex_row p_gray">
            <p className="link_login">
                <CustomLink to="/">Соглашение </CustomLink>
            </p>
            <p className="p_12_login">и </p>
            <p className="link_login">
                <CustomLink to="/">Политика конфиденциальности</CustomLink>
            </p>
        </div>
    );
}

export default PrivacyPolicyLink;
