import { FC, ReactNode, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { IProjectHistoryDisplay } from "../../../models/ProjectModels";
import { HistoryEntities } from "../../../models/History/HistoryEntities";
import { HistoryActions } from "../../../models/History/HistoryActions";
import PersonLineDisplay from "../PersonLineDisplay";
import { fullNameFormat } from "../../../helpers/Inicials";
import RightArrow from "../../../assets/rightArrow.svg";
import { RenderValue } from "./ProjectHistoryMapper";
import UserPopupWrapper from "../UserPopup/UserPopupWrapper";
import { AppContext } from "../../../App";

const toEntityLink = (
    archivedPage: boolean,
    type?: string,
    id?: number,
    name?: string
): ReactNode => {
    if (name && id && type) {
        let url = "";
        if (type === "projectTeam")
            return (
                <UserPopupWrapper userId={id}>
                    <span style={{ cursor: "pointer" }}>{name}</span>
                </UserPopupWrapper>
            );
        if (type == "task")
            url = `${archivedPage ? "/archive" : ""}/task/${id}`;
        else if (type == "project")
            url = `${archivedPage ? "/archive" : ""}/project/${id}`;
        else if (type == "board")
            url = `${archivedPage ? "/archive" : ""}/board/${id}`;
        return <Link to={url}>{name}</Link>;
    } else if (name) {
        return <span>{name}</span>;
    }
    return null;
};

function toDisplayDate(dateStr: any): string {
    try {
        let date = new Date(dateStr);
        const padWithZero = (value: number) =>
            value.toString().padStart(2, "0");
        const day = padWithZero(date.getDate());
        const month = padWithZero(date.getMonth() + 1);
        const year = date.getFullYear();
        const hours = padWithZero(date.getHours());
        const minutes = padWithZero(date.getMinutes());

        //Перестанет работать в 2100 году :)
        return `${day}.${month}.${year - 2000} ${hours}:${minutes}`;
    } catch (err: any) {
        return "-";
    }
}

interface HistoryTableProps {
    showFullChanges?: boolean;
    showHyperlinkColumn?: boolean;
    records: IProjectHistoryDisplay[];
    onScrolledToBottom: (e: any) => void;
}

const HistoryTable: FC<HistoryTableProps> = ({
    showFullChanges = true,
    showHyperlinkColumn = true,
    records,
    onScrolledToBottom,
}) => {
    const [closedRows, setClosedRows] = useState<string[]>([]);
    const { archivedPage } = useContext(AppContext);

    const onScroll = (e: any) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;

        if (1 + scrollTop + offsetHeight >= scrollHeight) {
            onScrolledToBottom(e);
        }
    };

    const onMinimizeButtonClick = (elem: string) => {
        if (closedRows.some((r) => r === elem))
            setClosedRows([...closedRows.filter((r) => r !== elem)]);
        else setClosedRows([...closedRows, elem]);
    };

    return (
        <div onScroll={onScroll} className="custom_table history_project_table">
            <table>
                <thead>
                    <tr>
                        <th>ДАТА</th>
                        {showHyperlinkColumn ? <th>ОБЪЕКТ</th> : null}
                        <th>ТИП ОБЪЕКТА</th>
                        <th>ТИП ИЗМЕНЕНИЯ</th>
                        <th>ПОЛЬЗОВАТЕЛЬ</th>
                        <th>ИЗМЕНЕНИЕ</th>
                    </tr>
                </thead>
                <tbody>
                    {records.map((record, index) => (
                        <tr key={index}>
                            <td style={{ width: "fit-content" }}>
                                {toDisplayDate(record.createdAt)}
                            </td>
                            {showHyperlinkColumn ? (
                                <td
                                    style={{
                                        width: "fit-content",
                                        maxWidth: "15%",
                                    }}
                                >
                                    {toEntityLink(
                                        archivedPage === true,
                                        record.hyperlinkEntityType,
                                        record.hyperlinkId,
                                        record.hyperlinkName
                                    )}
                                </td>
                            ) : null}
                            <td style={{ width: "fit-content" }}>
                                {HistoryEntities.get(record.entityType)}
                            </td>
                            <td style={{ width: "fit-content" }}>
                                {HistoryActions.get(record.typeOfChange)}
                            </td>
                            <td style={{ width: "fit-content" }}>
                                <UserPopupWrapper userId={record.createdBy.id}>
                                    <PersonLineDisplay
                                        name={fullNameFormat(
                                            {
                                                surname:
                                                    record.createdBy.surname,
                                                name: record.createdBy.name,
                                                middlename:
                                                    record.createdBy.middlename,
                                            },
                                            "s N M"
                                        )}
                                        photoId={record.createdBy.photoId}
                                    />
                                </UserPopupWrapper>
                            </td>
                            <td style={{ width: "auto" }}>
                                <div>
                                    {record.changeDataDifference.map((diff) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {diff.caption ? (
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    {diff.caption + ":"}
                                                </span>
                                            ) : null}
                                            <RenderValue
                                                type={diff.type}
                                                value={diff.oldValue}
                                                showFullChanges={
                                                    showFullChanges ?? false
                                                }
                                                minimized={
                                                    !closedRows.some(
                                                        (r) =>
                                                            r ===
                                                            index +
                                                                diff.caption +
                                                                "old"
                                                    )
                                                }
                                                onMinimizeButtonClick={() => {
                                                    onMinimizeButtonClick(
                                                        index +
                                                            diff.caption +
                                                            "old"
                                                    );
                                                }}
                                            />
                                            {diff.oldValue || diff.newValue ? (
                                                <img
                                                    style={{
                                                        alignSelf: "center",
                                                    }}
                                                    src={RightArrow}
                                                    alt=""
                                                    height={10}
                                                />
                                            ) : null}
                                            <RenderValue
                                                type={diff.type}
                                                value={diff.newValue}
                                                showFullChanges={
                                                    showFullChanges ?? false
                                                }
                                                minimized={
                                                    !closedRows.some(
                                                        (r) =>
                                                            r ===
                                                            index +
                                                                diff.caption +
                                                                "new"
                                                    )
                                                }
                                                onMinimizeButtonClick={() => {
                                                    onMinimizeButtonClick(
                                                        index +
                                                            diff.caption +
                                                            "new"
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HistoryTable;
