import $api from "..";
import {AxiosResponse} from "axios";
import {IFilter} from "../models/response/IFilterResponse";
import {IIParametrsFilter} from "../models/IFilterModel";


export default class FilterService {

    static async getFilters(body: IIParametrsFilter): Promise<AxiosResponse<IFilter[]>> {
        return $api.post<IFilter[]>(`/getFilters`, body); 
    }

}