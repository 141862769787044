import { PersonalSettingsOptions } from "../../../models/PersonalSettings";

export const TaskEmailNotificationSettingsMap = new Map<PersonalSettingsOptions, string>([
    [PersonalSettingsOptions.editTaskEmail, "Редактирование"],
    [PersonalSettingsOptions.commentTaskEmail, "Комментарии"],
]);

export const ProjectEmailNotificationSettingsMap = new Map<PersonalSettingsOptions, string>([
    [PersonalSettingsOptions.createDeleteTaskEmail, "Создание/удаление задач"],
    [PersonalSettingsOptions.projectEditInfoEmail, "Редактирование общей информации"],
    [PersonalSettingsOptions.createDeleteSubprojectEmail, "Уведомления о подпроектах"],
    [PersonalSettingsOptions.createDeleteBoardEmail, "Уведомления о канбан-досках"],
    [PersonalSettingsOptions.editBoardEmail, "Редактирование канбан-досок"],
]);

export const TaskFrontNotificationSettingsMap = new Map<PersonalSettingsOptions, string>([
    [PersonalSettingsOptions.editTaskFront, "Редактирование"],
    [PersonalSettingsOptions.commentTaskFront, "Комментарии"],
]);

export const ProjectFrontNotificationSettingsMap = new Map<PersonalSettingsOptions, string>([
    [PersonalSettingsOptions.createDeleteTaskFront, "Создание/удаление задач"],
    [PersonalSettingsOptions.projectEditInfoFront, "Редактирование общей информации"],
    [PersonalSettingsOptions.createDeleteSubprojectInfoFront, "Уведомления о подпроектах"],
    [PersonalSettingsOptions.createDeleteBoardFront, "Уведомления о канбан-досках"],
    [PersonalSettingsOptions.editBoardFront, "Редактирование канбан-досок"],
]);


export const NotificationSettingsMap = new Map<PersonalSettingsOptions, string>(
    [
        ...Array.from(TaskEmailNotificationSettingsMap),
        ...Array.from(ProjectEmailNotificationSettingsMap),
        ...Array.from(TaskFrontNotificationSettingsMap),
        ...Array.from(ProjectFrontNotificationSettingsMap)
    ]
);