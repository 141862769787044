import $api from "..";
import {AxiosResponse} from "axios";
import {TaskResponse, ColumnsResponse, DashBoardResponse} from "../models/response/DashBoardResponse";
import { IDeleteBoard } from "../models/IDeleteBoard";
import { IBoardEdit } from "../models/IBoardEdit";

export default class DashBoardService {

    static async addDashBoard(name: string, projectId: number, groupId: number): Promise<AxiosResponse<DashBoardResponse>> {
        return $api.post<DashBoardResponse>('Project/addDashBoard', {name, projectId, groupId})
    }

    static async getProjectDashBoards(id: number): Promise<AxiosResponse<DashBoardResponse>> {
        return $api.post<DashBoardResponse>('/Project/getDashBoards', {id});
    }

    static async getProjectIdDashBoards(id: number): Promise<AxiosResponse<number>> {
        return $api.get<number>(`/Project/getDashBoardsProjectId/?boardId=${id}`);
    }

    static async getAllColumnBoard(id: number): Promise<AxiosResponse<ColumnsResponse>> {
        return $api.post<ColumnsResponse>(`/Project/dashBoard/getColumns/?boardId=${id}`);
    }

    static async getAllTasksBoard(id: number): Promise<AxiosResponse<TaskResponse>> {
        return $api.post<TaskResponse>("/Project/dashBoard/columns/getTasks", {id});
    }

    static async deleteBoard(body: IDeleteBoard): Promise<void> {
        return $api.post("Project/deleteDashboard", body);
    }

    static async editBoard(body: IBoardEdit): Promise<void> {
        return $api.post("/Project/editDashBoard", body);
    }
}