import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";
import {IGetRole} from "../../../models/IGetRole";
import "./SafetySettings.css"
import {IEditElemSettings} from "../../../models/IElemSettings";
import ArrowRight from "../../../assets/ArrowRight.svg";
import ArrowSub from "../../../assets/arrowDown.svg";
import {AppContext} from "../../../App";

const SafetySettings: FC = (index) => {
    const { store } = useContext(Context);
    const [allSystemRoles, setAllSystemRoles] = useState<IGetRole[]>();
    const [allProjectRoles, setAllProjectRoles] = useState<IGetRole[]>();
    const [chooseSystemRole, setChooseSystemRole] = useState<IGetRole>();
    const [chooseProjectRole, setChooseProjectRole] = useState<IGetRole>();
    const [showSystemRole, setShowSystemRole] = useState<boolean>(false);
    const [showProjectRole, setShowProjectRole] = useState<boolean>(false);
    const { showToast } = useContext(AppContext);

    useEffect(() => {
        (async () => {
            try {
                const allRoles = await store.getAllRoles()

                if (allRoles) {
                    let systemRoles: IGetRole[] = [];
                    let projectRoles: IGetRole[] = [];
                    allRoles?.forEach((xx) => {
                        if (xx.requiredProject == 0) {
                            systemRoles.push(xx)
                        } else {
                            projectRoles.push(xx)
                        }
                    })
                    setAllSystemRoles(systemRoles);
                    setAllProjectRoles(projectRoles);
                }
            } catch(err) {
                console.log(err)
            }
        })()
    }, []);

    useEffect(() => {
        getRole();
    }, [allSystemRoles, allProjectRoles]);

    function getRole() {
        (async () => {
            try {
                const chooseRoles = await store.getSystemSettings();
                
                if (!chooseRoles?.length) {
                    return showToast("У вас нет доступа к списку ролей!");
                }

                let chooseSystemRole = chooseRoles.find(xx => xx.paramName == "systemRole");
                let findSystemRole = allSystemRoles?.find(xx => xx.id == Number(chooseSystemRole?.value));
                setChooseSystemRole(findSystemRole);

                let chooseProjectRole = chooseRoles.find(xx => xx.paramName == "projectRole");
                let findProjectRole = allProjectRoles?.find(xx => xx.id == Number(chooseProjectRole?.value));
                setChooseProjectRole(findProjectRole);
            } catch(err) {
                console.log(err)
            }
        })()
    }

    function changeSystem(role: IGetRole) {
        (async () => {
            try {
                let data: IEditElemSettings[] = [{
                    paramName: "systemRole",
                    value: String(role.id),
                    fileId: undefined
                }]

                const res = await store.editSystemSettings(data)
                getRole()
            } catch(err) {
                console.log(err)
            }
        })()
    };

    function changeProject(role: IGetRole) {
        (async () => {
            try {
                let data: IEditElemSettings[] = [{
                    paramName: "projectRole",
                    value: String(role.id),
                    fileId: undefined
                }]

                const res = await store.editSystemSettings(data)
                getRole()
            } catch(err) {
                console.log(err)
            }
        })()
    };

    return (
        <div className="safety__settings">
            <div className="safety__settings--pod">
                <div className="safety__settings--header__settings">
                    Автоматически выдавать роль пользователю в системе:
                </div>
                <div className="safety__settings--choose--role" onClick={() => (setShowSystemRole(!showSystemRole))}>
                    <div className="safety__settings--choose--role__curent">
                        {chooseSystemRole?.roleName}
                    </div>
                    <img className="safety__settings--choose--role__current--arrow" src={showSystemRole ? ArrowRight : ArrowSub}/>
                    {showSystemRole ?
                        <div className="safety__settings--choose__modal--size">
                            <div className="safety__settings--choose__modal--leave" onClick={() => (setShowSystemRole(!showSystemRole))}></div>
                            <div className="safety__settings--choose__modal">
                                {allSystemRoles?.map(xx => (
                                    <div className="safety__settings--choose__modal--elem"
                                         onClick={() => changeSystem(xx)}>
                                        {xx.roleName}
                                    </div>
                                ))}
                            </div>
                        </div>
                        : null}
                </div>
            </div>
            <div className="safety__settings--pod">
                <div className="safety__settings--header__settings">
                    Автоматически выдавать создателю проекта роль в проекте:
                </div>
                <div className="safety__settings--choose--role" onClick={() => (setShowProjectRole(!showProjectRole))}>
                    <div className="safety__settings--choose--role__curent">
                        {chooseProjectRole?.roleName}
                    </div>
                    <img className="safety__settings--choose--role__current--arrow" src={showProjectRole ? ArrowRight : ArrowSub}/>
                    {showProjectRole ?
                        <div className="safety__settings--choose__modal--size">
                            <div className="safety__settings--choose__modal--leave" onClick={() => (setShowProjectRole(!showProjectRole))}></div>
                            <div className="safety__settings--choose__modal">
                                {showProjectRole && allProjectRoles?.map(xx => (
                                    <div className="safety__settings--choose__modal--elem"
                                         onClick={() => (changeProject(xx))}>
                                        {xx.roleName}
                                    </div>
                                ))}
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    );
};

export default SafetySettings;