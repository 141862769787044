import { FC } from "react";

interface ITasksInfoProps {
    totalStats?: {
        total: number;
        ongoing: number;
        completed: number;
        overdue: number;
    };
}

const TasksInfo: FC<ITasksInfoProps> = ({ totalStats }) => {
    return (
        <div>
            <div className="right-bar-top-header">Общие сведения</div>
            <div className="user-monitor-right-menu-item">
                <label className="a_header_name_16">ВСЕГО ЗАДАЧ</label>
                <a>{totalStats?.total ?? "-"}</a>
            </div>
            <div className="user-monitor-right-menu-item">
                <label className="a_header_name_16">ЗАДАЧ В РАБОТЕ</label>
                <a>{totalStats?.ongoing ?? "-"}</a>
            </div>
            <div className="user-monitor-right-menu-item">
                <label className="a_header_name_16">ВЫПОЛНЕНО ЗАДАЧ</label>
                <a>{totalStats?.completed ?? "-"}</a>
            </div>
            <div className="user-monitor-right-menu-item">
                <label className="a_header_name_16">ПРОСРОЧЕНО ЗАДАЧ</label>
                <a className="bad">{totalStats?.overdue ?? "-"}</a>
            </div>
        </div>
    );
};

export default TasksInfo;
