import { FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../..";
import { ISubTaskView } from "../../../../models/ISubTask";
import "../../../styles/table.pc.css";
import "./SubTasksGraph.css";
import { Link } from "react-router-dom";
import TaskStatus from "../../../Shared/TaskStatus";
import TaskPriority from "../../../Shared/TaskPriority";
import PersonLineDisplay from "../../../Shared/PersonLineDisplay";
import { fullNameFormat } from "../../../../helpers/Inicials";
import { dateToNiceString } from "../../../../helpers/dateToNiceString";
import ArrowDown from "../../../../assets/arrowDown.svg";
import ArrowRight from "../../../../assets/ArrowRight.svg";
import SubTasks from "../../../../assets/subtasks.svg";
import LineConnector from "./LineConnector";
import TaskType from "../../../Shared/TaskType";
import UserPopupWrapper from "../../../Shared/UserPopup/UserPopupWrapper";

const levelToColor = new Map<number, string>([
    [0, "#F97316"],
    [1, "#699F4D"],
    [2, "#C25ADB"],
    [3, "#6974EB"],
    [4, "#C9D6FC"],
    [5, "#F5D5E0"],
    [6, "#F7EBFC"],
]);

interface SubTasksGraphProps {
    taskId: number
    treeHead?: ISubTaskView;
}

const SubTasksGraph: FC<SubTasksGraphProps> = ({ taskId, treeHead }) => {

    const [graphUpdateFlag, setGraphUpdateFlag] = useState<boolean>(true);
    const [foldedTaskIds, setFoldedTaskIds] = useState<number[]>([]);
    const [graphOffset, setGraphOffset] = useState<[number, number]>([0, 0]);
    const [graphParentHeight, setGraphParentHeight] = useState<number>(0);
    const tableRef = useRef(null);

    const foldTask = (taskId: number) => {
        setFoldedTaskIds([...foldedTaskIds, taskId]);
        setGraphUpdateFlag(!graphUpdateFlag);
    };

    const unfoldTask = (taskId: number) => {
        setFoldedTaskIds(foldedTaskIds.filter((id) => id !== taskId));
        setGraphUpdateFlag(!graphUpdateFlag);
    };

    useEffect(() => {
        const updateDimensions = () => {
            if (!tableRef.current) return;
            const parent = tableRef.current as HTMLDivElement;
            const parentRect = parent.getBoundingClientRect();
            setGraphOffset([-parentRect.left, -parentRect.top]);
            setGraphParentHeight(parentRect.height - 20);
        };

        updateDimensions();

        const observer = new ResizeObserver(() => {
            updateDimensions();
        });

        if (tableRef.current) {
            observer.observe(tableRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [tableRef.current]);

    const renderTask = (
        task: ISubTaskView,
        level: number,
        parentTaskId: number | undefined
    ): ReactNode => {
        return (
            <>
                <tr
                    className={`subtasks-row ${
                        task.id === taskId ? "highlighted" : ""
                    }`}
                >
                    <td
                        style={{
                            width: "fit-content",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "fit-conent",
                            }}
                        >
                            {parentTaskId ? (
                                <LineConnector
                                    offset={graphOffset}
                                    parentHeight={graphParentHeight}
                                    sourceId={`subtask-${parentTaskId}`}
                                    targetId={`subtask-${task.id}`}
                                    updateFlag={graphUpdateFlag}
                                />
                            ) : null}

                            <div
                                style={{
                                    flexShrink: 0,
                                    display: "flex",
                                    marginLeft: `${15 * level}px`,
                                }}
                            >
                                <div>
                                    <svg
                                        width="5"
                                        height="5"
                                        style={{
                                            verticalAlign: "bottom",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <circle
                                            id={`subtask-${task.id}`}
                                            cx="2.5"
                                            cy="2.5"
                                            r="2.5"
                                            fill="#E1E7EA"
                                        />
                                    </svg>
                                </div>
                                {task.subTasks.length ? (
                                    <button
                                        className="subtask-hide-button"
                                        onClick={() =>
                                            !foldedTaskIds.some(
                                                (id) => id === task.id
                                            )
                                                ? foldTask(task.id)
                                                : unfoldTask(task.id)
                                        }
                                    >
                                        <img
                                            alt="arrow"
                                            width="20px"
                                            style={{ marginLeft: "-10px" }}
                                            src={
                                                !foldedTaskIds.some(
                                                    (id) => id === task.id
                                                )
                                                    ? ArrowRight
                                                    : ArrowDown
                                            }
                                        ></img>
                                    </button>
                                ) : (
                                    <div
                                        style={{
                                            width: "20px",
                                            marginLeft: "-10px",
                                        }}
                                    ></div>
                                )}
                                <div>
                                    <svg
                                        width="10"
                                        height="10"
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        <circle
                                            cx="4"
                                            cy="4"
                                            r="4"
                                            fill={
                                                levelToColor.get(level % 7) ??
                                                "black"
                                            }
                                        />
                                    </svg>

                                    <Link
                                        to={"/task/" + task.id}
                                        className="subtask-number-text"
                                    >
                                        {task.number}
                                    </Link>
                                    <div>
                                        <span className="subtask-subtasks-number-text">
                                            {task.subTasks.length}
                                        </span>
                                        <img src={SubTasks}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style={{ width: "fit-content", maxWidth: "200px" }}>
                        <Link to={"/task/" + task.id} className="subtask-name">
                            {task.name}
                        </Link>
                    </td>
                    <td style={{ width: "fit-content", maxWidth: "200px" }}>
                        <Link
                            to={"/board/" + task.board.id}
                            className="subtask-board"
                        >
                            {task.board.name}
                        </Link>
                    </td>
                    <td>
                        <TaskStatus
                            name={task.status.name}
                            color={JSON.parse(task.status.style).color}
                        />
                    </td>
                    <td>
                        <TaskPriority
                            name={task.priority.name}
                            color={JSON.parse(task.priority.style).color}
                            icon={JSON.parse(task.priority.style).icon}
                        />
                    </td>
                    <td>
                        <TaskType
                            name={task.type.name}
                            color={JSON.parse(task.type.style).color}
                            icon={JSON.parse(task.type.style).icon}
                        />
                    </td>
                    <td>
                        <UserPopupWrapper userId={task.responsible.id}>
                            <PersonLineDisplay
                                name={fullNameFormat(
                                    {
                                        name: task.responsible.name,
                                        surname: task.responsible.surname,
                                        middlename: task.responsible.middlename,
                                    },
                                    "s N M"
                                )}
                                photoId={task.responsible.photoId}
                            />
                        </UserPopupWrapper>
                    </td>
                    <td>
                        {task.endDate
                            ? dateToNiceString(
                                  new Date(task.endDate),
                                  "dd.MM.yyyy"
                              )
                            : null}
                    </td>
                </tr>
                {!foldedTaskIds.some((id) => id === task.id)
                    ? task.subTasks.map((subTask) =>
                          renderTask(subTask, level + 1, task.id)
                      )
                    : null}
            </>
        );
    };

    return (
        <div
            style={{ position: "relative", overflowX: "scroll" }}
            ref={tableRef}
        >
            <table
                className="subtasks-table"
                style={{ width: "100%", overflow: "scroll" }}
            >
                <thead>
                    <tr>
                        <th>
                            <div style={{ marginLeft: "20px" }}>№</div>
                        </th>
                        <th>НАЗВАНИЕ</th>
                        <th>ДОСКА</th>
                        <th>СТАТУС</th>
                        <th>ПРИОРИТЕТ</th>
                        <th>ТИП</th>
                        <th>ИСПОЛНИТЕЛЬ</th>
                        <th>ДАТА</th>
                    </tr>
                </thead>
                <tbody>
                    {treeHead ? renderTask(treeHead, 0, undefined) : null}
                </tbody>
            </table>
        </div>
    );
};

export default SubTasksGraph;
