export enum SortType {
    date = "date",
    text = "text",
    number = "number",
}

/**
 * Сортирует массив данных в зависимости от указанного типа сортировки.
 *
 * @param {SortType} type - Тип сортировки: 'date' (дата), 'text' (текст), 'number' (число).
 * @param {string[] | number[]} data - Массив данных для сортировки.  Должен содержать только строки (для 'date' и 'text') или только числа (для 'number').
 * @returns {string[] | number[] | undefined} - Отсортированный массив данных того же типа, что и входной массив. Возвращает `undefined`, если произошла ошибка (неверный тип данных или ошибка сортировки).
 *
 * Функция выполняет сортировку массива данных в зависимости от указанного типа сортировки.  Для дат используется сортировка по времени, для строк - лексикографическая сортировка, для чисел - числовая сортировка.
 * Функция проверяет тип данных и обрабатывает потенциальные ошибки. Если входные данные имеют неверный формат, функция возвращает `undefined` и выводит сообщение об ошибке в консоль.
 */
export const sortAny = (type: SortType, data: any[], sortDirection: "asc" | "desc"): any[] | undefined => {
    try {
        const multiplier = sortDirection === "asc" ? 1 : -1;

        if (type === SortType.date) {
            return (data as string[]).sort((a, b) => multiplier * (new Date(a).getTime() - new Date(b).getTime()));
        } else if (type === SortType.text) {
            return (data as string[]).sort((a, b) => multiplier * a.localeCompare(b));
        } else if (type === SortType.number) {
            return (data as number[]).sort((a, b) => multiplier * (a - b));
        }
    } catch (error) {
        console.error("Неверный формат данных")
        return undefined;
    }

    return undefined
};