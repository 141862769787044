import React, {FC} from 'react';
import WidgetContainer from "../../UiLib/WidgetContainer/WidgetContainer";
import Button from "../../UiLib/Button/Button";
import AbsolutePosition from "../../UiLib/AbsolutePosition/AbsolutePosition";
import {zPositionWindows} from "../../UiLib/zPositionWindows";
import "./ConfirmModal.css"

interface IConfirmModalProps {
    headText: string;
    bodyText?: string;
    confirmText: string;
    cancelText: string;
    confirmImg?: any;
    cancelImg?: any;
    callBack: (check: boolean) => void;
}

const ConfirmModal: FC<IConfirmModalProps> = ({headText, bodyText, confirmText, cancelText,confirmImg,cancelImg, callBack}) => {
    const onConfirm = () => {
        callBack(true)
    }

    const onCloseConfirm = () => {
        callBack(false)
    }

    return (
        <AbsolutePosition zIndex={zPositionWindows.confirmModal}>
            <WidgetContainer >
                <div className="modal__confirm--block">
                    <div className="modal__confirm--block__head">
                        <h2>{headText}</h2>
                    </div>
                    <div className="modal__confirm--block__body">
                        <p>{bodyText}</p>
                    </div>
                </div>
                <div className="modal__confirm--block__buttons">
                    <Button textColor="--ui-color-absolute-position-button-confirm-text"
                            style={{backgroundColor: "var(--ui-color-absolute-position-button-confirm)",
                                padding: "18px",
                                borderRadius: "5px",
                                marginRight: "10px"
                            }}
                            text={confirmText}
                            image={confirmImg}
                            callBack={() => onConfirm()}></Button>
                    <Button textColor="--ui-color-absolute-position-button-cansel-text"
                            style={{backgroundColor: "var(--ui-color-absolute-position-button-cansel)",
                                padding: "18px",
                                borderRadius: "5px"
                            }}
                            text={cancelText}
                            image={cancelImg}
                            callBack={() => onCloseConfirm()}></Button>
                </div>
            </WidgetContainer>
        </AbsolutePosition>
    );
};

export default ConfirmModal;