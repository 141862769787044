import React, {CSSProperties, FC, ReactNode} from 'react';
import "./WidgetContainer.css"

interface ICurrentWidgetProps {
    children: ReactNode;
    style?: CSSProperties;
}

/**
 * Компонент-контейнер для виджета.
 *
 * @param {ICurrentWidgetProps} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы, которые представляют контент виджета.
 * @param {CSSProperties} [props.style] - Дополнительные стили, которые применяются к контейнеру виджета.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент-контейнер для виджета.
 *
 * @example
 * ```jsx
 *  <WidgetContainer style={{ backgroundColor: 'lightblue', padding: '10px'}}>
 *      <div>Контент виджета</div>
 *  </WidgetContainer>
 * ```
 */
const WidgetContainer: FC<ICurrentWidgetProps> = ({children, style}) => {
    return (
        <div style={{...style}} className="ui__component--widget__container">
            {children}
        </div>
    );
};

export default WidgetContainer;