import React, {Component, FC} from 'react';
import "./Loader.css"


const Loader: FC = () => {
    return (
        <div className='global-loader-container-none_height'>
            <div className='global-loader-container'>
                <div className="global-loader">

                </div>
            </div>
        </div>
    );
}

export default Loader;