import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import "../../globalstyles/page.pc.css"
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import '../../pages/Project/Project.css'
import '../../component/styles/input.css'
import NameProject from "../../component/Project/NameProject/NameProject";
import Files from "../../component/Project/Files/Files";
import HistoryProject, { HISTORY_PAGE_SIZE } from "../../component/Project/HistoryProject/HistoryProject";
import Team, {ITeamFilter} from "../../component/Project/Team/Team";
import GeneralInformation from "../../component/Project/GeneralInformation/GeneralInformation";
import Tasks, {ITaskFilter, PAGE_SIZE} from "../../component/Project/Tasks/Tasks";

import {IGetTasksDisplayData, ITaskDisplay, ITasksDisplay, ITasksDisplayFilters} from "../../models/TaskModels";
import {
    IGetProjectHistoryDisplay,
    IProjectHistoryDisplay,
    IProjectGeneralInfo,
    IProjectProgressInfo,
    IGetProjectHistoryFilters
} from "../../models/ProjectModels";
import {IFileFilterOptions, IFileFilters, IFilesDisplay, IGetFilesDisplayData} from "../../models/FileModels";
import {IGetTeamDisplay, ITeamMemberDisplay} from "../../models/TeamModels";
import {IIParametrsFilter} from "../../models/IFilterModel";
import {IFilter} from "../../models/response/IFilterResponse";
import {initials} from '../../helpers/Inicials';
import {AppContext} from "../../App";
import {ICheckRole, IHasPermission} from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import { SEO } from '../../component/Helmet/HelmetSEO';
import Loader from "../../component/Shared/Loader/Loader";
import {IProjects} from "../../models/IProject";


export const ProjectContext = createContext<any>(null);

export interface ISort {
    date?: "asc" | "desc";
    status?: "asc" | "desc";
    responsible?: "asc" | "desc";
    type?: "asc" | "desc";
    priority?: "asc" | "desc";
    nameTask?: "asc" | "desc";
}

interface ItemTeam {
    name: string;
    code: string;
    status?: string;
}

export interface ISort {
    date?: "asc" | "desc";
    status?: "asc" | "desc";
    responsible?: "asc" | "desc";
    type?: "asc" | "desc";
    priority?: "asc" | "desc";
    nameTask?: "asc" | "desc";
}

interface ItemTeam {
    name: string;
    code: string;
}

const Project: FC = (index) => {
    const navigate = useNavigate();
    const {store} = useContext(Context);
    const { pageTitle, showToast } = useContext(AppContext);

    const {id} = useParams();
    //NAME PROJECT
    const [nameData, setNameData] = useState<IProjectGeneralInfo | undefined>(undefined);
    //PROGRESS INFO
    const [progressData, setProgressData] = useState<IProjectProgressInfo | undefined>(undefined);
    //FILES
    const [files, setFiles] = useState<IFilesDisplay | undefined>(undefined);
    const [filesFilterOptions, setFilesFilterOptions] = useState<IFileFilterOptions | undefined>(undefined);
    //HISTORY
    const [changes, setChanges] = useState<IProjectHistoryDisplay[]>([]);
    //TEAM
    const [team, setTeam] = useState<ITeamMemberDisplay[]>([]);
    const [scrollEndedTeam, setScrollEndedTeam] = useState(false);
    const [persons, setPersons] = useState<ItemTeam[]>([]);
    const [roles, setRoles] = useState<ItemTeam[]>([]);
    const [pageTeam, setPageTeam] = useState(1);


    //TASKS
    const [scrollEndedTasks, setScrollEndedTasks] = useState(false);
    const [tasks, setTasks] = useState<ITaskDisplay[]>([]);
    const [taskFilters, setTaskFilters] = useState<ITasksDisplayFilters>({
        priority: [],
        type: [],
        status: [],
        tag: []
    });

    const [sortAsc, setSortAsc] = useState(false);
    const [skipTasks, setSkipTasks] = useState(0);
    // const [openTask, setOpenTask] = useState(false);

    //FILTERS
    const [filterDataTask, setFilterDataTask] = useState<IFilter[] | undefined>();
    const [filterDataTeam, setFilterDataTeam] = useState<IFilter[] | undefined>();

    const [TaskReset, setTaskReset] = useState<any | undefined>();
    const [TeamReset, setTeamReset] = useState<any | undefined>();

    const [selectedUsersTasks, setSelectedUsersTasks] = useState(null);
    const [datesTasks, setDatesTasks] = useState(null);
    const [selectedUsersTeams, setSelectedUsersTeams] = useState(null);

    const [counterTasks, setCounterTasks] = useState<number>(0);
    const [counterTeam, setCounterTeam] = useState<number>(0);

    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    const [checkedFiltersTasks, setCheckedFiltersTasks] = useState<any>({
        projectId: Number(id),
        status: undefined,
        priority: undefined,
        type: undefined,
        name: undefined,
        responsible: undefined,
        date: undefined
    });

    const [checkedFiltersTeams, setCheckedFiltersTeams] = useState<any>({
        projectId: Number(id),
        position: undefined,
        responsible: undefined,
    });

    const fieldFilterTasks: IIParametrsFilter = {
        projectId: Number(id),
        date: true,
        responsible: true,
        status: true,
        typeTask: true,
        priority: true,
        role: false,
        fileType: false,
    };

    const fieldFilterTeams: IIParametrsFilter = {
        projectId: Number(id),
        date: false,
        responsible: false,
        status: false,
        typeTask: false,
        priority: false,
        role: true,
        fileType: false,
    }

    // const fieldFilterFiles: IIParametrsFilter = {
    //     projectId: Number(id),
    //     date: false,
    //     responsible: false,
    //     status: false,
    //     typeTask: false,
    //     priority: false,
    //     role: false,
    //     fileType: true,
    // }

    //Ограничение доступа
    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem()
                const newCheckRole: ICheckRole = {
                    projectId: Number(id),
                    boardId: undefined,
                    functionCodes: functionClass.getProjectFunction
                };


                const checkThisRole = await store.hasPermission(newCheckRole)
                setUserAccess(checkThisRole);
            } catch (error) {
                console.log(error)
            }
        })();
    }, [id]);

    //NAME PROJECT FUNCTIONS
    const loadNameProjectData = async (): Promise<IProjectGeneralInfo | null | number> => {
        let res = await store.getProjectGeneralInfo(Number(id))
        return res;
    }

    useEffect(() => {
        const filterTasks = structuredClone(checkedFiltersTasks);
        filterTasks.projectId = Number(id);
        setCheckedFiltersTasks(filterTasks);

        const filterTeams = structuredClone(checkedFiltersTeams);
        filterTeams.projectId = Number(id);
        setCheckedFiltersTasks(filterTeams);

        setSkipTasks(0);
        setScrollEndedTasks(false);

        setPageTeam(1);
        setScrollEndedTeam(false);

        loadNameProjectData().then((res) => {
            if(!res){
                // navigate('/projects')
                console.error("Ошибка")
            }
            if (res === 404) {
                navigate('/projects')
            }
            if(typeof res !== 'number') setNameData(res!);
        }).catch((error) => {
            showToast("Ошибка загрузки данных!")
        });

        (async () => {
            try {
                // await
                await loadProjectProgressData()
                await loadFiles({
                    projectId: Number(id),
                    name: '',
                    fileType: []
                })
                await loadTeamMembers(0, PAGE_SIZE, {
                    projectId: Number(id),
                    name: undefined,
                    role: undefined
                })
                setSkipTasks(PAGE_SIZE);
                await loadTasks(0, PAGE_SIZE, {
                    projectId: Number(id),
                    responsible: undefined,
                    date: undefined,
                    taskId: undefined,
                    status: undefined,
                    priority: undefined,
                    typeTask: undefined,
                    name: undefined
                }, {date: sortAsc ? "asc" : "desc"});
                await loadSystemPersonWithRoles();
                await loadChanges(0, HISTORY_PAGE_SIZE, undefined, false);
                await getFilterData(fieldFilterTasks, 'task');
                await getFilterData(fieldFilterTeams, 'team');

                const taskFilters = await store.getProjectTasksFilters(
                    Number(id)
                );
                if (
                    taskFilters &&
                    taskFilters.type &&
                    taskFilters.priority &&
                    taskFilters.status
                )
                    setTaskFilters(taskFilters);
            } catch (error) {
                console.log(error)
            }
        })();

        store.setCurrentProjectId(Number(id))
    }, [id]);

    const loadSystemPersonWithRoles = async () => {
        await store.getSystemPersonWithRoles();
        if (store.personsWithRoles && store.personsWithRoles.person && store.personsWithRoles.roles) {
            let personsData: ItemTeam[] = [];
            let rolesdata: ItemTeam[] = [];
            store.personsWithRoles.person.forEach(i => {
                let item = {
                    name: String(i?.surname + (i?.name?.length ? " " + i?.name?.slice(0, 1) + "." : "") + (i?.middlename?.length ? "" + i?.middlename?.slice(0, 1) + "." : "")),
                    code: i?.id?.toString(),
                    status: i?.status
                }
                personsData.push(item)
            });


            store.personsWithRoles.roles.forEach(i => {
                let item = {
                    name: i?.name,
                    code: i?.id?.toString(),
                }
                rolesdata.push(item)
            });


            setPersons([...personsData]);
            setRoles([...rolesdata]);

        }
    }

    //PROJECT PROGRESS INFO
    const loadProjectProgressData = async () => {
        let res = await store.getProjectProgressData(Number(id))
        setProgressData(res!);
    }
    //FILES FUNCTIONS
    const loadFiles = async (filters: IFileFilters) => {

        let req: IGetFilesDisplayData = {
            filters: filters
        }

        const res = await store.getFilesDisplayData(req) as IFilesDisplay;
        setFiles(res);
    }

    // const loadFileFilterOptions = async () => {
    //     const resFilterOptions = await store.getFileFilterOptions() as IFileFilterOptions;
    //     setFilesFilterOptions(resFilterOptions)
    // }

    //HISTORY FUNCTIONS
    const loadChanges = async (skip: number, take: number, filters: IGetProjectHistoryFilters | undefined, append: boolean): Promise<boolean> => {
        let req: IGetProjectHistoryDisplay = {
            skip: skip,
            take: take,
            filters: filters ?? {
                projectId: Number(id)
            },
            sort: {
                date: "desc"
            }
        }

        const res = await store.getProjectHistoryDisplayData(req);
        if (Array.isArray(res)) {
            if (append)
                setChanges([...changes, ...res]);
            else
                setChanges(res);
        }
        return res.length > 0;
    }

    //TODO add appendTasks
    //TEAM FUNCTIONS
    const loadTeamMembers = async (skip: number, take: number, filters: ITeamFilter) => {
        const newPageReq: IGetTeamDisplay = {
            skip: skip,
            take: take,
            filters: filters
        };


        const res = await store.getProjectTeamDisplayData(newPageReq);
        console.log("res", res);
        if (res !== null) {
            setTeam(structuredClone(res?.team!));
        } else {
            setTeam([]);
        }

    }

    const appendTeamMembers = async (skip: number, take: number, filters: ITeamFilter) => {
        const newPageReq: IGetTeamDisplay = {
            skip: skip,
            take: take,
            filters: filters
        };

        const res = await store.getProjectTeamDisplayData(newPageReq);
        if (res?.team && res?.team?.length > 0) {
            setTeam([...team, ...res.team]);
        } else {
            setScrollEndedTeam(true)
        }
    }

    const handleLoadTeamMembers = async (skip: number, take: number, filters: ITeamFilter) => {
        await appendTeamMembers(skip, take, filters);
    }
    const handleUpdateFiltersTeam = async (filters: ITeamFilter) => {
        setScrollEndedTeam(false)
        await loadTeamMembers(0, PAGE_SIZE, filters);
    }

    //TASK FUNCTIONS
    const loadTasks = async (skip: number, take: number, filters: ITaskFilter, sort: ISort) => {

        setScrollEndedTasks(false);
        const newPageReq: IGetTasksDisplayData = {
            skip: skip,
            take: take,
            filters: filters,
            sort: sort
        };

        const res = await store.getProjectTasksDisplayData(newPageReq) as ITasksDisplay;
        if (res !== null) {
            setTasks(res.tasks);
        } else {
            setTasks([]);
        }

    }
    const appendTasks = async (skip: number, take: number, filters: ITaskFilter, sort: ISort) => {
        const newPageReq: IGetTasksDisplayData = {
            skip: skip,
            take: take,
            filters: filters,
            sort: sort
        };

        const res = await store.getProjectTasksDisplayData(newPageReq) as ITasksDisplay;
        if (res?.tasks && res?.tasks?.length > 0) {
            setTasks((prev) => [...prev, ...res.tasks]);
        } else { //TODO check res status
            setScrollEndedTasks(true);
        }

    }
    const handleLoadPageTasks = async (skip: number, take: number, filters: ITaskFilter, sort: ISort) => {
        await appendTasks(skip, take, filters, sort);
    }

    const getFilterData = async (body: IIParametrsFilter, typeFilter: 'team' | 'task') => {
        let data = await store.getDataForFilter(body);
        //Преобразования Имя пользователя к формату ФИО
        let a: any = data?.map(i => {
            if (i.type && i.type === 'users') {
                let c: any = i.items?.map((x: any) => {
                    return {code: x.code, name: initials(x.name!)}
                });
                if (c?.length) {
                    i.items = c;
                    return i;
                } else {
                    return i;
                }
            } else {
                return i;
            }
        })

        let resetData = structuredClone(a);
        if (data?.length && a?.length && typeFilter === 'task') {
            setFilterDataTask(a);
            setTaskReset(resetData)
        } else if (data && typeFilter === 'team') {
            setFilterDataTeam(data);
            setTeamReset(resetData);
        }
    }

    const applayFiltersTeams = async () => {
        setPageTeam(1);
        setScrollEndedTeam(false);
        await loadTeamMembers(0, PAGE_SIZE, checkedFiltersTeams);
    }

    const resetFilter = (typeFilter: 'team' | 'task') => {
        if (typeFilter === 'task') {


        } else if (typeFilter === 'team') {
            setPageTeam(1);
            TeamReset && setFilterDataTeam(structuredClone(TeamReset));

            let nullFilters = {
                projectId: Number(id),
                role: undefined,
                name: '',
            }
            setCheckedFiltersTeams({...nullFilters});
            setSelectedUsersTeams(null);
            setCounterTeam(0);
            (async () => {
                setScrollEndedTeam(false);
                await loadTeamMembers(0, PAGE_SIZE, nullFilters);
            })();
        }
    }

    return (
        <ProjectContext.Provider value={{
            loadFiles, persons, roles, filterDataTask,
            checkedFiltersTasks, setCheckedFiltersTasks,
            filterDataTeam, checkedFiltersTeams, setCheckedFiltersTeams, applayFiltersTeams,
            resetFilter, setFilterDataTask, setFilterDataTeam,
            selectedUsersTasks, setSelectedUsersTasks,
            datesTasks, setDatesTasks,
            selectedUsersTeams, setSelectedUsersTeams,
            counterTasks, setCounterTasks,
            counterTeam, setCounterTeam,
            loadNameProjectData, sortAsc, setSortAsc,
            skipTasks, setSkipTasks, pageTeam, setPageTeam,
            taskFilters, loadTasks, setScrollEndedTeam,
            scrollEndedTasks, setScrollEndedTasks, TaskReset
        }}>
            <SEO title={nameData ? nameData.projectName + " | Проект · " + pageTitle : pageTitle} />
            {loader ? <Loader /> : null}
            <div className='widgets_container'>
                <div className='widgets_container_column'>
                    <NameProject
                        data={nameData}
                        loadNameProjectData={async () => {
                            await loadNameProjectData();
                            await loadProjectProgressData();
                        }}
                        userAccess={userAccess}
                    />
                    <Files files={files} filterOptions={filesFilterOptions} updateFilters={loadFiles}
                           userAccess={userAccess}/>
                    <HistoryProject records={changes} loadPage={loadChanges} userAccess={userAccess}/>
                </div>
                <div className='widgets_container_column'>
                    <Team members={team} scrollEnded={scrollEndedTeam} loadPage={handleLoadTeamMembers}
                          updateFilters={handleUpdateFiltersTeam} loadTeamMembers={loadTeamMembers}
                          userAccess={userAccess}/>
                    <GeneralInformation data={progressData}/>

                    <Tasks tasks={tasks} scrollEnded={scrollEndedTasks} loadPage={handleLoadPageTasks}
                           userAccess={userAccess}/>
                </div>
            </div>
        </ProjectContext.Provider>
    );
}

export default observer(Project);
