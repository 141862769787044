import ProjectsMain from "../../component/ProjectsMain/ProjectsMain";
import { observer } from "mobx-react-lite";
import { FC } from "react";

const Archive: FC = () => {
    return (
        <ProjectsMain loadArchivedProjects={true}></ProjectsMain>
    )
}

export default observer(Archive);