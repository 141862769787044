import React, {FC, useContext, useEffect, useState} from "react";
import {IColumnDisplay} from "../../../models/IColumnDisplay";
import {BoardContext} from "../../../pages/Board/Board";
import BoardTask from "../BoardTask/BoardTask";
import {useNavigate} from "react-router-dom";
import BoardColumnOptions from "../BoardColumnOptions/BoardColumnOptions";
import ProxyToBlue from "../../../assets/proxyArrow.svg"
import ProxyToGray from "../../../assets/proxyArrowGray.svg"

import AddTaskBlue from "../../../assets/addTaskInColumn.svg"
import AddTaskGray from "../../../assets/addGray.svg"

import SettingColumnBlue from "../../../assets/gorizontThreeButtonBlue.svg"
import SettingColumnGray from "../../../assets/threePointSetings.svg"

import {Context} from "../../../index";
import {AppContext} from "../../../App";
import {IHasPermission} from "../../../models/IChekRole";
import ConfirmationWindow from "../../Shared/ConfirmationWindow/ConfirmationWindow";
import {IRestrictAccess} from "../../../models/IRestrictAccess";

interface IBoardColumnProps {
    column: IColumnDisplay,
    userAccess: IHasPermission[],
    columnAccess: boolean,
    boardsData?: IRestrictAccess | undefined,
    currentZoom: any
}

const BoardColumn: FC<IBoardColumnProps> = ({column, userAccess, columnAccess, boardsData, currentZoom}) => {
    const [previewTaskId, setPreviewTaskId] = useState<number | undefined>();
    const [previewColumnId, setPreviewColumnId] = useState<number | undefined>();
    const [dragEventTarget, setDragEventTarget] = useState<any>();
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const navigate = useNavigate();
    const {store} = useContext(Context);
    const {showToast} = useContext(AppContext);

    const isProxyColumn: boolean = column.proxyBoardId !== undefined && column.proxyBoardId !== 0;
    const maxLength = isProxyColumn ? 27 : 22;
    const displayName = column?.name?.length > maxLength
        ? column.name.slice(0, maxLength) + "..."
        : column.name;

    // Ограничение прав
    const [viewProxy, setViewProxy] = useState(false);
    const [addTask, setAddTask] = useState(false);
    const [editColumn, setEditColumn] = useState(false);
    const [viewBoard, setViewBoard] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "ColumnAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditColumn(true);
                    }
                })
            }
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddTask(true);
                    }
                })
            }
            if (xx.functionCode == "ColumnProxyAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewProxy(true);
                    }
                })
            }
            userAccess.forEach((xx) => {
                if (xx.functionCode == "BoardAction") {
                    xx.permissions.forEach((yy) => {
                        if (yy.permissionCode == "view" && yy.isGranted == true) {
                            setViewBoard(true);
                        }
                    })
                }
            })
        })

        // if (store.user.email == "admin@admin.adm") {
        //     setViewProxy(true);
        //     setAddTask(true);
        //     setEditColumn(true);
        //     setViewBoard(true);
        // }
    }, [userAccess]);

    const {
        handleMoveTask,
        columns,
        actionMode,
        selectedTasks,
        openAddTaskForm,
        abbreviationProject
    } = useContext(BoardContext);
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragEventTarget(e.target);
    };

    const handleDragOver = (
        e: React.DragEvent<HTMLDivElement>,
        columnId: number
    ) => {
        if (columnAccess) {
            return;
        }
        e.preventDefault();
        if (e.dataTransfer.types.length > 1 ||
            (e.dataTransfer.types.length && e.dataTransfer.types[0] !== "application/json"))
            return;

        const droppedPosition = e.clientY;

        const column = columns.find((c) => c.id === columnId);
        if (!column) return;

        let taskId: number | undefined = undefined;

        for (let task of column.tasks) {
            const taskElement = document.getElementById(`task-${task.id}`);
            if (taskElement) {
                const rect = taskElement.getBoundingClientRect();
                const midY = rect.top + rect.height / 2;
                if (droppedPosition < midY) {
                    taskId = task.id;
                    break;
                }
            }
        }

        if (taskId === previewTaskId && previewColumnId === columnId) return;

        removePreview();

        if (taskId) {
            const taskElement = document.getElementById(`task-${taskId}`);
            if (taskElement) {
                let newElement = createPreviewElement(
                    "<div className='create__preview--element__drag--block'>" +
                    "<div>"
                );
                taskElement.insertAdjacentElement("beforebegin", newElement);
                setPreviewTaskId(taskId);
            }
        } else if (columnId !== previewColumnId) {
            const taskElement = document.getElementById(
                `tasks-container-${columnId}`
            );
            if (taskElement) {
                let newElement = createPreviewElement(
                    "<div className='create__preview--element__drag--block'>" +
                    "<div>"
                );
                taskElement.insertAdjacentElement("afterend", newElement);
                setPreviewColumnId(columnId);
            }
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (dragEventTarget !== e.target) {
            removePreview();
        }
    };

    const handleOnDrop = (
        e: React.DragEvent<HTMLDivElement>,
        columnId: number
    ) => {
        if (columnAccess) {
            return;
        }

        e.preventDefault();
        try {
            const {taskId} = JSON.parse(
                e.dataTransfer.getData("application/json")
            );

            const droppedPosition = e.clientY;

            const newColumn = columns.find((c) => c.id === columnId);
            if (!newColumn) return;

            const oldColumn = columns.find(
                (c) => c.tasks.find((t) => t.id === taskId) !== undefined
            );
            if (!oldColumn) return;

            const movedTask = oldColumn.tasks.find((t) => t.id === taskId);
            if (!movedTask) return;

            let insertIndex = newColumn.tasks.length;

            for (let task of newColumn.tasks) {
                const taskElement = document.getElementById(`task-${task.id}`);
                if (taskElement) {
                    const rect = taskElement.getBoundingClientRect();
                    const midY = rect.top + rect.height / 2;
                    if (droppedPosition < midY) {
                        insertIndex = newColumn.tasks.indexOf(task);
                        break;
                    }
                }
            }

            if (oldColumn === newColumn) {
                if (insertIndex !== oldColumn.tasks.indexOf(movedTask))
                    insertIndex--;
                if (insertIndex < oldColumn.tasks.indexOf(movedTask))
                    insertIndex++;

                if (insertIndex === oldColumn.tasks.indexOf(movedTask)) {
                    return;
                }
            }

            handleMoveTask(taskId, columnId, insertIndex);

            removePreview();
        } catch (err) {
            removePreview();
        }
    };

    const handleDragEnd = () => {
        removePreview();
    };

    const removePreview = () => {
        const elements = document.querySelectorAll(".task-preview");
        elements.forEach((element) => element.remove());
        setPreviewTaskId(undefined);
        setPreviewColumnId(undefined);
    };

    function createPreviewElement(content: string) {
        let newElement = document.createElement("div");
        newElement.className = "task-preview";
        newElement.innerHTML = content;
        newElement.style.backgroundColor = "#149cce6b";
        // newElement.style.padding = "25px";
        newElement.style.minHeight = "200px";
        newElement.style.margin = "6px 0px 6px 6px";
        newElement.style.borderRadius = "6px";
        newElement.style.pointerEvents = "none";
        return newElement;
    }

    useEffect(() => {

    }, [column]);

    return (
        <div key={column.id} className="dash__board--elem droppable-column" onDragEnter={handleDragEnter}
             onDragOver={(e) => handleDragOver(e, column.id)}
             onDrop={(e) => handleOnDrop(e, column.id)} onDragLeave={handleDragLeave}
             style={isProxyColumn ?
                 columnAccess ? {opacity: "0.5", minWidth: "390px", maxWidth: "390px"} :
                     {minWidth: "390px", maxWidth: "390px"} :
                 columnAccess ? {opacity: "0.5"} :
                     {}
             }
        >
            {/*<div><ConfirmationWindow headerText={"Завершить задачу"} bodyText={} confirmText={} cancelText={} onConfirm={} onCancel={}/></div>*/}
            <div className="canban-column-default"
                 style={isProxyColumn
                     ? {
                         background: "#009CD40A", boxShadow: "5px 5px 18px #149CCE33, -5px -5px 18px #149CCE33",
                         border: "1px solid #149CCE33", maxWidth: "390px", minWidth: "390px"
                     }
                     : {}
                 }
            >
                <div className="canban-column-header"
                     style={{background: column.color, border: column.color}}>
                    <div
                        title={column?.name?.length > 27 ? column.name : undefined}
                        className="name__column--style name__column--ellipses">
                        {displayName}
                    </div>
                    <div className="canban-column-header__options">
                        <p className="num__task--style">
                            {column?.tasks?.length}
                        </p>
                        {isProxyColumn ? (
                            <button
                                onClick={() => (viewProxy && !columnAccess ? navigate(`/board/${column.proxyBoardId}`) : showToast("У вас нет прав на спросмотр прокси доски!"))}>
                                <img src={viewProxy && !columnAccess ? ProxyToBlue : ProxyToGray}/>
                            </button>
                        ) : null}
                        <div className="add__elem--task__button"
                             onClick={() => (addTask && !columnAccess ? openAddTaskForm(column.id) : showToast("У вас нет прав на создание задач в данном столбце!"))}>
                            <img src={addTask && !columnAccess ? AddTaskBlue : AddTaskGray}/>
                        </div>
                        <div>
                            <div className="add__elem--task__button"
                                 onClick={() => (editColumn && !columnAccess ? setShowOptions(true) : showToast("У вас нет прав на редактирование столбца!"))}>
                                <img src={editColumn && !columnAccess ? SettingColumnBlue : SettingColumnGray}/>
                            </div>
                            {showOptions ? (
                                <div className="settings__column--popup__edit">
                                    <div className="full-screen-overlay__board__column__options"
                                         onClick={() => setShowOptions(false)}>
                                    </div>
                                    <BoardColumnOptions column={column} userAccess={userAccess}/>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div id={`tasks-container-${column.id}`} className="column-task-container">
                    {viewBoard && column.tasks.map((task) => (
                        <div key={task.id} className="dash__board--form-column__tasks--list">
                            <BoardTask
                                task={task}
                                onTaskDragEnd={handleDragEnd}
                                isSelectable={actionMode}
                                isSelected={selectedTasks.some(
                                    (xx) => xx === task.id
                                )}
                                userAccess={userAccess}
                                abbreviationProject={abbreviationProject}
                                columnAccess={columnAccess}
                                boardsData={boardsData}
                                currentZoom={currentZoom}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BoardColumn;
