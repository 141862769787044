import React, {FC, useContext, useEffect} from 'react';
import LoginForm from "../../component/Authorization/Login/LoginForm";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {Route, useLocation, useNavigate} from "react-router-dom";
import Confirmation from "../../component/Authorization/Confirmation/Confirmation";

//to commit 
const Login: FC = (index) => {
    const {store} = useContext(Context);
    const navigate = useNavigate();

    // useEffect(() => {
    //     // if (localStorage.getItem('token')) {
    //     //     store.checkAuth();
    //     // }

    //     if (store.isAuth && store.isActivate) {
    //         navigate("/projects");
    //     }
    // }, [store.isAuth, store.isActivate])

    if (!store.isAuth) {
        return (
            <div>
                <LoginForm/>
            </div>
        )
    } else if (!store.isActivate) {
        return (
            <div>
                <Confirmation/>
            </div>
        );
    } else {
        navigate("/projects");
        return null;
    }
}

export default observer(Login);