import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProxyBoardIcon from "../../../assets/proxy_board_frame.svg";
import BoardIcon from "../../../assets/board_frame.svg";
import { ISideBarBoard } from "../../../models/LeftMenuModels";
import "./NavigationPanel.css";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import { AppContext } from "../../../App";

interface IBoardComponentProps {
    board: ISideBarBoard;
    showProxyBoards: boolean;
}

const BoardComponent: FC<IBoardComponentProps> = ({
    board,
    showProxyBoards,
}) => {
    const [highlighted, setHighlighted] = useState<boolean>(false);
    const { toggleBoard, navBarInfo } = useContext(AppContext);

    useEffect(() => {
        setHighlighted(navBarInfo.boardId === board.id);
    }, [navBarInfo, board]);

    const handleClick = () => {
        if (highlighted) toggleBoard(board.id);
    };

    if (!showProxyBoards && board.isProxyBoard)
        return <></>

    return (
        <div>
            <Link
                className={`nav-panel_board ${
                    highlighted ? "nav-panel_highlighted-row" : ""
                } ${board.isProxyBoard ? "nav-panel_proxy-board" : ""}`}
                to={"/board/" + board.id}
                onClick={handleClick}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    {board.isProxyBoard ? (
                        <img
                            src={ProxyBoardIcon}
                            className="board-icon"
                            alt="board-icon"
                        />
                    ) : (
                        <img
                            src={BoardIcon}
                            className="board-icon"
                            alt="board-icon"
                        />
                    )}
                    <div className="nav-panel_label">{board.name}</div>
                </div>
                {board.boards && board.boards.length && showProxyBoards ? (
                    <ArrowButton
                        onClick={(e) => {
                            e.preventDefault();
                            toggleBoard(board.id);
                        }}
                        direction={
                            navBarInfo.openBoardIds !== undefined &&
                            navBarInfo.openBoardIds?.includes(board.id) ? "bottom" : "top"
                        }
                    />
                ) : null}
            </Link>
            {navBarInfo.openBoardIds?.includes(board.id)
                ? 
                <div>
                    {board.boards?.length ? (<div className="nav-panel_divider">ПРОКСИ-ДОСКИ</div>) : null}
                    {board.boards?.map((proxyBoard) => (
                        <BoardComponent
                            board={proxyBoard}
                            showProxyBoards={showProxyBoards}
                        />
                    ))}
                </div>
                : null}
        </div>
    );
};

export default BoardComponent;
