import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/checkbox.css";

interface ICheckbox {
    id: number,
    handleSelection: (id: number, e: any, nameForFilter?: string, roleId?: number) => void,
    nameForFilter?: string,
    checked?: boolean,
    roleId?: number,
    color?: string,
}
const Checkbox: FC<ICheckbox> = ({id, handleSelection, nameForFilter, checked, roleId, color}) => {
    // const [checked, setChecked] = useState(false);

    const handleCheck = (id: number, e: any) => {
        // setChecked(!checked);
        handleSelection(id, e, nameForFilter, roleId)
    }
    return (
        <label className={`custom-checkbox ${color}`}>
            <input  checked={checked} key={id} onChange={(e: any) => handleCheck(id, e)}  type="checkbox" />
            <span></span>
        </label>
    )
}

export default observer(Checkbox);