import { FC } from 'react'
import "../ConfirmationWindow/ConfirmationWindow.css"
import "../../styles/modalWindow.css"
interface ICustomAlert {
  headerText: string;
  bodyText: string;
  confirmText: string;
  onConfirm: () => void;
}

const CustomAlert: FC<ICustomAlert> = ({ headerText, bodyText, confirmText, onConfirm}) => {
  return (
    <div className='modal-window-overlay'>
      <div className='modal-window-container'>
        <div>
          <h1 className='p_black_black'>
            {headerText}
          </h1>
          <p className='p_black_black p-18'>{bodyText}</p>
        </div>
        <div className='modal-window-btns'>
          <button className='confirm_btn  p_blue p_14 button_reset_style' onClick={onConfirm}>{confirmText}</button>
        </div>
      </div>
    </div>
  )
}

export default CustomAlert