import React from "react";

/**
 * Проверяет, достигла ли прокрутка в элементе DIV нижнего края.
 *
 * @param {React.UIEvent<HTMLDivElement>} event - Событие прокрутки.  Ожидается, что `event.target` будет элементом HTMLDivElement.
 * @returns {boolean} - `true`, если прокрутка достигла нижнего края или очень близка к нему; `false` в противном случае.
 *
 * Эта функция вычисляет, достигла ли прокрутка нижней части элемента DIV, сравнивая текущую позицию прокрутки (`scrollTop`), высоту видимой области (`clientHeight`) и полную высоту содержимого (`scrollHeight`).
 *  Важно: Функция предполагает, что `event.target` всегда является элементом `HTMLDivElement`.  Неправильный тип `event.target` может привести к ошибке.  Для повышения надежности рекомендуется использовать `useRef` для доступа к элементу.
 */
export const scrollYCheck = (event: React.UIEvent<HTMLDivElement>): boolean => {
    const htmlTarget = (event.target as HTMLDivElement);
    const newScrollTop = htmlTarget.scrollTop; // Вертикальный скролл
    const newScrollLeft = htmlTarget.scrollLeft; // Горизонтальный скролл
    const scrollHeight = htmlTarget.scrollHeight;
    const clientHeight = htmlTarget.clientHeight;

    return newScrollTop + clientHeight >= scrollHeight;
};