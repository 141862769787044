import { FC, useEffect, useState } from "react";
import arrowDown from "../../../assets/arrowDown.svg";
import "./SelectDropdown.css";

interface DropdownOption {
    id: number;
    text: string;
}

interface SelectDropdownProps {
    onSelect: (option: DropdownOption) => void;
    options: DropdownOption[];
    defaultOption: DropdownOption;
    errorMessage?: string;
}

const SelectDropdown: FC<SelectDropdownProps> = ({
    onSelect,
    options,
    defaultOption,
    errorMessage,
}) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] =
        useState<DropdownOption>(defaultOption);

    useEffect(() => {
        onSelect(selectedOption);
    }, [selectedOption]);

    return (
        <div>
            <div
                className={`container_pointer ${
                    errorMessage ? "div_with_error" : ""
                }`}
                onClick={() => setOpenDropdown(!openDropdown)}
            >
                <div className="selected-option">{selectedOption.text}</div>
                <img
                    alt="arrowDown"
                    src={arrowDown}
                    style={
                        openDropdown ? { rotate: "180deg" } : { rotate: "0deg" }
                    }
                />
            </div>
            {openDropdown ? (
                <>
                    <div style={{width: "100%", position: "relative"}}>
                        <div className={"dropdown_overview"}>
                            {options?.map((option, index) => (
                                <div
                                    className="dropdown_option"
                                    id={`${index}`}
                                    onClick={() => {
                                        setOpenDropdown(false);
                                        setSelectedOption(option);
                                    }}
                                >
                                    {option.text}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{ opacity: "0.5" }}
                        className="full-screen-overlay_dropdown"
                        onClick={() => setOpenDropdown(!openDropdown)}
                    ></div>
                </>
            ) : null}
        </div>
    );
};

export default SelectDropdown;
