import $api from "..";
import {AxiosResponse} from "axios";
import {IViewBasket} from "../models/IViewBasket";
import {IViewBasketResp} from "../models/IViewBasketResp";

export default class BasketService {
    static async viewBasket(reqBody: IViewBasket): Promise<AxiosResponse<IViewBasketResp>> {
        return $api.post<IViewBasketResp>('/basket', reqBody);
    }

    static async restoreBasket(reqBody: number[]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/basket/restore', reqBody);
    }

    static async deleteBasketFiles(reqBody: number[]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/basket/deleteFile', reqBody);
    }
}