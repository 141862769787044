import { FC, useContext, useRef, useState } from "react";
import "./TaskPerson.css";
import { TaskContext } from "../../../pages/Task/Task";
import Avatar from "../../Shared/Avatar/Avatar";
import { getFileUrlFromId } from "../../../helpers/getFileUrlFromId";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import { fullNameFormat } from "../../../helpers/Inicials";
import UserPopupWrapper from "../../Shared/UserPopup/UserPopupWrapper";

interface ITaskPerson {
    permission: boolean;
}

const TaskPerson: FC<ITaskPerson> = ({ permission }) => {
    const [showFullList, setShowFullList] = useState(false);
    const listRef = useRef(null);
    const { allPerson } = useContext(TaskContext);

    const handleMouseEnter = () => {
        setShowFullList(true);
    };

    const handleMouseLeave = () => {
        setShowFullList(false);
    };

    return (
        <div className="task__person--block">
            <div className="task__person--block__executor">
                <div className="task__person--block--name">Исполнитель</div>
                <div style={{margin: "10px 0px"}}>
                    {allPerson ? (
                        <UserPopupWrapper userId={allPerson.executor.id}>
                            <PersonLineDisplay
                                name={fullNameFormat(
                                    {
                                        surname: allPerson.executor.surname ?? "",
                                        name: allPerson.executor.name ?? "",
                                        middlename: allPerson.executor.middlename ?? "",
                                    },
                                    "s N M"
                                )}
                                photoId={allPerson.executor.photoId}
                            />
                        </UserPopupWrapper>) : null}
                </div>
            </div>
            <div className="task__person--block__group">
                <div className="task__person--block--name">Подписчики</div>
                <div>
                    <div
                        className="task__person--block--line__group"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={listRef}
                    >
                        {permission && allPerson ?
                            allPerson.group
                                  .slice(0, 5)
                                  .map((item, index) => (
                                    <UserPopupWrapper userId={item.id}>
                                      <div
                                          key={"person-" + index}
                                          className="task__person--block--name__person--group"
                                      >
                                          <div className="task__person--block--img">
                                              <Avatar
                                                  url={getFileUrlFromId(
                                                      item.photoId
                                                  )}
                                                  size="s"
                                              />
                                          </div>
                                      </div>
                                    </UserPopupWrapper>
                                  ))
                        : null}

                        {showFullList &&
                            allPerson?.group.slice(5).map((item, index) => (
                                <div
                                    key={"person-" + index}
                                    className="task__person--block--name__person--group"
                                >
                                    <div className="task__person--block--img">
                                        <Avatar
                                            url={getFileUrlFromId(item.photoId)}
                                            size="s"
                                        />
                                    </div>
                                    {/* <div className="task__person--block--name__person--in">
                                {item.surname?.slice(0, 1) + "" + item.name?.slice(0, 1)}
                            </div> */}
                                </div>
                            ))}

                        {allPerson?.group?.length! > 5 && !showFullList && (
                            <div className="task__person--block--more">
                                +{allPerson?.group?.length! - 5}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="task__person--block__author">
                <div className="task__person--block--name">Автор</div>
                <div style={{margin: "10px 0px"}}>
                    {allPerson ? (
                        <UserPopupWrapper userId={allPerson.author.id}>
                            <PersonLineDisplay
                                name={fullNameFormat(
                                    {
                                        surname: allPerson.author.surname ?? "",
                                        name: allPerson.author.name ?? "",
                                        middlename: allPerson.author.middlename ?? "",
                                    },
                                    "s N M"
                                )}
                                photoId={allPerson.author.photoId}
                            />
                        </UserPopupWrapper>) : null}
                </div>
            </div>
        </div>
    );
};

export default TaskPerson;
