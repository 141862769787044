import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import SaveInputValue from "../../../assets/save_input_value.svg"
import ChooseItem from '../chooseUser/ChooseItem';
import './InputSaveOnWidjet.css';
import useOutsideAlerter from '../../../customHooks/useOutsideAlert';
import BtnCancel from "../../buttons/CancelButton/CancelButton";

interface Item {
    name: string;
    code: string;
}

interface IInputSaveOnEdit {
    value: string | undefined,
    setValue: (e: any) => void,
    open: boolean,
    setOpen: (open: boolean) => void,
    save: (e: any) => void,
    typeInput: string,
    selectedUser?: Item | null,
    setSelectedUser?: (e: any) => void,
    selectedRole?: Item | null,
    setSelectedRole?: (e: any) => void,
    persons?: Item[] | null,
    roles?: Item[] | null,
}

const InputSaveOnEdit: FC<IInputSaveOnEdit> = (
{ value, setValue,
    open, setOpen, save,
    typeInput,
    selectedUser, setSelectedUser,
    selectedRole, setSelectedRole,
    persons, roles
}) => {

    const outsideAlerterRef: any = useOutsideAlerter(() => setOpen(false));

    const closeInput = () => {
        setOpen(false);
    }


    if (open) {
        if (typeInput === 'text') {
            return (
                <div className='input_wrapper' ref={outsideAlerterRef}>
                    <input type='text'
                        className='input_reser input_widjets' value={value} onChange={(e: any) => setValue(e.target.value)} />
                    {value &&
                        <button className='btn_save_input_value position_on_input' onClick={(e: any) => save(e)}>
                            <img src={SaveInputValue} />
                        </button>
                    }
                </div>
            )
        } else if (typeInput === 'list') {
            return (
                <form>
                    <div className='input_wrapper' ref={outsideAlerterRef}>
                        <div className='input_lists'>
                            <ChooseItem selectedItem={selectedUser!} setSelectedItem={setSelectedUser!} placeholderName='Выберите пользователя' data={persons} />
                            <ChooseItem selectedItem={selectedRole!} setSelectedItem={setSelectedRole!} placeholderName='Выберите должность' data={roles} />
                        </div>
                        {(selectedUser && selectedRole) ?
                            (<button className='btn_save_input_value position' onClick={(e: any) => save(e)}>
                                <img src={SaveInputValue} />
                            </button>) : <div className=''><BtnCancel onClickFunc={closeInput} /></div>
                        }
                    </div>
                </form>

            )
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default observer(InputSaveOnEdit);