import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import filterIcon from "../../../assets/filter.svg"
import Filter from '../../Shared/Filter/Filter';
import '../../buttons/btnFilter.component/BtnFilter.css'
import { IFilter } from "../../../models/response/IFilterResponse";
import { useParams } from 'react-router-dom';
import useOutsideAlerter from '../../../customHooks/useOutsideAlert'
interface IFilterProps {
    filterData: IFilter[];
    setFilterData: (filterData: IFilter[]) => void;
    applayFilters: () => Promise<void>;
    checkedFilters: any;
    setCheckedFilters: (checkedFilters: any) => void;
    selectedUsers?: any;
    dates?: any;
    setSelectedUsers?: (selectedUsers: any) => void;
    setDates?: (dates: any) => void;
    counter: number;
    setCounter: (counter: number) => void;
    showApplyButton: boolean
}

interface IFilterItem {
    label: string;
    id: number;
    checked: boolean;
}

const BtnFilter: FC<IFilterProps> = ({ filterData, setFilterData,
    applayFilters, checkedFilters, setCheckedFilters,
    selectedUsers, setSelectedUsers,
    dates, setDates, counter, setCounter, showApplyButton }) => {
    const [open, setOpen] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        setOpen(false)
    }, [id])

    useEffect(() => {
        let counter = 0;
        if (checkedFilters.date && checkedFilters.date.length > 0)
            counter += 1;
        if (checkedFilters.responsible && checkedFilters.responsible.length > 0)
            counter += checkedFilters.responsible.length;
        if (checkedFilters.status && checkedFilters.status.length > 0)
            counter += checkedFilters.status.length;
        if (checkedFilters.typeTask && checkedFilters.typeTask.length > 0)
            counter += checkedFilters.typeTask.length;
        if (checkedFilters.priority && checkedFilters.priority.length > 0)
            counter += checkedFilters.priority.length;
        if (checkedFilters.role && checkedFilters.role.length > 0)
            counter += checkedFilters.role.length;
        if (checkedFilters.fileType && checkedFilters.fileType.length > 0)
            counter += checkedFilters.fileType.length;
        if (checkedFilters.entityTypes && checkedFilters.entityTypes.length > 0)
            counter += checkedFilters.entityTypes.length;
        if (checkedFilters.actionTypes && checkedFilters.actionTypes.length > 0)
            counter += checkedFilters.actionTypes.length;
        setCounter(counter);
    }, [checkedFilters, setCounter])

    const outsideAlerterRef: any = useOutsideAlerter(() => setOpen(false));
    return (
        <div className="btnFilterWrapper" ref={outsideAlerterRef}>
            <div className="btnFilter" onClick={() => setOpen(!open)}>
                <img src={filterIcon} />
            </div>
            {counter && counter !== 0 ?
                    <div className='label_counter'>
                        {counter}
                    </div>
                    : ''
                }
            {open && <Filter
                items={filterData.filter(xx => xx != undefined)} //Тут нужна проверка, иначе падает
                setItems={setFilterData}
                applayFilters={applayFilters}
                checkedFilters={checkedFilters}
                setCheckedFilters={setCheckedFilters}
                selectedUsers={selectedUsers}
                dates={dates}
                setSelectedUsers={setSelectedUsers}
                setDates={setDates}
                setCounter={setCounter}
                showApplyButton={showApplyButton}
                setOpen={setOpen}
            />}
        </div>
    )

}



export default observer(BtnFilter);