import React, { useContext, useState } from "react";
import { Context } from "../../../index";
// import "../../styles/form.pc.css"
// import "../../../globalstyles/input.pc.css"
// import "../../../globalstyles/background.pc.css"
// import "../../../globalstyles/button.pc.css"
// import "../../../globalstyles/text.pc.css"

import { observer } from "mobx-react-lite";
import CustomLink from "../../Shared/CustomLink/CustomLink";
import Logo from "../../../assets/mainLogo.svg";
import Bacground from "../../../assets/backgroundLogin.jpg";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import PrivacyPolicyLink from "../../Shared/PrivacyPolicyLink";

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState<string | undefined>("");
    const [emailError, setEmailError] = useState<string | undefined>("");
    const [infoMode, setInfoMode] = useState<boolean>(false);
    const { store } = useContext(Context);

    const handleConfirm = async () => {
        const emailErr = validateEmail();

        if (emailErr) return;

        try {
            const res = await store.requestPasswordRecover(email!);
            if (res == 404) {
                setEmailError("Не найден пользователь с таким email");
                return;
            }
            setInfoMode(true);
        } catch (err) {}
    };

    const validateEmail = (): boolean => {
        let emailErr = undefined;

        if (!email) {
            emailErr = "Введите email";
        } else if (!isValidEmail(email)) {
            emailErr = "Некорректный email";
        }
        setEmailError(emailErr);
        return emailErr !== undefined;
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    if (infoMode) {
        return (
            <div className="form_main_center">
                <div className="form_container form_container_fullscreen form_container_center">
                    <div className="form_logo">
                        <img src={Logo} alt="Логотип компании" />
                    </div>
                    <div>
                        <h2 className="form_header form_text">Письмо отправлено на указанную почту</h2>
                    </div>
                    <div className="form_description">
                        <p className="form_text">
                            Для восстановления пароля перейдите по ссылке из
                            письма
                        </p>
                    </div>
                    <div className="bottom_buttons">
                        <p className="p_blue p_14 pointer_custom">
                            <CustomLink to="/login">Вход</CustomLink>
                        </p>
                    </div>
                    <PrivacyPolicyLink />
                </div>
                <img
                    src={Bacground}
                    alt="Фон компании"
                    className="background_image_fullscreen"
                />
            </div>
        );
    }

    return (
        <div className="form_main_center">
            <div className="form_container form_container_fullscreen form_container_center">
                <div className="form_inner">
                    <div className="form_logo">
                        <img src={Logo} alt="Логотип компании" />
                    </div>
                    <div>
                        <h2>Восстановление пароля</h2>
                    </div>
                    <div className="form_description">
                        <p>
                            Введите почту, чтобы мы отправили вам письмо для
                            восстановления пароля
                        </p>
                    </div>
                    <div
                        className={`input_form_login ${
                            emailError ? "input_with_error" : ""
                        }`}
                    >
                        <label className="input_label" htmlFor="login_email">
                            Email*
                        </label>
                        <InputText
                            placeholder="email"
                            id="login_email"
                            value={email}
                            onBlur={validateEmail}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                        />
                    </div>
                    {emailError ? (
                        <p className="error_message">{emailError}</p>
                    ) : null}
                    <div className="login_btn">
                        <Button
                            className="login_btn"
                            label="Восстановить"
                            onClick={handleConfirm}
                        />
                    </div>
                    <div className="bottom_buttons form__margin-top">
                        <p className="p_blue p_14 pointer_custom">
                            <CustomLink to="/login">Вход</CustomLink>
                        </p>
                        <p className="p_blue p_14 pointer_custom">
                            <CustomLink to="/register">Регистрация</CustomLink>
                        </p>
                    </div>
                    <PrivacyPolicyLink />
                </div>
            </div>
            <img
                src={Bacground}
                alt="Фон компании"
                className="background_image_fullscreen"
            />
        </div>
    );
};

export default observer(ForgotPasswordForm);
