import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import './MisceleniousStyles.css'

const TaskPriority = (params: any) => {
    return (
        <div className='task-priority-widget nonselectable' style={{
            backgroundColor: params.color,
        }}>
            <div className='task-type-icon' dangerouslySetInnerHTML={{__html: params.icon}}/>
            <div className='task-type-text' style={{color: "#fff"}}>{params.name}</div>
        </div>

    );
}
export default TaskPriority;
