import React, {CSSProperties, FC, ReactNode} from 'react';
import "./AbsolutePosition.css"

interface IAbsolutePositionProps {
    children: ReactNode;
    style?: CSSProperties;
    zIndex: number;
}

const AbsolutePosition: FC<IAbsolutePositionProps> = ({children, style, zIndex}) => {
    return (
        <div style={{...style, zIndex: `${zIndex}`}} className="ui__component--absolute__position">
            {children}
        </div>
    );
};

export default AbsolutePosition;