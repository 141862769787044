import { createRef, FC, useContext, useState } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
import EditIconWhite from "../../../assets/EditIconWhite.svg";
import { AppContext } from "../../../App";
import "./ImageSelector.css"

interface IImageSelectorProps {
    onImageCropped: (image: File) => void;
    fileName?: string;
}

const MAX_SIZE_IMG_MB = 5;

const ImageSelector: FC<IImageSelectorProps> = ({
    onImageCropped,
    fileName,
}) => {
    const { showToast } = useContext(AppContext);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const cropperRef = createRef<ReactCropperElement>();
    const [image, setImage] = useState<string>("");
    const [validate, setValidate] = useState<boolean>(false);

    const disableCrop = () => {
        setImage("");
        setOpenModal(false);
    };

    const dataURLToBlob = (dataURL: string): Blob => {
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const getCropData = async () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setValidate(false);
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
            const dataURL = croppedCanvas.toDataURL("image/png", 0.8);
            const croppedImageBlob = dataURLToBlob(dataURL);

            if (croppedImageBlob) {
                const croppedFile = new File(
                    [croppedImageBlob],
                    fileName ?? "image.png",
                    {
                        type: "image/png",
                    }
                );
                try {
                    
                    await onImageCropped(croppedFile);
                    setOpenModal(false);
                } catch (error) {
                    console.log(error);
                }
                setImage("");
            } else {
                console.error("Ошибка при получении обрезанного изображения!");
            }
        }
    };

    const onChangeLogo = (e: any) => {
        const file = e.target.files?.[0];
        const fileSizeInMB = file.size / (1024 * 1024);

        if (fileSizeInMB > MAX_SIZE_IMG_MB) {
            showToast("Файл слишком большой");
            return;
        }

        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
        setOpenModal(true);
    };

    return (
        <div>
            {openModal && (
                <div className="image__selector--modal__window">
                    <div className="image__selector--modal__window--block">
                        <div>
                            <Cropper
                                ref={cropperRef}
                                style={{
                                    height: "600px",
                                    minWidth: "600px",
                                }}
                                zoomTo={0.5}
                                initialAspectRatio={1}
                                src={image}
                                viewMode={1}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={true}
                                className={
                                    validate
                                        ? "image__selector--setting__elem--cover__block--crop"
                                        : ""
                                }
                            />
                            <div className="image__selector--modal__window--block">
                                <div className="image__selector--modal__window--block__button--background">
                                    <button
                                        className="image__selector--modal__window--block__button--close"
                                        onClick={disableCrop}
                                    >
                                        Отмена
                                    </button>
                                    <button
                                        className="image__selector--modal__window--block__button--crop"
                                        onClick={getCropData}
                                    >
                                        Обрезать
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="image__selector--setting__elem--company__logo__button">
                <label className="image__selector--setting__elem--input-file image__selector--setting__elem--input-file__task--size">
                    <input
                        onChange={(e) => {
                            onChangeLogo(e);
                            e.target.value = "";
                        }}
                        type="file"
                        accept="image/png, image/jpeg"
                    />
                    <span>
                        <img src={EditIconWhite} />
                    </span>
                </label>
            </div>
        </div>
    );
};

export default ImageSelector;
