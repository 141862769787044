import { FC } from "react";
import { observer } from "mobx-react-lite";
import "./ExitButton.css"

export interface IExitButtonProps {
    onClickFunc: () => void;
}

const ExitButton: FC<IExitButtonProps> = ({ onClickFunc }) => {
    return (
        <div className="button-base exit-button" onClick={onClickFunc}>
            <div className="exit-button-img" />
            <p>
              Выход
            </p>
        </div>
    );
};

export default observer(ExitButton);
