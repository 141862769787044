import { useContext, useState } from "react";
import "./SubTaskSelect.css";
import { ISubTask } from "../../../../../models/ISubTask";

import AddedCheck from "../../../../../assets/ButtonIcons/subtask-control-added.svg";
import RemoveCross from "../../../../../assets/ButtonIcons/subtask-control-remove.svg";
import AddPlus from "../../../../../assets/ButtonIcons/subtask-control-add.svg";
import Search from "../../../../Shared/Search/Search";
import { Context } from "../../../../../index";
import { TaskContext } from "../../../../../pages/Task/Task";
import SelectDropdown from "../../../../Shared/SelectDropdown/SelectDropdown";
import { AppContext } from "../../../../../App";
import axios, { AxiosError } from "axios";

const SubTaskSelect = () => {
    const { store } = useContext(Context);
    const { taskInformation, subTasks, loadSubTasks, allowedProjects } =
        useContext(TaskContext);

    const {showToast} = useContext(AppContext);

    const [searchValue, setSearchValue] = useState<string>("");
    const [hoveredSubtask, setHoveredSubtask] = useState<number | undefined>(
        undefined
    );
    const [selectedProject, setSelectedProject] = useState<number | undefined>(
        taskInformation?.projectId
    );

    async function onSubTaskControlClick(taskId: number) {
        let task = subTasks?.find((xx) => xx.id === taskId);
        if (!task) {
            return;
        }

        let newValue = {
            taskId: task.id,
            parentTaskId: task.parentId ? undefined : taskInformation?.id,
        };

        try {
            await store.taskService.setTaskParent(newValue);
            await loadSubTasks();
        } catch(ex: any) {
            if (axios.isAxiosError(ex)) {
                showToast(ex.response?.data.message);
            }
        }
    }

    function onSearchSubmit(value: string | undefined) {
        setSearchValue(value ?? "");
    }

    function filterSubtasks(tasks: ISubTask[]): ISubTask[] {
        ////TODO тут надо скрывать все задачи не доступные (из-за ограничений)
        let res: ISubTask[] = [];

        //Не сама задача, уникальная
        for (let task of tasks ?? []) {
            if (task.id !== taskInformation?.id) {
                if (!res.some((xx) => xx.id == task.id)) {
                    if (task.projectId == selectedProject) {
                        if (
                            task.name
                                ?.toLocaleLowerCase()
                                .includes(searchValue.toLocaleLowerCase()) ||
                            task.number
                                ?.toLocaleLowerCase()
                                .includes(searchValue.toLocaleLowerCase())
                        ) {
                            res.push(task);
                        }
                    }
                }
            }
        }
        return res;
    }

    function ProjectSelect() {
        return (
            <div className="subtask-project-dropdown">
                {allowedProjects ? (
                    <SelectDropdown
                        options={allowedProjects.map((p) => {
                            return { id: p.id, text: p.name };
                        })}
                        defaultOption={
                            allowedProjects.find(
                                (p) => p.id === taskInformation?.projectId
                            )
                                ? {
                                      id: taskInformation?.projectId ?? -1,
                                      text: allowedProjects.find(
                                          (p) =>
                                              p.id ===
                                              taskInformation?.projectId
                                      )!.name,
                                  }
                                : { id: -1, text: "" }
                        }
                        onSelect={(opt) => {
                            setSelectedProject(opt.id);
                        }}
                    />
                ) : null}
            </div>
        );
    }

    function SubTaskItemControl(subTask: ISubTask) {
        if (hoveredSubtask == subTask.id && subTask.parentId) {
            return (
                <div
                    onClick={() => {
                        onSubTaskControlClick(subTask.id);
                    }}
                    onMouseOut={() => {
                        setHoveredSubtask(undefined);
                    }}
                    onMouseOver={(event) => {
                        setHoveredSubtask(subTask.id);
                    }}
                    style={{ marginLeft: "auto" }}
                    className="subtask-control-base row-align remove-control"
                >
                    <img src={RemoveCross} alt="" />
                    <div style={{ marginLeft: "auto" }}>Удалить</div>
                </div>
            );
        }

        if (!subTask.parentId) {
            return (
                <div
                    onClick={() => {
                        onSubTaskControlClick(subTask.id);
                    }}
                    onMouseOut={() => {
                        setHoveredSubtask(undefined);
                    }}
                    onMouseOver={(event) => {
                        setHoveredSubtask(subTask.id);
                    }}
                    style={{ marginLeft: "auto" }}
                    className="subtask-control-base row-align add-control"
                >
                    <img src={AddPlus} alt="" />
                    <div style={{ marginLeft: "auto" }}>Добавить</div>
                </div>
            );
        } else {
            return (
                <div
                    onClick={() => {
                        onSubTaskControlClick(subTask.id);
                    }}
                    onMouseOut={() => {
                        setHoveredSubtask(undefined);
                    }}
                    onMouseOver={(event) => {
                        setHoveredSubtask(subTask.id);
                    }}
                    style={{ marginLeft: "auto" }}
                    className="subtask-control-base row-align added-control"
                >
                    <img src={AddedCheck} alt="" />
                    <div style={{ marginLeft: "auto" }}>Добавлена</div>
                </div>
            );
        }
    }

    return (
        <div className="right-menu-subtask-select-container">
            <div className="right-menu-subtask-select-controls row-align">
                {ProjectSelect()}
                <Search
                    defaultValue={searchValue}
                    changeSearchValue={onSearchSubmit}
                    onSubmitFunc={onSearchSubmit}
                />
            </div>
            <div className="right-menu-subtask-select-items-container column-align">
                {subTasks ? (
                    filterSubtasks(subTasks).map((subTask: ISubTask) => {
                        return (
                            <div
                                key={`subtask_${subTask.id}_s`}
                                className="subtask-select-item row-align"
                            >
                                <div className="subtask-select-item-info">
                                    <div
                                        style={{
                                            color: "var(--secondary-text-gray)",
                                        }}
                                        className="p_14"
                                    >
                                        {subTask.number}
                                    </div>
                                    <div
                                        style={{ fontWeight: "600" }}
                                        className="p_18 subtask-select-item-info-name"
                                    >
                                        {subTask.name}
                                    </div>
                                </div>
                                {SubTaskItemControl(subTask)}
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export default SubTaskSelect;
